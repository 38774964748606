import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-layout-page-header-title',
  templateUrl: './page-header-title.component.html',
  styleUrls: ['./page-header-title.component.scss'],
})
export class PageHeaderTitleComponent {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() subtitle_two?: string;
  @Input() subtitle_three?: string;
}
