import { Component } from '@angular/core';
import { AdminUsersPageStore } from './admin-users-page.store';
import { SolutionService } from '../../../../core/services/solution.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-users-page',
  templateUrl: './admin-users-page.component.html',
  styleUrls: ['./admin-users-page.component.scss'],
  providers: [SolutionService, AdminUsersPageStore],
})
export class AdminUsersPageComponent {
  vm$ = this.adminUsersPageStore.vm$;

  usersCount: any;

  constructor(
    private adminUsersPageStore: AdminUsersPageStore,
    private router: Router
  ) {}

  onUserTypeChange(type: number) {
    this.adminUsersPageStore.setUserType(type);
  }

  backtoAdminHome() {
    this.router.navigate(['/admin']);
  }
}
