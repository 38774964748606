import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { User } from '../models/user';
import { UserService } from './user.service';
import {
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  user,
} from '@angular/fire/auth';
import { Timestamp, doc, onSnapshot } from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import UserApi from 'src/app/core/apis/user.api';
import { tapResponse } from '@ngrx/component-store';
import { HttpErrorResponse } from '@angular/common/http';
import Roles from '../models/role';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(
    null
  ); // Save logged in user data
  user$ = user(this.afAuth);
  //user meta data (from users collection) accessible throught all components
  userRole?: string;
  roles?: Roles;
  buOfUser?: string;
  displayName?: string;
  type?: string;
  passwordEdited?: boolean;
  email?: string;

  constructor(
    // Inject Firebase auth service
    public afAuth: Auth,
    public userService: UserService,
    public router: Router, // NgZone service to remove outside scope warning.
    private userApi: UserApi,
    private veoliaMessageService: VeoliaMessageService
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    onAuthStateChanged(this.afAuth, (user: any) => {
      if (user) {
        onSnapshot(
          doc(this.userService._firestore, 'users', user?.uid),
          doc => {
            const userData = doc.data() as User;
            //user meta data (from users collection) accessible throught all components
            this.userData.next(userData);
            this.userRole = userData.role;
            this.roles = userData.roles;
            this.type = userData.type;
            this.buOfUser = userData.businessUnit;
            this.passwordEdited = userData.passwordEdited;
            this.email = userData.email;

            if (userData.isVeolia) {
              this.displayName = userData.displayName;
            } else {
              this.displayName = userData.firstname + ' ' + userData.lastname;
            }

            localStorage.setItem(
              'user',
              JSON.stringify({
                ...userData,
                emailVerified: user?.emailVerified,
              })
            );
          },
          () => {
            localStorage.setItem('user', 'null');
          }
        );
        // this.UserData = user;
        // localStorage.setItem('user', JSON.stringify(this.UserData));
        // JSON.parse(localStorage.getItem('user')!);
      } else {
        localStorage.setItem('user', 'null');
      }
    });

    // this.RefreshUserData();
  }

  RefreshUserData() {
    this.user$.subscribe(user => {
      if (user) {
        onSnapshot(
          doc(this.userService._firestore, 'users', user?.uid),
          doc => {
            this.userData.next(doc.data() as unknown as User);
          }
        );
        this.userService.getUserData(user?.uid).then(userDoc => {
          this.userRole = (userDoc.data() as unknown as User).role;
          this.userData.next(userDoc.data() as unknown as User);
          localStorage.setItem(
            'user',
            JSON.stringify({
              ...(userDoc.data() as unknown as User),
              emailVerified: user?.emailVerified,
            })
          );
        });
      } else {
        localStorage.removeItem('user');
      }
    });
  }
  // Sign in with Google
  GoogleAuth() {
    return this.oAuthLogin(new GoogleAuthProvider());
  }

  //VEOLIA
  //sign in with google sso
  private oAuthLogin(provider: any) {
    // User logged in through google auth
    const auth = getAuth();
    return signInWithPopup(auth, provider)
      .then(result => {
        // Check if user exists in firestore
        this.userService.getUserData(result.user?.uid).then(user => {
          if (!user.data()) {
            //user does not exists
            // Create user in firestore if not exists
            this.CreateVeoliaUserIfNotExists(result).then(() => {
              this.router.navigate(['/auth/first-connection']);
            });
          } else {
            //user already exists
            // Only uplast login time and browser
            this.userService
              .updateLastLogin(result.user?.uid)
              .then(() => {
                if (this.hasAcceptedTerms !== true) {
                  this.router.navigate(['/auth/first-connection']);
                } else {
                  // Navigate to home page admin
                  // if (user?.data()!['roles'].admin === true) {
                  //   this.router.navigate(['/admin']);
                  // }
                  // // Navigate to home page
                  // if (user?.data()!['roles'].admin === false) {
                  //   this.router.navigate(['/']);
                  // }
                  if (user?.data()!['roles'].evaluator === true) {
                    this.router.navigate(['/evaluator']);
                  } else {
                    this.router.navigate(['/']);
                  }
                }
              })
              .finally(() => {
                this.userService.updateCurrentBrowser(result.user?.uid);
              });
          }

          // Update user data in localstorage
          localStorage.setItem(
            'user',
            JSON.stringify({
              ...(user.data() as unknown as User),
              emailVerified: result.user?.emailVerified,
            })
          );

          // Navigate to home page
          this.router.navigate(['/']);
        });
      })
      .catch(error => {
        window.alert(error);
      });
  }

  //VENDOR
  // Sign in with email/password
  SignIn(email: string, password: string) {
    // User logged in through email/pass auth
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password)
      .then(result => {
        // Check if user exists in firestore
        this.userService.getUserData(result.user?.uid).then(user => {
          if (!user.data()) {
            //user does not exists
            // Error the user must already have his credentials to log in
            this.veoliaMessageService.create(
              {
                title: 'Error',
                icon: 'error',
                content: 'Your email and password are unknown',
              },
              {
                duration: 10000,
              }
            );
          } else {
            //user already exists //it should be always the case
            // Only uplast login time and browser
            this.userService
              .updateLastLogin(result.user?.uid)
              .then(() => {
                if (this.hasAcceptedTerms !== true) {
                  this.router.navigate(['/auth/first-connection']);
                } else {
                  this.router.navigate(['/']);
                }
              })
              .finally(() => {
                this.userService.updateCurrentBrowser(result.user?.uid);
              });
          }

          // Update user data in localstorage
          localStorage.setItem(
            'user',
            JSON.stringify({
              ...(user.data() as unknown as User),
              emailVerified: result.user?.emailVerified,
            })
          );

          // console.log('routing to home');
          // Navigate to home page
          this.router.navigate(['/']);
        });
      })
      .catch(error => {
        window.alert(error.message);
      });
  }

  // Reset forgot password
  forgotPassword(email: string) {
    const auth = getAuth();
    return sendPasswordResetEmail(auth, email)
      .then(() => {
        this.veoliaMessageService.create(
          {
            title: 'Notification sent !',
            icon: 'error',
            content: 'Reset password email sent',
          },
          {
            duration: 10000,
          }
        );
      })
      .catch(error => {
        window.alert(error);
      });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null;
  }

  // Return true when user has accepted terms
  get hasAcceptedTerms(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.termsAccepted === true;
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: Partial<User> | User) {
    return this.userService.update(user.uid!, user, false);
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.userData.next(null);
      this.router.navigate(['/auth/login']);
    });
  }

  //New veolia user
  // create user in firestore if not exists after google auth
  private initializeNewVeoliaUser(credential: any) {
    const data: Partial<User> = {
      uid: credential.user.uid,
      email: credential.user.email,
      displayName: credential.user.displayName,
      photoURL: credential.user.photoURL || 'https://goo.gl/Fz9nrQ',
      role: 'Viewer',
      isVeolia: true,
      type: 'Veolia',
      createdAt: Timestamp.fromDate(new Date(Date.now())), //first login
      lastLoggedIn: Timestamp.fromDate(new Date(Date.now())),
      termsAccepted: false, //par défaut n'a pas encore dit YES aux terms de firstConnection
      roles: {
        viewer: true,
        solutionOwner: false, //n'a pas encore fait de demande
        evaluator: false,
        vendor: false,
        admin: false,
      },
    };

    return data;
  }

  //veolia user
  // create user if nots exists in firestore
  async CreateVeoliaUserIfNotExists(credential: any) {
    const data = this.initializeNewVeoliaUser(credential);
    this.userService.create(data as unknown as User, credential.user.uid);
  }

  disableVendorUser(uid: string, email: string) {
    return this.userApi
      .disableVendor(uid, email)
      .pipe(
        tapResponse(
          res => {
            console.log('response from backend routes.py', res);
          },
          (err: HttpErrorResponse) => {
            console.log('error', err);
          }
        )
      )
      .subscribe();
  }

  enableVendorUser(uid: string, email: string) {
    return this.userApi
      .enableVendor(uid, email)
      .pipe(
        tapResponse(
          res => {
            console.log('response from backend routes.py', res);
          },
          (err: HttpErrorResponse) => {
            console.log('error', err);
          }
        )
      )
      .subscribe();
  }
}
