import { Injectable } from '@angular/core';
import { Database, getDatabase, onValue, ref } from '@angular/fire/database';
import { FileItem } from '../models/file-item';
@Injectable()
export class FileService {
  constructor(public __database: Database = getDatabase()) {}

  getAllFiles(form: string, solutionId: string) {
    return new Promise((resolve, reject) => {
      const filesRef = ref(this.__database, `/files/${form}/${solutionId}`);

      onValue(filesRef, snapshot => {
        if (!snapshot.exists()) {
          reject('No files found');
        }

        const data = snapshot.val();
        resolve(data);
      });
    });
  }

  getFiles(form: string, solutionId: string) {
    return new Promise((resolve, reject) => {
      const filesRef = ref(
        this.__database,
        `evaluations/files/${form}/${solutionId}`
      );

      onValue(filesRef, snapshot => {
        if (!snapshot.exists()) {
          reject('No files found');
        }

        const data = snapshot.val();
        resolve(data);
      });
    });
  }

  getChapterFiles(form: string, chapter: string, solutionId: string) {
    return new Promise((resolve, reject) => {
      const filesRef = ref(
        this.__database,
        `files/${form}/${solutionId}/${chapter}`
      );

      onValue(filesRef, snapshot => {
        if (!snapshot.exists()) {
          reject('No files found');
        }

        const data: FileItem[] = [];

        snapshot.forEach(childSnapshot => {
          data.push({
            id: childSnapshot.key,
            ...childSnapshot.val(),
          } as FileItem);
        });

        resolve(data);
      });
    });
  }

  getChapterFilesCount(form: string, chapter: string, solutionId: string) {
    return new Promise((resolve, reject) => {
      const filesRef = ref(
        this.__database,
        `files/${form}/${solutionId}/${chapter}`
      );

      onValue(filesRef, snapshot => {
        if (!snapshot.exists()) {
          resolve(0);
        }

        resolve(snapshot.size);
      });
    });
  }
}
