import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input } from '@angular/core';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SecurityScoreCardApi } from 'src/app/core/apis/security-score-card.api';
import { SecurityScoreCard } from 'src/app/core/models/security-score-card';
import { Solution } from 'src/app/core/models/solution';
import { SecurityScoreCardService } from 'src/app/core/services/security-score-card.service';

@Component({
  standalone: true,
  selector: 'app-security-score-card',
  templateUrl: './security-score-card.component.html',
  styleUrls: ['./security-score-card.component.scss'],
  imports: [CommonModule, NzToolTipModule],
  providers: [SecurityScoreCardApi, SecurityScoreCardService],
})
export class SecurityScoreCardComponent implements AfterViewInit {
  @Input() solution?: Solution;

  scoreCard?: SecurityScoreCard;

  isSet: boolean | undefined;

  constructor(
    private sscApi: SecurityScoreCardApi,
    private sscService: SecurityScoreCardService
  ) {}

  ngAfterViewInit(): void {
    if (this.solution?.uid) {
      this.sscService
        .get(this.solution.uid)
        .then(res => {
          if (res.exists()) {
            this.isSet = true;
            this.scoreCard = res.data() as SecurityScoreCard;
          } else {
            this.isSet = false;
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
}
