import { Component, ChangeDetectionStrategy, Type } from '@angular/core';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
} from '@ngx-formly/core';
import { FormlyFieldProps } from '@ngx-formly/ng-zorro-antd/form-field';

type InputProps = FormlyFieldProps;

export interface FormlyInputFieldConfig extends FormlyFieldConfig<InputProps> {
  type: 'v-input' | Type<FormlyFieldInput>;
}

@Component({
  selector: 'app-v-input',
  template: `
    <veolia-input
      *ngIf="props.type !== 'number'; else numberTmp"
      [maxlength]="props.maxLength!"
      [disabled]="props.disabled!"
      [placeholder]="props.placeholder!"
      [formlyAttributes]="field"
      [ngModel]="formControl.value"
      (ngModelChange)="onChange($event)"
      [disabled]="props.disabled!"
      nz-input />
    <ng-template #numberTmp>
      <veolia-input
        type="number"
        [maxlength]="props.maxLength!"
        [disabled]="props.disabled!"
        [placeholder]="props.placeholder!"
        [formlyAttributes]="field"
        [ngModel]="formControl.value"
        (ngModelChange)="onChange($event)"
        [disabled]="props.disabled!"
        nz-input />
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldInput extends FieldType<FieldTypeConfig<InputProps>> {
  onChange(event: any) {
    this.formControl.markAsDirty();
    this.formControl.setValue(event);
    this.formControl.markAsTouched();
  }
}
