export type LogsPageStoreState<T> = {
  form: string;
  isLoading: boolean;
  logs: T[];
  solutionId?: string;
};

export const logsPageStoreInitialState = {
  form: "",
  isLoading: false,
  logs: [],
};
