<veolia-modal #userManagementModal>
  <div veolia-modal-title>
    <h4 nz-typography *ngIf="user?.type === 'Veolia'">
      {{ 'User management for ' | translate }}
      <strong>{{ user?.displayName }}</strong>
      <br />
      <span style="font-size: 0.8em"
        >ID: <em>{{ user?.uid! }}</em></span
      >
      <br />
      <span style="font-size: 0.8em"
        >Type: <em>{{ user?.type! }}</em></span
      >
    </h4>
    <div veolia-modal-content>
      <div *ngIf="user?.type === 'Veolia'">
        <div class="page-break" style="page-break-inside: avoid">
          <form nz-form [formGroup]="veoliaUserForm" (ngSubmit)="submitForm()">
            <nz-card
              nzType="inner"
              nzTitle="{{ 'User business unit and rights' | translate }}"
              class="spacing-v16">
              <nz-form-item>
                <nz-form-label>Business Unit</nz-form-label>
                <nz-form-control nzErrorTip="Please select a BU for the user">
                  <nz-select
                    [ngModel]="user?.businessUnit!"
                    (nzScrollToBottom)="loadBus()"
                    nzPlaceHolder="Select a BU"
                    formControlName="businessUnit">
                    <nz-option
                      *ngFor="let o of buList"
                      [nzValue]="o.nomBu"
                      [nzLabel]="o.nomBu"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label>Admin right ?</nz-form-label>
                <nz-form-control
                  [nzSpan]="5"
                  nzErrorTip="Please select true or false">
                  <nz-select
                    [ngModel]="user?.roles?.admin!"
                    formControlName="admin">
                    <nz-option [nzValue]="true" nzLabel="yes"></nz-option>
                    <nz-option [nzValue]="false" nzLabel="no"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label>Evaluator right ?</nz-form-label>
                <nz-form-control
                  [nzSpan]="5"
                  nzErrorTip="Please select true or false">
                  <nz-select
                    [ngModel]="user?.roles?.evaluator!"
                    formControlName="evaluator">
                    <nz-option [nzValue]="true" nzLabel="yes"></nz-option>
                    <nz-option [nzValue]="false" nzLabel="no"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label>Solution owner right ?</nz-form-label>
                <nz-form-control
                  [nzSpan]="5"
                  nzErrorTip="Please select true or false">
                  <nz-select
                    [ngModel]="user?.roles?.solutionOwner!"
                    formControlName="solutionOwner">
                    <nz-option [nzValue]="true" nzLabel="yes"></nz-option>
                    <nz-option [nzValue]="false" nzLabel="no"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item>
                <nz-form-label>Vendor right ?*</nz-form-label>
                <nz-form-control
                  [nzSpan]="5"
                  nzErrorTip="Please select true or false">
                  <nz-select
                    [ngModel]="user?.roles?.vendor!"
                    formControlName="vendor">
                    <nz-option [nzValue]="true" nzLabel="yes"></nz-option>
                    <nz-option [nzValue]="false" nzLabel="no"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <span nz-typography
                  ><em
                    >*The user will have the right to edit the forms</em
                  ></span
                >
              </nz-form-item>

              <p>
                Main role: <strong>{{ user?.role }}</strong>
              </p>
            </nz-card>
            <nz-form-item>
              <nz-form-control>
                <button nz-button nzType="primary">Save changes</button>
              </nz-form-control>
            </nz-form-item>
          </form>
        </div>
      </div>
    </div>
  </div>
</veolia-modal>
