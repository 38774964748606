<div class="layout-container">
  <app-navbar></app-navbar>
  <div id="content">
    <nz-spin class="base-spinner" [nzSpinning]="loading">
      <router-outlet></router-outlet>
    </nz-spin>
  </div>
  <div class="footer">
    <app-footer></app-footer>
  </div>
</div>
