import { AbstractControl } from "@angular/forms";

export function lowercaseValidator(control: AbstractControl) {
  const value = control.value;
  if (value && value !== value.toLowerCase()) {
    return { 'lowercase': true };
  }
  return null;
}

export function lowercaseValidatorMessage(err: any, field: any) {
  return 'This field should be in lowercase';
}
