import { Injectable } from '@angular/core';
import {
  Database,
  child,
  getDatabase,
  onValue,
  push,
  ref,
  remove,
  set,
  update,
} from '@angular/fire/database';
import { Comment } from '../models/comment';

@Injectable()
export class CommentService {
  constructor(public __database: Database = getDatabase()) {}

  getComment(form: string, solutionId: string) {
    return new Promise((resolve, reject) => {
      const commentsRef = ref(
        this.__database,
        `comments/${form}/${solutionId}`
      );

      onValue(commentsRef, snapshot => {
        if (!snapshot.exists()) {
          reject('No comments found');
        }

        const data = snapshot.val();
        resolve(data);
      });
    });
  }

  getCommentsCount(form: string, solutionId: string, fieldId: string) {
    return new Promise((resolve, reject) => {
      const commentsRef = ref(
        this.__database,
        `comments/${form}/${solutionId}/${fieldId}`
      );

      onValue(commentsRef, snapshot => {
        if (!snapshot.exists()) {
          resolve(0);
        }

        resolve(snapshot.size);
      });
    });
  }

  getComments(form: string, solutionId: string, fieldId: string) {
    return new Promise((resolve, reject) => {
      const commentsRef = ref(
        this.__database,
        `comments/${form}/${solutionId}/${fieldId}`
      );

      onValue(commentsRef, snapshot => {
        if (!snapshot.exists()) {
          reject('No comments found');
        }

        const data: Comment[] = [];

        snapshot.forEach(childSnapshot => {
          data.push({
            id: childSnapshot.key,
            ...(childSnapshot.val() as Comment),
          });
        });

        resolve(data);
      });
    });
  }

  getAllComments(form: string, solutionId: string) {
    return new Promise((resolve, reject) => {
      const commentsRef = ref(
        this.__database,
        `comments/${form}/${solutionId}`
      );

      onValue(commentsRef, snapshot => {
        if (!snapshot.exists()) {
          reject('No comments found');
        }

        const data: Record<string, Comment[]> = {};

        snapshot.forEach(childSnapshot => {
          data[childSnapshot.key] = childSnapshot.val() as Comment[];
        });

        resolve(data);
      });
    });
  }

  addComment(path: string, data: any) {
    const newCommentKey = push(child(ref(this.__database), path)).key;
    return set(ref(this.__database, `${path}/${newCommentKey}`), data);
  }

  updateComment(path: string, data: any) {
    console.log('edit', path, data);
    return update(ref(this.__database, path), data);
  }

  deleteComment(path: string) {
    return remove(ref(this.__database, `${path}`));
  }
}
