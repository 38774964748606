import { Injectable } from '@angular/core';
import { BaseApi } from './api';

@Injectable()
export class SecurityScoreCardApi extends BaseApi {
  getScore(solution_id?: string, link?: string) {
    return this.get(
      `/api/security_score_card/${solution_id}?link=${this.getRootDomain(link!)}`
    );
  }

  addPortofolio(solution_id?: string, link?: string) {
    return this.get(
      `/api/security_score_card/${solution_id}/add_to_portfolio?link=${this.getRootDomain(link!)}`
    );
  }

  removePortofolio(solution_id?: string, link?: string) {
    return this.get(
      `/api/security_score_card/${solution_id}/remove_from_portfolio?link=${this.getRootDomain(link!)}`
    );
  }

  getRootDomain(url: string) {
    // Remove protocol and www if present
    let domain = url.replace(/(https?:\/\/)?(www\.)?/, '');

    // Remove port number and path
    domain = domain.split(':')[0].split('/')[0];

    // Split the domain by dots
    const parts = domain.split('.');

    // If we have more than two parts, we need to handle potential subdomains
    if (parts.length > 2) {
      // Check for country code top-level domains (ccTLD)
      const potentialCcTLD = parts.slice(-2).join('.');
      if (ccTLDs.includes(potentialCcTLD)) {
        // If it's a known ccTLD, return last three parts
        return parts.slice(-3).join('.');
      } else {
        // Otherwise, return last two parts
        return parts.slice(-2).join('.');
      }
    }

    // If we have two or fewer parts, return the whole thing
    return domain;
  }
}

const ccTLDs = [
  'ac.uk',
  'co.uk',
  'gov.uk',
  'org.uk',
  'me.uk', // United Kingdom
  'com.au',
  'net.au',
  'org.au',
  'edu.au',
  'gov.au', // Australia
  'co.nz',
  'net.nz',
  'org.nz',
  'govt.nz', // New Zealand
  'co.za',
  'org.za',
  'gov.za', // South Africa
  'co.jp',
  'ne.jp',
  'ac.jp',
  'go.jp',
  'or.jp', // Japan
  'co.kr',
  'ne.kr',
  'or.kr',
  'go.kr',
  'ac.kr', // South Korea
  'com.cn',
  'net.cn',
  'org.cn',
  'gov.cn', // China
  'com.tw',
  'org.tw',
  'gov.tw', // Taiwan
  'com.hk',
  'org.hk',
  'gov.hk', // Hong Kong
  'in.th',
  'co.th',
  'ac.th',
  'go.th', // Thailand
  'com.sg',
  'org.sg',
  'gov.sg', // Singapore
  'com.my',
  'org.my',
  'gov.my', // Malaysia
  'com.ph',
  'org.ph',
  'gov.ph', // Philippines
  'com.vn',
  'gov.vn', // Vietnam
  'co.in',
  'firm.in',
  'org.in',
  'gov.in', // India
  'ac.ir',
  'co.ir',
  'gov.ir',
  'org.ir', // Iran
  'ac.il',
  'co.il',
  'org.il',
  'gov.il', // Israel
  'com.br',
  'net.br',
  'org.br',
  'gov.br', // Brazil
  'com.mx',
  'org.mx',
  'gob.mx', // Mexico
  'com.ar',
  'gob.ar', // Argentina
  'com.co',
  'org.co',
  'gov.co', // Colombia
  'com.pe',
  'gob.pe', // Peru
  'com.ve',
  'gob.ve', // Venezuela
  'com.ec',
  'gob.ec', // Ecuador
  'com.uy',
  'gub.uy', // Uruguay
  'co.ke',
  'go.ke', // Kenya
  'co.ug',
  'go.ug', // Uganda
  'com.ng',
  'gov.ng', // Nigeria
  'com.gh',
  'gov.gh', // Ghana
  'ac.ma',
  'co.ma',
  'gov.ma', // Morocco
  'co.za',
  'gov.za', // South Africa
  'com.eg',
  'gov.eg', // Egypt
  'com.tr',
  'org.tr',
  'gov.tr', // Turkey
  'com.ua',
  'gov.ua', // Ukraine
  'gov.ru',
  'com.ru', // Russia
  'com.pl',
  'org.pl',
  'gov.pl', // Poland
  'com.gr',
  'gov.gr', // Greece
  'com.ro',
  'gov.ro', // Romania
];
