import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { LegalForm } from '../models/legal-form';

@Injectable()
export class LegalFormService extends EntityService<LegalForm> {
  override get collectionName(): string {
    return 'legalForm';
  }
}
