<app-layout-page-header>
  <div class="row row-space-between row-bottom">
    <div>
      <app-layout-page-header-title
        title="Evaluator Home"></app-layout-page-header-title>
      <div>
        <!-- <hui-options-list
          *ngIf="vm$ | async as vm"
          [options]="[{ name: 'SaaS' }]"
          [selectedIndex]="vm.solutionType"
          (indexChange)="onSolutionTypeChange($event)"></hui-options-list> -->
      </div>
    </div>
    <div>
      <div class="buttons row end">
        <app-form-caption></app-form-caption>
        <app-documentation></app-documentation>
      </div>
    </div>
  </div>
</app-layout-page-header>
<div class="layout-body">
  <div class="layout-body-content table_container">
    <ng-container *ngIf="vm$ | async as vm">
      <nz-tabset>
        <nz-tab nzTitle="Requests to evaluate">
          <app-solutions-to-evaluate
            [solutionType]="vm.solutionType"></app-solutions-to-evaluate>
        </nz-tab>
        <nz-tab nzTitle="Evaluated requests">
          <app-evaluated-solutions
            [solutionType]="vm.solutionType"></app-evaluated-solutions>
        </nz-tab>
      </nz-tabset>
    </ng-container>
  </div>
</div>
