import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BaseLayoutComponent } from './components/base-layout/base-layout.component';
import { NavbarModule } from '../navbar/navbar.module';
import { FooterModule } from '../footer/footer.module';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageHeaderTitleComponent } from './components/page-header-title/page-header-title.component';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
  declarations: [
    BaseLayoutComponent,
    PageHeaderComponent,
    PageHeaderTitleComponent,
  ],
  exports: [BaseLayoutComponent, PageHeaderComponent, PageHeaderTitleComponent],
  imports: [
    CommonModule,
    NavbarModule,
    FooterModule,
    RouterModule,
    NzSpinModule,
    NzTypographyModule,
  ],
})
export class LayoutModule {}
