import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { User } from '../models/user';
import { HttpErrorResponse } from '@angular/common/http';
import { UserItemStoreState } from './states/user-item-store.state';

@Injectable()
export abstract class UserItemsStore<T> extends ComponentStore<
  UserItemStoreState<T>
> {
  private currentPage$ = this.select(state => state.currentPage);
  private isLoading$ = this.select(state => state.isLoading);
  private isSearching$ = this.select(state => state.isSearching);
  private error$ = this.select(state => state.error);
  private users$ = this.select(state => state.users);
  private total$ = this.select(state => state.total);
  private limit$ = this.select(state => state.limit);
  private userType$ = this.select(state => state.userType);
  private businessUnit$ = this.select(state => state.businessUnit);
  private isForward$ = this.select(state => state.isForward);
  private search$ = this.select(state => state.search);
  private uid$ = this.select(state => state.uid);
  private email$ = this.select(state => state.email);

  vm$ = this.select(
    this.currentPage$,
    this.isLoading$,
    this.isSearching$,
    this.error$,
    this.users$,
    this.total$,
    this.limit$,
    this.userType$,
    this.businessUnit$,
    this.isForward$,
    this.search$,
    this.uid$,
    this.email$,
    (
      currentPage,
      isLoading,
      isSearching,
      error,
      users,
      total,
      limit,
      userType,
      businessUnit,
      isForward,
      search,
      uid,
      email
    ) => ({
      currentPage,
      isSearching,
      isLoading,
      error,
      users,
      total,
      limit,
      userType,
      businessUnit,
      isForward,
      search,
      uid,
      email,
    })
  );

  setUid = this.updater((state, uid: string) => ({
    ...state,
    uid,
  }));

  setEmail = this.updater((state, email: string) => ({
    ...state,
    email,
  }));

  setPrevCursor = this.updater((state, prevCursor: any) => ({
    ...state,
    prevCursor,
  }));

  setNextCursor = this.updater((state, nextCursor: any) => ({
    ...state,
    nextCursor,
  }));

  setLimit = this.updater((state, limit: number) => ({
    ...state,
    limit,
  }));

  setUserType = this.updater((state, userType: number) => ({
    ...state,
    userType,
  }));

  setIsLoading = this.updater(state => ({
    ...state,
    isLoading: true,
  }));

  setError = this.updater((state, error: HttpErrorResponse) => ({
    ...state,
    isLoading: false,
    error: error.message,
  }));

  setPage = this.updater((state, page: number) => ({
    ...state,
    isForward: page > state.currentPage,
    currentPage: page,
  }));

  setPageReset = this.updater((state, page: number) => ({
    ...state,
    isSearching: false,
    mappedUsers: [],
    users: [],
    prevCursor: null,
    nextCursor: null,
    currentPage: page,
  }));

  setTotal = this.updater((state, total: number) => ({
    ...state,
    total,
  }));

  setUsers = this.updater((state, users: User[]) => ({
    ...state,
    isLoading: false,
    users,
  }));

  setBusinessUnit = this.updater((state, businessUnit: string) => ({
    ...state,
    businessUnit,
  }));

  setSearch = this.updater((state, search: string) => ({
    ...state,
    isSearching: search.length > 0,
    mappedUsers: [],
    users: [],
    prevCursor: null,
    nextCursor: null,
    search,
  }));

  getSelectedUser = (idUser: string): User | undefined => {
    return this.get().users.find(user => user.uid === idUser);
  };

  filterSearch = (users: User[]): User[] => {
    const searchTerm = this.get().search.toLowerCase();
    return users.filter(
      user =>
        user.uid?.toLowerCase().includes(searchTerm) ||
        user.email?.toLowerCase().includes(searchTerm) ||
        user.firstname?.toLowerCase().includes(searchTerm) ||
        user.lastname?.toLowerCase().includes(searchTerm) ||
        user.displayName?.toLowerCase().includes(searchTerm) ||
        user.role?.toLowerCase().includes(searchTerm) ||
        user.businessUnit?.toLowerCase().includes(searchTerm)
    );
  };
}
