export const environment = {
  useEmulators: false,
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCvatIkxuKRoHhJsI4lvNhGoi_sLISbaoA',
    authDomain: 'gbl-ist-ve-dlaaas-uat.firebaseapp.com',
    databaseURL: 'https://gbl-ist-ve-dlaaas-uat-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'gbl-ist-ve-dlaaas-uat',
    storageBucket: 'gbl-ist-ve-dlaaas-uat.appspot.com',
    messagingSenderId: '195412318049',
    appId: '1:195412318049:web:48c1105ae994a92581d720',
    experimentalForceLongPolling: true,
  },
};
