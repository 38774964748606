import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FollowedSolutionsStore } from './followed-solutions.store';
import { AuthService } from '../../../../core/services/auth.service';
import { Router } from '@angular/router';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';
import { TranslateService } from '@ngx-translate/core';
import { SolutionService } from 'src/app/core/services/solution.service';
import { OwnedSolutionsStore } from '../owned-solutions/owned-solutions.store';
import { EvaluatorSolutionsStore } from '../evaluator-solutions/evaluator-solutions.store';

@Component({
  selector: 'app-home-page-followed-solutions',
  templateUrl: './followed-solutions.component.html',
  styleUrls: ['./followed-solutions.component.scss'],
  providers: [
    FollowedSolutionsStore,
    OwnedSolutionsStore,
    EvaluatorSolutionsStore,
  ],
})
export class FollowedSolutionsComponent
  extends DataTableComponent
  implements OnChanges, OnInit
{
  @Input() solutionType = 0;

  vm$ = this.followedSolutionsStore.vm$;

  constructor(
    public override authService: AuthService,
    public override router: Router,
    public override translateService: TranslateService,
    public ownedSolutionsStore: OwnedSolutionsStore,
    public followedSolutionsStore: FollowedSolutionsStore,
    public evaluatorSolutionsStore: EvaluatorSolutionsStore,
    public override solutionService: SolutionService
  ) {
    super(authService, router, translateService, solutionService);
  }

  ngOnInit(): void {
    this.authService.userData.subscribe(user => {
      if (user) {
        this.followedSolutionsStore.setEmail(user?.email);
        this.followedSolutionsStore.setSolutionType(this.solutionType);
        this.followedSolutionsStore.getCount();
        this.followedSolutionsStore.getSolutions();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['solutionType']['firstChange']) {
      this.followedSolutionsStore.changeSolutionType(
        changes['solutionType'].currentValue
      );
    }
  }

  changePage(page: number) {
    this.followedSolutionsStore.changePage(page);
  }

  changeSize(size: Event) {
    const limit = parseInt((size.target as HTMLSelectElement).value);
    this.followedSolutionsStore.changeLimit(limit);
  }

  onSearch(event: string) {
    this.followedSolutionsStore.changeSearch(event);
  }

  onRefreshAfterEmit() {
    this.followedSolutionsStore.getSolutions();
    //this.ownedSolutionsStore.refreshSolutions();
    //this.evaluatorSolutionsStore.refreshSolutions();
  }
}
