import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";

export interface AdminHomePageState {
  solutionType: number;
}

@Injectable()
export class AdminHomePageStore extends ComponentStore<AdminHomePageState> {
  private solutionType$ = this.select((state) => state.solutionType);

  vm$ = this.select(

    this.solutionType$,
    ( solutionType) => ({
      solutionType
    })
  );

  constructor() {
    super({
      solutionType: 0,
    });
  }

  setSolutionType = this.updater((state, solutionType: number) => ({
    ...state,
    solutionType
  }));

}
