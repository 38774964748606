<div *ngIf="!isSet">
  {{ this.solution?.editorBrand }}
</div>
<div *ngIf="isSet" nz-tooltip [nzTooltipTitle]="scc" nzTool>
  <img
    *ngIf="scoreCard"
    width="24"
    height="24"
    [src]="scoreCard.grade_url"
    [alt]="scoreCard.grade" />
  {{ scoreCard?.name }}
</div>
<ng-template #scc>
  <ul style="padding: 8px">
    <li>
      Company Name: <b>{{ scoreCard?.name }}</b>
    </li>
    <li>
      Industry Style: <b>{{ scoreCard?.industry }}</b>
    </li>
    <li>
      Domain Name: <b>{{ scoreCard?.domain }}</b>
    </li>
    <li>
      Score: <b>{{ scoreCard?.score }}</b>
    </li>
    <li>
      Grade: <b>{{ scoreCard?.grade }}</b>
    </li>
    <li>Score over 30 days: {{ scoreCard?.last30day_score_change }}</li>
    <li>
      First add date:
      <b>{{ scoreCard?.firstAddDate?.toDate() | date: 'longDate' }}</b>
    </li>
    <li>
      Last update date:
      <b>{{ scoreCard?.lastUpdateDate?.toDate() | date: 'longDate' }}</b>
    </li>
  </ul>
  <a href="https://securityscorecard.com/" target="_blank">Visit the Security Score Card</a>
</ng-template>
