import { NgModule } from '@angular/core';
import { FileSummaryComponent } from './components/file-summary/file-summary.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { FileSummaryItemComponent } from './components/file-summary-item/file-summary-item.component';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';

@NgModule({
  declarations: [FileSummaryComponent, FileSummaryItemComponent],
  exports: [FileSummaryComponent, FileSummaryItemComponent],
  imports: [
    CommonModule,
    NzCollapseModule,
    NzDividerModule,
    NzListModule,
    NzModalModule,
    TranslateModule,
    VeoliaButtonComponent,
    VeoliaIconComponent,
    VeoliaModalModule,
  ],
})
export class FileSummaryModule {}
