import { NgModule } from '@angular/core';
import { DataTableComponent } from './components/data-table/data-table.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { VeoliaChipComponent } from '@veolia.com/vds-angular-components/chips';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';
import { VeoliaLinkComponent } from '@veolia.com/vds-angular-components/link';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import {
  VeoliaTableComponent,
  VeoliaTableColumnComponent,
  VeoliaTableHeaderComponent,
  VeoliaTableCellComponent,
  VeoliaTableActionComponent,
} from '@veolia.com/vds-angular-components/table';
import { VeoliaTagComponent } from '@veolia.com/vds-angular-components/tag';
import { CommonModule } from '@angular/common';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  declarations: [DataTableComponent],
  imports: [
    CommonModule,
    NzIconModule,
    NzMenuModule,
    NzDropDownModule,
    VeoliaIconComponent,
    VeoliaChipComponent,
    VeoliaLinkComponent,
    VeoliaTableComponent,
    VeoliaTableColumnComponent,
    VeoliaTableHeaderComponent,
    VeoliaTableColumnComponent,
    VeoliaTableCellComponent,
    VeoliaTableActionComponent,
    VeoliaTagComponent,
    TranslateModule,
  ],
  exports: [DataTableComponent],
  providers: [TranslateService],
})
export class DataTableModule {}
