import { Injectable } from '@angular/core';
import { map, switchMap, tap, withLatestFrom } from 'rxjs';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { Article32FormService } from 'src/app/core/services/article-32-form.service';
import { ChapterCompletionStatusService } from 'src/app/core/services/chapter-completion-status.service';
import { CommentService } from 'src/app/core/services/comment.service';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { FileService } from 'src/app/core/services/file.service';
import { ReservationService } from 'src/app/core/services/reservation.service';
import { FormStore } from 'src/app/core/stores/form.store';

@Injectable({
  providedIn: 'root',
})
export class Article32FormStore extends FormStore {
  constructor(
    protected override evaluationService: EvaluationService,
    protected override reservationService: ReservationService,
    protected override chapterCompletionService: ChapterCompletionStatusService,
    protected override commentService: CommentService,
    protected override fileService: FileService,
    private article32FormService: Article32FormService
  ) {
    super(
      evaluationService,
      reservationService,
      chapterCompletionService,
      commentService,
      fileService
    );
  }

  getArticle32Form = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => this.setNotReady()),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ solution, solutionId }) => {
        return this.article32FormService
          .get(solutionId)
          .then(data => {
            if (data.exists()) {
              this.setForm({
                uid: solution?.uid,
                ...data.data(),
              } as Article32Form);
              this.setReady();
            } else {
              this.article32FormService
                .create(
                  this.initForm(solution, 'art32') as unknown as Article32Form,
                  solution?.uid
                )
                .then(() => {
                  this.setForm({
                    uid: solution?.uid,
                    ...this.initForm(solution, 'art32'),
                  } as unknown as Article32Form);

                  this.setReady();
                })
                .catch(() => this.setReady());
            }
          })
          .catch(error => {
            console.log(error);
            this.setReady();
          });
      })
    );
  });
}
