<div *ngIf="vm$ | async as vm">
  <app-simple-spinner-placeholder *ngIf="!vm.ready" />
  <app-form-toolbar
    *ngIf="vm.ready"
    [comments]="vm.comments"
    [currentForm]="vm.form"
    [fileList]="vm.fileList"
    [files]="vm.files"
    [evaluation]="vm.evaluation"
    [formName]="formName"
    [formCompletionStatus]="formCompletionStatus"
    [formStatus]="formStatus"
    [solution]="solution"
    [businessUnit]="businessUnit"
    (saveAll)="saveAll()"
    (markAsComplete)="markAsComplete(formCompletionStatus)"
    (refreshForm)="refreshForm()"
    [isDirty]="isDirty"
    [isSaving]="isSaving" />
  <div nz-row [nzGutter]="16">
    <div
      *ngIf="vm.ready"
      nz-col
      class="gutter-row"
      [nzSpan]="5"
      [nzLg]="6"
      [nzMd]="6"
      [nzSm]="24"
      [nzXs]="24">
      <div class="rounded-white spacing-b16">
        <h4 nz-typography>Table of content</h4>
        <div>
          <app-form-block-button
            [showId]="false"
            id="1"
            [title]="'LA_0' | translate"
            (click)="selectChapter('LA_0')"
            [evaluation]="vm.evaluation"
            evaluationField="LA_0_RADIO"
            [selected]="selectedChapter === 'LA_0'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="2"
            [title]="'LA_1' | translate"
            (click)="selectChapter('LA_1')"
            [evaluation]="vm.evaluation"
            evaluationField="LA_1_RADIO"
            [selected]="selectedChapter === 'LA_1'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="3"
            [title]="'LA_2' | translate"
            (click)="selectChapter('LA_2')"
            [evaluation]="vm.evaluation"
            evaluationField="LA_2_RADIO"
            [selected]="selectedChapter === 'LA_2'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="4"
            [title]="'LA_3' | translate"
            (click)="selectChapter('LA_3')"
            [evaluation]="vm.evaluation"
            evaluationField="LA_3_RADIO"
            [selected]="selectedChapter === 'LA_3'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="5"
            [title]="'LA_4' | translate"
            (click)="selectChapter('LA_4')"
            [evaluation]="vm.evaluation"
            evaluationField="LA_4_RADIO"
            [selected]="selectedChapter === 'LA_4'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="6"
            [title]="'LA_5' | translate"
            (click)="selectChapter('LA_5')"
            [evaluation]="vm.evaluation"
            evaluationField="LA_5_RADIO"
            [selected]="selectedChapter === 'LA_5'"
            [unlocked]="true">
          </app-form-block-button>
        </div>
      </div>
    </div>
    <div
      *ngIf="vm.ready"
      nz-col
      class="gutter-row chapters"
      [nzLg]="18"
      [nzMd]="18"
      [nzSm]="24"
      [nzXs]="24">
      <app-form-chapter
        *ngIf="fields['LA_0']?.fields"
        [evaluation]="fields['LA_0']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'LA_0'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Legal Form"
        [formModel]="vm.form"
        [formFields]="fields['LA_0'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('LA_0')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['LA_1']?.fields"
        [evaluation]="fields['LA_1']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'LA_1'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Legal Form"
        [formModel]="vm.form"
        [formFields]="fields['LA_1'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('LA_1')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['LA_2']?.fields"
        [evaluation]="fields['LA_2']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'LA_2'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Legal Form"
        [formFields]="fields['LA_2'].fields"
        [formModel]="vm.form"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('LA_2')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['LA_3']?.fields"
        [evaluation]="fields['LA_3']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'LA_3'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Legal Form"
        [formModel]="vm.form"
        [formFields]="fields['LA_3'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('LA_3')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['LA_4']?.fields"
        [evaluation]="fields['LA_4']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'LA_4'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Legal Form"
        [formModel]="vm.form"
        [formFields]="fields['LA_4'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('LA_4')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['LA_5']?.fields"
        [evaluation]="fields['LA_5']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'LA_5'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Legal Form"
        [formModel]="vm.form"
        [formFields]="fields['LA_5'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('LA_5')"
        (formEdited)="formEdited($event)"></app-form-chapter>
    </div>
  </div>
</div>
