import { Injectable } from '@angular/core';
import {
  Database,
  child,
  getDatabase,
  onValue,
  push,
  query,
  ref,
  set,
  update,
} from '@angular/fire/database';

@Injectable()
export class ReservationService {
  constructor(public __database: Database = getDatabase()) {}

  getReservations(form: string, solutionId: string) {
    return new Promise((resolve, reject) => {
      const reservationsRef = query(
        ref(this.__database, `reservations/${solutionId}/${form}`)
      );

      onValue(reservationsRef, snapshot => {
        if (!snapshot.exists()) {
          reject('No Reservations found');
        }

        const data = snapshot.val();
        resolve(data);
      });
    });
  }

  addReservation(path: string, data: any) {
    const newReservationKey = push(child(ref(this.__database), path)).key;
    return set(ref(this.__database, `${path}/${newReservationKey}`), data);
  }

  updateReservation(path: string, data: any) {
    return update(ref(this.__database, path), data);
  }
}
