<div class="layout-body">
  <div class="layout-body-content">
    <div id="edit_password_page">
      <h3 nz-typography>Edit your password</h3>
      <div class="info">
        <strong
          >Password MUST reach at least 6 characters and max 32 characters
          containing four types of characters:</strong
        >
        <ul>
          <li>Lowercase(s)</li>
          <li>Capital letter(s)</li>
          <li>Digit(s)</li>
          <li>Special character(s) e.g ~*:!@%-_/</li>
        </ul>
      </div>
      <form
        nz-form
        nzLayout="vertical"
        [formGroup]="form"
        (ngSubmit)="submit()">
        <formly-form
          [model]="model"
          [fields]="fields"
          [options]="options"
          [form]="form">
          <ng-template formlyTemplate="divider" let-field>
            <nz-divider></nz-divider>
          </ng-template>
        </formly-form>
        <veolia-button *ngIf="!loading" htmlType="submit"
          >Edit password</veolia-button
        >
        <nz-spin *ngIf="loading" nzSimple></nz-spin>
      </form>
    </div>
  </div>
</div>
