import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";

export interface LoginPageState {
  isVisible: boolean;
}

@Injectable({
  providedIn: "root"
})
export class LoginPageStore extends ComponentStore<LoginPageState> {
  private isVisible$ = this.select((state) => state.isVisible);

  vm$ = this.select(this.isVisible$, (isVisible) => ({
    isVisible
  }));

  constructor() {
    super({
      isVisible: false
    });
  }

  openLoginForm = this.updater((state) => ({
    ...state,
    isVisible: true
  }));

  closeLoginForm = this.updater((state) => ({
    ...state,
    isVisible: false
  }));
}
