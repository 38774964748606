import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { FormlyFieldProps } from '@ngx-formly/ng-zorro-antd/form-field';

interface DatepickerProps extends FormlyFieldProps {
  defaultValue?: string;
}

@Component({
  selector: 'app-nz-time-picker',
  template: `
    <input
      type="time"
      [disabled]="props.disabled!"
      [formlyAttributes]="field"
      [ngModel]="formControl.value"
      (ngModelChange)="onChange($event)"
      placeholder="Time" />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormNZTimepickerComponent extends FieldType<
  FieldTypeConfig<DatepickerProps>
> {
  onChange(value: any) {
    this.formControl.markAsDirty();
    this.formControl.patchValue(value);
    this.formControl.markAsTouched();
  }
}
