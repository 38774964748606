import { Injectable } from "@angular/core";
import { Article28Form } from "../models/article-28-form";
import { EntityService } from "./entity.service";

@Injectable()
export class Article28FormService extends EntityService<Article28Form>{
  override get collectionName(): string {
    return 'article28Form';
  }
}
