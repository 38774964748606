import { Component } from '@angular/core';
import { Auth, sendPasswordResetEmail } from '@angular/fire/auth';
import {
  FormGroup,
  FormControl,
  Validators,
  NonNullableFormBuilder,
} from '@angular/forms';

@Component({
  selector: 'app-reset-form',
  templateUrl: './reset-form.component.html',
  styleUrls: ['./reset-form.component.scss'],
})
export class ResetFormComponent {
  forgotPasswordForm: FormGroup<{
    email: FormControl<string>;
  }> = this.fb.group({
    email: ['', [Validators.required]],
  });

  constructor(
    private auth: Auth,
    private fb: NonNullableFormBuilder
  ) {}

  submitForm() {
    if (this.forgotPasswordForm.valid) {
      const { email } = this.forgotPasswordForm.value;

      if (email) {
        sendPasswordResetEmail(this.auth, email)
          .then(data => {
            console.log('Password send');
          })
          .catch(error => {
            console.log('Error', error);
          });
      }
    }
  }
}
