import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { DesignForm } from 'src/app/core/models/design-form';
import { LegalForm } from 'src/app/core/models/legal-form';
import { Reservation } from 'src/app/core/models/reservation';
import { Solution } from 'src/app/core/models/solution';

@Component({
  selector: 'app-summary-reservation-overview',
  templateUrl: 'summary-reservation-overview.component.html',
  styleUrls: ['summary-reservation-overview.component.scss'],
})
export class SummaryReservationOverviewComponent {
  @Input() formName?: string;
  @Input() form?: Article28Form | Article32Form | DesignForm | LegalForm;
  @Input() isVisible = false;
  @Input() reservations?: Reservation[];
  @Input() solution?: Solution;
  @Input() businessUnit?: BusinessUnit;

  @Output() editedReservation: EventEmitter<Reservation> = new EventEmitter();
  @Output() closeModal: EventEmitter<void> = new EventEmitter();

  @ViewChild('modal') modal!: VeoliaModalComponent;

  editedReference?: string;

  close() {
    this.closeModal.emit();
  }

  onEdit(reference: string) {
    this.editedReference = reference;
  }

  onEdited(reservation: Reservation) {
    this.editedReservation.emit(reservation);
    this.editedReference = undefined;
  }

  onCancel() {
    this.editedReference = undefined;
  }

  open() {
    this.modal.open();
  }
}
