import { Component, Input } from '@angular/core';
import { getTagColor } from 'src/app/core/utils/colors';

@Component({
  selector: 'app-evaluation-section-header',
  templateUrl: './evaluation-section-header.component.html',
  styleUrls: ['./evaluation-section-header.component.scss'],
})
export class EvaluationSectionHeaderComponent {
  @Input() title?: string;
  @Input() status?: string;

  getFormStatusTheme(status: string) {
    return getTagColor(status);
  }
}
