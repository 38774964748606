import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-documentation-item',
  templateUrl: './documentation-item.component.html',
  styleUrls: ['./documentation-item.component.scss'],
})
export class DocumentationItemComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() link = '';
}
