import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { User } from '../models/user';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    public authService: AuthService,
    public router: Router,
    public userService: UserService,
    private veoliaMessageService: VeoliaMessageService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {
    return new Promise((resolve, reject) => {
      if (this.authService.isLoggedIn !== true) {
        this.router.navigate(['/login']);
        resolve(false);
      }

      this.authService.user$.subscribe(user => {
        if (user) {
          this.userService
            .getUserData(user?.uid)
            .then(user => {
              const userData = user.data() as User;
              if (!userData.isVeolia && !userData.termsAccepted) { //VENDOR
                this.router.navigate(['/auth/first-connection']);
                resolve(true);
              }
              if(!userData.isVeolia && userData.termsAccepted && !userData.passwordEdited) { //VENDOR
                this.router.navigate(['/auth/edit-first-password']);
                resolve(true);
              }
              if(!userData.isVeolia && userData.termsAccepted && userData.passwordEdited) { //VENDOR
                resolve(true); //rediction this.router.navigate(['/']);
              }
              if (userData.isVeolia && !userData.termsAccepted) { //VEOLIA
                this.router.navigate(['/auth/first-connection']);
                resolve(true);
              }
              if (userData.isVeolia && userData.termsAccepted) { //VEOLIA
                resolve(true); //rediction this.router.navigate(['/']);
              }
            })
            .catch(() => {
              this.router.navigate(['/auth/login']);
              resolve(false);
            });
        }
      });
    });
  }
}
