import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-form-block',
  templateUrl: './form-block.component.html',
  styleUrls: ['./form-block.component.scss'],
})
export class FormBlockComponent {
  @Input() id?: string;
  @Input() selectedId?: string;
  @Input() title?: string;
  @Input() unlocked?: boolean;
  @Input() actionTitle = 'Next';
  @Input() loading?: boolean;
  @Output() submitted: EventEmitter<string> = new EventEmitter<string>();

  @Output() selected: EventEmitter<string> = new EventEmitter<string>();

  onClick(status: boolean) {
    if (status) {
      this.selected.emit(this.id);
    }
  }

  submit() {
    this.submitted.emit(this.id);
  }
}
