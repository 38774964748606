import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

export interface AdminUsersPageState {
  userType: number;
}

@Injectable()
export class AdminUsersPageStore extends ComponentStore<AdminUsersPageState> {
  private userType$ = this.select(state => state.userType);

  vm$ = this.select(this.userType$, userType => ({
    userType,
  }));

  constructor() {
    super({
      userType: 0,
    });
  }

  setUserType = this.updater((state, userType: number) => ({
    ...state,
    userType,
  }));
}
