import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Domain } from '../models/domain';
import {
  getDocs,
  collection,
  query,
  collectionData,
  orderBy,
} from '@angular/fire/firestore';
import { nameof } from '../utils/nameof';
import { Observable } from 'rxjs';
import { Entity } from '../models/bases';

@Injectable()
export class DomainService extends BaseService<Domain> {
  get collectionName(): string {
    return 'domainsRef';
  }

  async getDomains() {
    const rawDocs = await getDocs(
      query(collection(this._firestore, this.collectionName))
    );
    const docs = rawDocs.docs.map(doc => doc.data() as Domain);
    return docs;
  }

  override observeAll() {
    return collectionData(
      query(
        collection(this._firestore, this.collectionName),
        orderBy('domainName')
      ),
      {
        idField: nameof<Entity>('uid'),
      }
    ) as Observable<Domain[]>;
  }
}
