<ng-container *ngIf="vm$ | async as vm">
  <nz-alert
    *ngIf="vm.error"
    nzType="error"
    [nzMessage]="vm.error"
    nzShowIcon></nz-alert>
  <div>
    <div>
      <div class="row row-right row-vertical-center">
        <app-export-csv-button
          exportType="evaluated"
          [solutionType]="vm.solutionType === 0 ? 'SaaS' : 'SaaS'" />
        <search-input
          [isSearching]="vm.isSearching"
          (onChange)="onSearch($event)"></search-input>
      </div>
      <simple-spinner
        style="text-align: center !important"
        *ngIf="vm.isLoading"></simple-spinner>
      <app-solutions-table
        (refresh)="onRefreshAfterEmit()!"
        [actions]="[
          'edit',
          'share',
          'summary',
          'logs',
          'access',
          'cancel',
          'restore',
          'ssc'
        ]"
        [solutions]="vm.solutions"
        [solutionType]="vm.solutionType"
        [showId]="true"
        [isSearching]="vm.isSearching"
        (sorted)="tableSorted($event)"></app-solutions-table>
    </div>
  </div>
  <app-pagination
    *ngIf="!vm.isSearching || vm.search === ''"
    [loading]="vm.isLoading || vm.isSearching"
    [size]="vm.limit"
    [page]="vm.currentPage"
    [total]="vm.total"
    (onPageChange)="changePage($event)"
    (onSizeChange)="changeSize($event)"></app-pagination>
</ng-container>
