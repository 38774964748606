import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LoginOptionComponent } from './components/login-option/login-option.component';
import { LoginFormComponent } from './components/login-form/login-form.component';

import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { NzLayoutModule } from 'ng-zorro-antd/layout';

import { AuthService } from '../../core/services/auth.service';
import { UserService } from '../../core/services/user.service';
import { FirstConnectionPageComponent } from './pages/first-connection-page/first-connection-page.component';
import { LayoutModule } from '../../shared/features/layout/layout.module';
import { CountryService } from '../../core/services/country.service';
import { BusinessUnitService } from '../../core/services/business-unit.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { provideComponentStore } from '@ngrx/component-store';
import { FirstConnectionPageStore } from './pages/first-connection-page/first-connection-page.store';
import { TranslateModule } from '@ngx-translate/core';
import { AuthModule } from '@angular/fire/auth';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import { VeoliaDividerComponent } from '@veolia.com/vds-angular-components/divider';
import { VeoliaInputComponent } from '@veolia.com/vds-angular-components/input';
import { ResetFormComponent } from './components/reset-form/reset-form.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import {
  VeoliaMessageComponent,
  VeoliaMessageService,
} from '@veolia.com/vds-angular-components/message';
import { EditPasswordPageComponent } from './pages/edit-password-page/edit-password-page.component';
import { FormlyModule } from '@ngx-formly/core';
import { NzDividerModule } from 'ng-zorro-antd/divider';

export function fieldMatchValidator(control: AbstractControl) {
  const { newPassword, newPasswordConfirm } = control.value;

  if (!newPassword || !newPasswordConfirm) {
    return null;
  }

  if (newPassword === newPasswordConfirm) {
    return null;
  }

  return { fieldMatch: { message: 'Password Not Matching' } };
}

export function PasswordRuleValidator(
  control: AbstractControl
): ValidationErrors | null {
  return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~*:!@%\-_/])[A-Za-z\d~*:!@%\-_/]{6,32}$/.test(
    control.value
  )
    ? null
    : { passwordRule: true };
}

@NgModule({
  declarations: [
    LoginPageComponent,
    LoginOptionComponent,
    LoginFormComponent,
    FirstConnectionPageComponent,
    ResetFormComponent,
    EditPasswordPageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FormlyModule.forChild({
      validators: [
        { name: 'fieldMatch', validation: fieldMatchValidator },
        { name: 'passwordRule', validation: PasswordRuleValidator },
      ],
      validationMessages: [
        {
          name: 'passwordRule',
          message:
            'Password must contain At least 1 digit, at least 1 lowercase letter and 1 uppercase letter, at least 1 special character (from ~*:!@%-_/).',
        },
      ],
    }),
    ReactiveFormsModule,
    NzButtonModule,
    NzDividerModule,
    NzFormModule,
    NzInputModule,
    NzModalModule,
    NzIconModule,
    NzTypographyModule,
    NzSelectModule,
    NzRadioModule,
    NzLayoutModule,
    NzSpinModule,
    LayoutModule,
    AuthModule,
    TranslateModule,
    AuthenticationRoutingModule,
    VeoliaButtonComponent,
    VeoliaDividerComponent,
    VeoliaInputComponent,
    VeoliaModalModule,
    VeoliaMessageComponent,
  ],
  providers: [
    BusinessUnitService,
    CountryService,
    UserService,
    VeoliaMessageService,
    provideComponentStore(FirstConnectionPageStore),
  ],
})
export class AuthenticationModule {}
