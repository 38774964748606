import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { SearchInputComponent } from "./components/search-input.component";

@NgModule({
  declarations: [
    SearchInputComponent
  ],
  imports: [
    CommonModule,
    NzIconModule,
    NzInputModule,
  ],
  exports: [
    SearchInputComponent
  ],
})
export class SearchInputModule {}
