import { Component, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import {
  FirstConnectionExternalFormValue,
  FirstConnectionVeoliaFormValue,
} from './first-connection-page.type';
import { FirstConnectionPageStore } from './first-connection-page.store';
import { UserService } from '../../../../core/services/user.service';
import { Timestamp } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AuthService } from '../../../../core/services/auth.service';
import { Auth } from '@angular/fire/auth';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';

@Component({
  selector: 'app-first-connection-page',
  templateUrl: './first-connection-page.component.html',
  styleUrls: ['./first-connection-page.component.scss'],
})
export class FirstConnectionPageComponent implements OnInit {
  vm$ = this.firstConnectionPageStore.vm$;

  isVeolia?: boolean;

  public firstConnectionVeoliaForm: FormGroup<FirstConnectionVeoliaFormValue> =
    this.fb.group({
      businessUnit: [''],
      country: [''],
      role: [''],
      displayName: [''],
      email: [''],
      photoURL: [''],
      uid: [''],
      terms_RADIO: ['', [Validators.required]],
    });

  public firstConnectionExternalForm: FormGroup<FirstConnectionExternalFormValue> =
    this.fb.group({
      terms_RADIO: ['', [Validators.required]],
    });

  constructor(
    private aFA: Auth,
    private authService: AuthService,
    private fb: NonNullableFormBuilder,
    private firstConnectionPageStore: FirstConnectionPageStore,
    private router: Router,
    private userService: UserService,
    private veoliaMessageService: VeoliaMessageService
  ) {}

  ngOnInit(): void {
    // Load data
    this.firstConnectionPageStore.loadCountries();
    this.firstConnectionPageStore.loadBusinessUnits();

    this.vm$.subscribe(vm => {
      if (vm.currentUser) {
        if (vm.currentUser.isVeolia) {
          this.isVeolia = true;
          this.firstConnectionVeoliaForm.patchValue({
            businessUnit: vm.currentUser.businessUnit,
            country: vm.currentUser.country,
            role: vm.currentUser.role,
            displayName: vm.currentUser.displayName,
            email: vm.currentUser.email,
            uid: vm.currentUser.uid,
          });

          // Disable fields
          this.firstConnectionVeoliaForm.get('role')?.disable();
          this.firstConnectionVeoliaForm.get('email')?.disable();
          this.firstConnectionVeoliaForm.get('displayName')?.disable();
        } else {
          console.log('vm.currentUser', vm.currentUser);
        }
      }
    });
  }

  public hasTermsAcceptedError = () => {
    return this.isVeolia
      ? this.firstConnectionVeoliaForm.controls['terms_RADIO'].hasError(
          'required'
        )
      : this.firstConnectionExternalForm.controls['terms_RADIO'].hasError(
          'required'
        );
  };

  public async onSubmitVeolia(): Promise<void> {
    if (this.firstConnectionVeoliaForm.valid) {
      // Check if terms are accepted
      if (this.firstConnectionVeoliaForm.value.terms_RADIO === 'No') {
        this.veoliaMessageService.create({
          icon: 'error',
          title: '',
          type: 'error',
          content: 'You must accept the terms and conditions to continue',
        });
      } else {
        // Update user to mark terms as accepted and redirect to home
        const currentUser = await this.aFA.currentUser;
        const userId = currentUser?.uid;
        if (userId) {
          this.userService
            .update(userId, this.firstConnectionVeoliaForm.value, false)
            .then(() => {
              this.userService.update(
                userId,
                {
                  termsAccepted: true,
                  termsAcceptedDate: Timestamp.fromDate(new Date(Date.now())),
                },
                false
              );
              this.veoliaMessageService.create(
                {
                  icon: 'check',
                  title: 'Success',
                  type: 'success',
                  content: 'You have accepted the terms and conditions !',
                },
                { duration: 7000 }
              );
              this.authService.RefreshUserData();
              this.router.navigate(['']);
            });
        }
      }
    }
  }

  public async onSubmitExternal(): Promise<void> {
    console.log(
      'this.firstConnectionExternalForm',
      this.firstConnectionExternalForm.value
    );
    if (this.firstConnectionExternalForm.valid) {
      // Check if terms are accepted
      console.log('valid');
      if (this.firstConnectionExternalForm.value.terms_RADIO === 'No') {
        this.veoliaMessageService.create(
          {
            icon: 'error',
            title: '',
            type: 'error',
            content: 'You must accept the terms and conditions to continue',
          },
          { duration: 7000 }
        );
      } else {
        console.log(
          'this.firstConnectionExternalForm.value',
          this.firstConnectionExternalForm.value
        );
        // Update user to mark terms as accepted and redirect to home
        const currentUser = await this.aFA.currentUser;

        const userId = currentUser?.uid;
        if (userId) {
          this.userService.update(
            userId,
            {
              termsAccepted: true,
              termsAcceptedDate: Timestamp.fromDate(new Date(Date.now())),
            },
            false
          );
          this.veoliaMessageService.create(
            {
              icon: 'check',
              title: 'Success',
              type: 'success',
              content: 'You have accepted the terms and conditions !',
            },
            { duration: 7000 }
          );
          this.authService.RefreshUserData();
          this.router.navigate(['']);
        }
      }
    }
  }
}
