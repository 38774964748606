import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AuthService } from '../../../../core/services/auth.service';
import { OtherBusinessUnitSolutionsStore } from './other-business-unit-solutions.store';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SolutionService } from 'src/app/core/services/solution.service';

@Component({
  selector: 'app-home-page-other-business-unit-solutions',
  templateUrl: './other-business-unit-solutions.component.html',
  styleUrls: ['./other-business-unit-solutions.component.scss'],
  providers: [OtherBusinessUnitSolutionsStore],
})
export class OtherBusinessUnitSolutionsComponent
  extends DataTableComponent
  implements OnChanges, OnInit
{
  @Input() solutionType = 0; 
  @ViewChild('search') search: ElementRef<HTMLInputElement> = {} as ElementRef;

  i: number | undefined;
  vm$ = this.otherBusinessUnitSolutionsStore.vm$;

  constructor(
    public override authService: AuthService,
    public override router: Router,
    public override translateService: TranslateService,
    private otherBusinessUnitSolutionsStore: OtherBusinessUnitSolutionsStore,
    public override solutionService: SolutionService
  ) {
    super(authService, router, translateService, solutionService);
  }

  ngOnInit(): void {
    this.authService.userData.subscribe(user => {
      if (user) {
        this.otherBusinessUnitSolutionsStore.setBusinessUnit(
          user?.businessUnit
        );
        this.otherBusinessUnitSolutionsStore.setSolutionType(this.solutionType);
        this.otherBusinessUnitSolutionsStore.fetchData();
      }
    });
    this.i = 3;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['solutionType']['firstChange']) {
      this.otherBusinessUnitSolutionsStore.changeSolutionType(
        changes['solutionType'].currentValue
      );
    }
  }

  changePage(page: number) {
    this.otherBusinessUnitSolutionsStore.changePage(page);
  }

  changeSize(size: Event) {
    const limit = parseInt((size.target as HTMLSelectElement).value);
    this.otherBusinessUnitSolutionsStore.changeLimit(limit);
  }

  onSearch(event: string) {
    this.otherBusinessUnitSolutionsStore.changeSearch(event);
  }
}
