import { Component } from '@angular/core';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  imports: [NzToolTipModule],
  standalone: true,
})
export class LogoComponent {
  goToVeolia() {
    window.open('https://veolia.com', '_blank');
  }
}
