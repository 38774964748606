<div class="container row row-space-between spacing-v8">
  <span
    ><a href="mailto:{{ email }}">{{ email }}</a></span
  >
  <div>
    <veolia-button
      *ngIf="isDeletable && solutionOwner !== email"
      [disabled]="!isDeletable!"
      [size]="'default'"
      [variant]="'primary'"
      [htmlType]="'button'"
      [icon]="'delete'"
      [iconPosition]="'left'"
      (click)="removeUser()">
      Delete
    </veolia-button>
  </div>
</div>
