import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { DesignForm } from '../models/design-form';

@Injectable()
export class DesignFormService extends EntityService<DesignForm> {
  override get collectionName(): string {
    return 'designForm';
  }
}
