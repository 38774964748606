import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(
    public authService: AuthService,
    public router: Router,
    public userService: UserService,
    private veoliaMessageService: VeoliaMessageService
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | UrlTree | boolean {
    if (this.authService.isLoggedIn !== true) {
      //OK
      return true; //retourne sur route par défaut -> /login
    } else {
      //OK
      this.router.navigate(['']);
      this.veoliaMessageService.create(
        {
          title: '',
          icon: 'warning',
          content: 'You are already connected',
        },
        {
          duration: 10000,
        }
      );
    }

    if (
      this.authService.isLoggedIn &&
      this.authService.hasAcceptedTerms !== true
    ) {
      this.router.navigate(['/auth/first-connection']);
    }

    if (
      this.authService.isLoggedIn &&
      this.authService.hasAcceptedTerms === true
    ) {
      this.router.navigate(['/']);
    }

    return false;
  }
}
