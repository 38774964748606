<div id="login-page">
  <app-login-option
    (openLoginForm)="modal.open()"
    (onSAML)="googleLogin()"></app-login-option>
  <ng-container *ngIf="vm$ | async as vm">
    <ng-template #loginForm let-data>
      <app-login-form
        [isVisible]="vm.isVisible"
        (onClose)="closeLoginForm()"
        (onSubmit)="submitLoginForm($event)"></app-login-form>
    </ng-template>
  </ng-container>
</div>

<veolia-modal #modal>
  <div veolia-modal-title>Login</div>
  <div veolia-modal-content>
    <form
      nz-form
      [formGroup]="validateForm"
      class="login-form"
      (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your username!">
          <nz-input-group nzPrefixIcon="user" class="light-gray">
            <input
              class="light-gray"
              type="email"
              nz-input
              formControlName="userName"
              placeholder="Username" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your Password!">
          <nz-input-group [nzSuffix]="suffixTemplate" nzPrefixIcon="lock" class="light-gray">
            <input
              [type]="passwordVisible ? 'text' : 'password'"
              class="light-gray"
              type="password"
              nz-input
              formControlName="password"
              placeholder="Password" />
          </nz-input-group>
          <ng-template #suffixTemplate>
            <span
              nz-icon
              [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
              (click)="passwordVisible = !passwordVisible"
            ></span>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <div nz-row class="login-form-margin">
        <div nz-col [nzSpan]="12">
          <a (click)="launchResetPassword()" class="login-form-forgot"
            >Forgot password</a
          >
        </div>
      </div>
    </form>
  </div>

  <div veolia-modal-footer>
    <veolia-button
      *ngIf="!loading"
      [htmlType]="'reset'"
      (click)="modal.close()"
      variant="secondary">
      Cancel
    </veolia-button>
    <veolia-button *ngIf="!loading" (click)="submitForm()">
      Log In
    </veolia-button>
    <nz-spin *ngIf="loading" nzSimple></nz-spin>
  </div>
</veolia-modal>

<veolia-modal #forgotPasswordModal>
  <div veolia-modal-title>Forgot Password</div>
  <div veolia-modal-content>
    <div class="spacing-v8">
      <strong nz-typography class="title">
        Fill in your email, you will receive a link to reset your password
      </strong>
    </div>
    <form
      nz-form
      [formGroup]="forgotPasswordForm"
      class="forgot-password-form"
      (ngSubmit)="submitForgotPageForm()">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your username!">
          <nz-input-group nzPrefixIcon="user" class="light-gray">
            <input
              class="light-gray"
              type="text"
              nz-input
              formControlName="email"
              placeholder="Email address" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>

  <div veolia-modal-footer>
    <veolia-button
      variant="secondary"
      *ngIf="!loading"
      [htmlType]="'reset'"
      (click)="resetForgotPasswordForm()">
      Cancel
    </veolia-button>
    <veolia-button
      *ngIf="!loading"
      (click)="submitForgotPageForm()"
      variant="primary">
      Reset password
    </veolia-button>
    <nz-spin *ngIf="loading" nzSimple></nz-spin>
  </div>
</veolia-modal>
