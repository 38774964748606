<div>
  <nz-result
    *ngIf="logs.length === 0 && isLoaded"
    [nzIcon]="'eye-invisible'"
    [nzTitle]="'No logs'">
    <nz-skeleton *ngIf="isLoading"></nz-skeleton>
  </nz-result>
  <nz-collapse>
    <nz-collapse-panel
      *ngFor="let datedLog of logs"
      [nzHeader]="datedLog.date"
      [nzActive]="false"
      [nzDisabled]="false">
      <p style="margin: 0">
        <nz-timeline>
          <nz-timeline-item *ngFor="let log of datedLog.logs">
            <span *ngIf="log.action === 'Connection'">
              <strong>{{ formatDate(log.dateTime) }}</strong> -
              {{ log.userEmail }} ({{ log.roleUser }}) logged in
            </span>

            <span *ngIf="log.action === 'Save form'">
              <strong>{{ formatDate(log.dateTime) }}</strong> -
              {{ log.userEmail }} saved the form
            </span>

            <span *ngIf="log.action === 'Save complete form'">
              <strong>{{ formatDate(log.dateTime) }}</strong> -
              {{ log.userEmail }} completed the form
            </span>
            <span *ngIf="log.action === 'File deleted'">
              <strong>{{ formatDate(log.dateTime) }}</strong> -
              {{ log.action }} (<em
                >{{ log.fileName }} on the chapter/sub-chapter
                <strong>{{ log.chapter | translate }}</strong
                >)</em
              >
              by {{ log.userEmail }} ({{ log.roleUser }})
            </span>
            <span *ngIf="log.action === 'File uploaded'">
              <strong>{{ formatDate(log.dateTime) }}</strong> -
              {{ log.action }} (<em
                >{{ log.fileName }} on the chapter/sub-chapter
                <strong>{{ log.chapter | translate }}</strong
                >)</em
              >
              by {{ log.userEmail }} ({{ log.roleUser }})
            </span>

            <span *ngIf="log.action === 'Comment added'">
              <strong>{{ formatDate(log.dateTime) }}</strong> -
              {{ log.action }} (with content: '{{ log.content }}'
              <em
                >on the chapter/sub-chapter
                <strong>{{ log.chapter | translate }}</strong
                >)</em
              >
              by
              {{ log.userEmail }} ({{ log.roleUser }})
              <em *ngIf="log.taggedUser">
                => with tagged user {{ log.taggedUser }}</em
              >
            </span>

            <span *ngIf="log.action === 'Comment edited'">
              <strong>{{ formatDate(log.dateTime) }}</strong> -
              {{ log.action }} (with content: '{{ log.content }}'
              <em
                >on the chapter/sub-chapter
                <strong>{{ log.chapter | translate }}</strong
                >)</em
              >
              by
              {{ log.userEmail }} ({{ log.roleUser }})
              <em *ngIf="log.taggedUser">
                => with tagged user {{ log.taggedUser }}</em
              >
            </span>

            <span *ngIf="log.action === 'Comment deleted'">
              <strong>{{ formatDate(log.dateTime) }}</strong> -
              {{ log.action }} (with content: '{{ log.content }}'
              <em
                >on the chapter/sub-chapter
                <strong>{{ log.chapter | translate }}</strong
                >)</em
              >
              by
              {{ log.userEmail }} ({{ log.roleUser }})
              <em *ngIf="log.taggedUser">
                => with tagged user {{ log.taggedUser }}</em
              >
            </span>

            <span *ngIf="log.action === 'Chapter/sub-chapter completed'">
              <strong>{{ formatDate(log.dateTime) }}</strong> -
              {{ log.userEmail }} ({{ log.roleUser }}) added status '{{
                log.status
              }}' (<em
                >on the chapter/sub-chapter
                <strong>{{ log.chapter | translate }}</strong
                >)</em
              >
            </span>

            <span *ngIf="log.action === 'Help'">
              <strong>{{ formatDate(log.dateTime) }}</strong> -
              {{ log.userEmail }} ({{ log.roleUser }}) requested help
            </span>

            <span *ngIf="log.action === 'Evaluation saved'">
              <strong>{{ formatDate(log.dateTime) }}</strong> -
              {{ log.userEmail }} ({{ log.roleUser }}) saved the evaluation for
              reference <strong>{{ log.chapter | translate }}</strong>
            </span>

            <span *ngIf="log.action === 'Evaluation edited'">
              <strong>{{ formatDate(log.dateTime) }}</strong> -
              {{ log.userEmail }} ({{ log.roleUser }}) saved the evaluation for
              reference <strong>{{ log.chapter | translate }}</strong>
            </span>

            <span *ngIf="log.action === 'Final evaluation saved'">
              <strong>{{ formatDate(log.dateTime) }}</strong> -
              {{ log.userEmail }} ({{ log.roleUser }}) submited the final
              evaluation with status
              <strong>{{ log.status }} ({{ log.comment }})</strong>
            </span>

            <span *ngIf="log.action === 'Acunetix saved'">
              <strong>{{ formatDate(log.dateTime) }}</strong> -
              {{ log.userEmail }} ({{ log.roleUser }}) submited informations
              about acunetix scan
            </span>
          </nz-timeline-item>
        </nz-timeline>
      </p>
    </nz-collapse-panel>
  </nz-collapse>
</div>
