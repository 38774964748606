import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { Article32Form } from '../models/article-32-form';

@Injectable()
export class Article32FormService extends EntityService<Article32Form> {
  override get collectionName(): string {
    return 'article32Form';
  }
}
