export interface BaseStoreState<T> {
  currentPage: number;
  isLoading: boolean;
  isSearching: boolean;
  error: string | null;
  total: number;
  limit: number;
  solutionType: number;
  businessUnit: string;
  isForward: boolean;
  prevCursor: any;
  nextCursor: any;
  search: string;
  uid: string;
  email: string;
}


export const baseStoreInitialState = {
  currentPage: 1,
  isLoading: false,
  isSearching: false,
  error: null,
  total: 0,
  limit: 10,
  solutionType: 0,
  businessUnit: '',
  isForward: true,
  prevCursor: null,
  nextCursor: null,
  search: '',
  uid: '',
  email: '',
}
