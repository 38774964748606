import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { BusinessUnit } from '../models/business-unit';
import { HttpErrorResponse } from '@angular/common/http';
import { BusinessUnitStoreState } from './states/business-unit-item-store.state';
import { EmailedUser } from '../types/emailed-user';

@Injectable()
export abstract class BusinessUnitItemsStore<T> extends ComponentStore<
  BusinessUnitStoreState<T>
> {
  private currentPage$ = this.select(state => state.currentPage);
  private isLoading$ = this.select(state => state.isLoading);
  private isSearching$ = this.select(state => state.isSearching);
  private error$ = this.select(state => state.error);
  private businessUnits$ = this.select(state => state.businessUnits);
  private total$ = this.select(state => state.total);
  private limit$ = this.select(state => state.limit);
  private solutionType$ = this.select(state => state.solutionType);
  private businessUnit$ = this.select(state => state.businessUnit);
  private isForward$ = this.select(state => state.isForward);
  private search$ = this.select(state => state.search);
  private uid$ = this.select(state => state.uid);
  private email$ = this.select(state => state.email);
  private isLoadingUsers$ = this.select(state => state.isLoadingUsers);
  private users$ = this.select(state => state.users);

  vm$ = this.select(
    this.currentPage$,
    this.isLoading$,
    this.isSearching$,
    this.error$,
    this.businessUnits$,
    this.total$,
    this.limit$,
    this.solutionType$,
    this.businessUnit$,
    this.isForward$,
    this.search$,
    this.uid$,
    this.email$,
    this.isLoadingUsers$,
    this.users$,
    (
      currentPage,
      isLoading,
      isSearching,
      error,
      businessUnits,
      total,
      limit,
      solutionType,
      businessUnit,
      isForward,
      search,
      uid,
      email,
      isLoadingUsers,
      users
    ) => ({
      currentPage,
      isSearching,
      isLoading,
      error,
      businessUnits,
      total,
      limit,
      solutionType,
      businessUnit,
      isForward,
      search,
      uid,
      email,
      isLoadingUsers,
      users,
    })
  );

  setUid = this.updater((state, uid: string) => ({
    ...state,
    uid,
  }));

  setEmail = this.updater((state, email: string) => ({
    ...state,
    email,
  }));

  setPrevCursor = this.updater((state, prevCursor: any) => ({
    ...state,
    prevCursor,
  }));

  setNextCursor = this.updater((state, nextCursor: any) => ({
    ...state,
    nextCursor,
  }));

  setLimit = this.updater((state, limit: number) => ({
    ...state,
    limit,
  }));

  setBusinessUnitType = this.updater((state, solutionType: number) => ({
    ...state,
    solutionType,
  }));

  setIsLoading = this.updater(state => ({
    ...state,
    isLoading: true,
  }));

  setError = this.updater((state, error: HttpErrorResponse) => ({
    ...state,
    isLoading: false,
    error: error.message,
  }));

  setPage = this.updater((state, page: number) => ({
    ...state,
    isForward: page > state.currentPage,
    currentPage: page,
  }));

  setPageReset = this.updater((state, page: number) => ({
    ...state,
    isSearching: false,
    mappedBusinessUnits: [],
    businessUnits: [],
    prevCursor: null,
    nextCursor: null,
    currentPage: page,
  }));

  setTotal = this.updater((state, total: number) => ({
    ...state,
    total,
  }));

  setBusinessUnits = this.updater((state, businessUnits: BusinessUnit[]) => ({
    ...state,
    isLoading: false,
    businessUnits,
  }));

  setBusinessUnit = this.updater((state, businessUnit: string) => ({
    ...state,
    businessUnit,
  }));

  setSearch = this.updater((state, search: string) => ({
    ...state,
    isSearching: search.length > 0,
    mappedBusinessUnits: [],
    businessUnits: [],
    prevCursor: null,
    nextCursor: null,
    search,
  }));

  readonly setUsers = this.updater((state, users: EmailedUser[]) => ({
    ...state,
    isLoadingUsers: false,
    users,
  }));

  readonly setIsLoadingUsers = this.updater(
    (state, isLoadingUsers: boolean) => ({
      ...state,
      isLoadingUsers,
    })
  );
}
