import { AbstractControl, ValidationErrors } from '@angular/forms';

export function noHttpsAndWWWValidator(
  control: AbstractControl
): ValidationErrors | null {
  const value = control.value;
  if (
    value &&
    (value.startsWith('www.') ||
      value.startsWith('http') ||
      value.startsWith('https'))
  ) {
    return { 'no-https-and-www': true };
  }

  return null;
}

export function noHttpsAndWWWValidatorMessage(err: any, field: any) {
  return 'This field should not contain "https://" or "www." and "http://"';
}
