import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { Observable, from } from 'rxjs';
import { Timestamp, endAt, or, startAt } from '@angular/fire/firestore';

import {
  collectionData,
  collection,
  query,
  orderBy,
  startAfter,
  limit,
  endBefore,
  limitToLast,
  where,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from '@angular/fire/firestore';
import { Entity } from '../models/bases';
import { nameof } from '../utils/nameof';
import { EntityService } from './entity.service';

@Injectable()
export class UserService extends EntityService<User> {
  override get collectionName(): string {
    return 'users';
  }

  override observeAll(lastDoc?: User, pageSize = 10, isForward = true) {
    return collectionData(
      query(
        collection(this._firestore, this.collectionName),
        orderBy('lastname'),
        isForward
          ? startAfter(lastDoc?.lastname || 0)
          : endBefore(lastDoc?.lastname || 0),
        isForward ? limit(pageSize) : limitToLast(pageSize)
      ),
      {
        idField: nameof<Entity>('uid'),
      }
    ) as Observable<User[]>;
  }

  observeVeoliaUsers() {
    return collectionData(
      query(
        collection(this._firestore, this.collectionName),
        where('isVeolia', '==', true),
        orderBy('email')
      ),
      {
        idField: nameof<Entity>('uid'),
      }
    ) as Observable<User[]>;
  }

  // get user data
  getUserData(userId: string) {
    return getDoc(doc(this._firestore, this.collectionName, userId));
  }

  // check if user exists by email or uid
  async checkUserExists(emailOrUid: string) {
    return getDocs(
      query(
        collection(this._firestore, this.collectionName),
        or(where('email', '==', emailOrUid), where('uid', '==', emailOrUid))
      )
    );
  }

  // update user last login
  async updateLastLogin(uid: string) {
    return await updateDoc(doc(this._firestore, this.collectionName, uid), {
      lastLoggedIn: Timestamp.fromDate(new Date(Date.now())),
    });
  }

  // update user current browser
  async updateCurrentBrowser(uid: string) {
    return await updateDoc(doc(this._firestore, this.collectionName, uid), {
      browser: window.navigator.userAgent,
    });
  }

  async editUserBuAndRights(
    uid: string,
    bu: string,
    adminRight: boolean,
    evaluatorRight: boolean,
    solOwnerRight: boolean,
    vendorRight: boolean
  ) {
    return await updateDoc(doc(this._firestore, this.collectionName, uid), {
      businessUnit: bu,
      roles: {
        admin: adminRight,
        evaluator: evaluatorRight,
        solutionOwner: solOwnerRight,
        viewer: true,
        vendor: vendorRight,
      },
    });
  }

  async editUserMainRole(uid: string, mainRole: string) {
    return await updateDoc(doc(this._firestore, this.collectionName, uid), {
      role: mainRole,
    });
  }

  getAllUsers(
    uid?: string,
    lastDoc?: User,
    pageSize = 10,
    userType = 'Veolia',
    isForward = true
  ) {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', userType),
          orderBy('email'),
          isForward ? startAt(lastDoc || 0) : endAt(lastDoc || 0),
          isForward ? limit(pageSize) : limitToLast(pageSize)
        )
      )
    );
  }

  searchAllUsers(uid?: string, userType = 'Veolia', search = '') {
    return from(
      getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', userType),
          orderBy('email')
        )
      )
    );
  }

  async getCountOfAllUsers(uid?: string, userType = 'Veolia') {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('type', '==', userType)
        )
      )
    ).size;
  }

  //Counters
  //Admin home page
  async getCountOfAllVeoliaUsers() {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('isVeolia', '==', true)
        )
      )
    ).size;
  }

  async getCountOfAllVendorUsers() {
    return (
      await getDocs(
        query(
          collection(this._firestore, this.collectionName),
          where('isVeolia', '==', false)
        )
      )
    ).size;
  }

  getSolutionsAccessForVendorUser(email: string) {
    return from(
      getDocs(
        query(
          collection(this._firestore, 'solutions'),
          where('emailVendorAuthorized', 'array-contains', email),
          orderBy('solutionName')
        )
      )
    );
  }

  getSolutionsAccessForVeoliaUser(email: string) {
    return from(
      getDocs(
        query(
          collection(this._firestore, 'solutions'),
          where('emailVeoliaAuthorized', 'array-contains', email),
          orderBy('solutionName')
        )
      )
    );
  }

  getSolutionsAccessForBuEvaluator(businessUnit: string) {
    return from(
      getDocs(
        query(
          collection(this._firestore, 'solutions'),
          where('businessUnitOfSolution', '==', businessUnit),
          orderBy('solutionName')
        )
      )
    );
  }
}
