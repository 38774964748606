import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import {
  VeoliaAvatarComponent,
  VeoliaAvatarGroupComponent,
} from '@veolia.com/vds-angular-components/avatar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { CommonModule } from '@angular/common';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { map, Subscription, takeUntil, Subject } from 'rxjs';
import {
  DocumentPresenceService,
  UserPresence,
  FormStatus,
} from './document-presence.service';

export interface PresentUser extends UserPresence {
  color: string;
  initials: string;
}

@Component({
  selector: 'app-document-presence',
  templateUrl: './document-presence.component.html',
  styleUrls: ['./document-presence.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NzDropDownModule,
    NzToolTipModule,
    NzAvatarModule,
    VeoliaAvatarGroupComponent,
    VeoliaAvatarComponent,
  ],
})
export class DocumentPresenceComponent implements OnInit, OnDestroy {
  @Input() documentId?: string;
  @Input() userId?: string;
  @Input() idleDuration = 900; // 15 minutes
  @Input() timeoutDuration = 1800; // 30 minutes

  private _formName?: string;
  private currentForm: string | null = null;

  @Input()
  set formName(value: string | undefined) {
    if (this._formName !== value) {
      this._formName = value;
      if (this.auth.currentUser) {
        this.cleanupOldForm();
        this.currentForm = value || null;
        if (value) {
          this.initializePresence();
        }
      }
    }
  }

  get formName(): string | undefined {
    return this._formName;
  }

  presentUsers: PresentUser[] = [];
  twoFirstPresent: PresentUser[] = [];
  remainingPresent: PresentUser[] = [];

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date;
  isLoading = true;

  private destroy$ = new Subject<void>();
  private heartbeatSubscription?: Subscription;

  constructor(
    private auth: Auth,
    private presence: DocumentPresenceService,
    private idle: Idle,
    private keepalive: Keepalive
  ) {}

  ngOnInit() {
    onAuthStateChanged(this.auth, user => {
      if (user) {
        this.userId = user.uid; // Use the uid from the authenticated user
        if (!this.documentId || !this.formName || !this.userId) {
          console.error(
            'DocumentComponent: documentId, formName, and userId are required inputs'
          );
          return;
        }

        // Check if the form has changed
        if (this.currentForm !== this.formName) {
          this.cleanupOldForm();
          this.currentForm = this.formName;
        }

        this.initializePresence();
      }
    });
  }

  ngOnDestroy() {
    this.cleanupOldForm();
    this.destroy$.next();
    this.destroy$.complete();
  }

  private cleanupOldForm() {
    if (this.currentForm && this.documentId && this.userId) {
      this.presence
        .removePresence(this.documentId, this.currentForm, this.userId)
        .catch(error =>
          console.error('Error removing presence from old form:', error)
        );

      if (this.heartbeatSubscription) {
        this.heartbeatSubscription.unsubscribe();
      }

      this.idle.stop();
    }
  }

  private initializePresence() {
    onAuthStateChanged(this.auth, user => {
      if (!this.documentId || !this.formName || !user?.uid) {
        console.error(
          'Cannot initialize presence: missing required information'
        );
        return;
      }

      this.presence.initializeDocumentPresence(this.formName, this.documentId);

      this.presence
        .getFormStatus(this.documentId, this.formName)
        .pipe(
          takeUntil(this.destroy$),
          map((formStatus: FormStatus) => {
            if (formStatus && Array.isArray(formStatus.users)) {
              return formStatus.users.filter(el => el.status !== 'away');
            }
            return [];
          }),
          map(users =>
            users.map(el => ({
              ...el,
              initials: this.getInitials(el.displayName || ''),
              color: this.getRandomColor(),
            }))
          )
        )
        .subscribe({
          next: users => {
            this.presentUsers = users.filter(
              el => el.userId !== this.auth.currentUser?.uid
            );
            this.updatePresentUserLists();
            this.isLoading = false;
          },
          error: error => {
            console.error('Error fetching users:', error);
            this.isLoading = false;
          },
        });

      this.updateUserPresence('online');

      this.heartbeatSubscription = this.presence.startHeartbeat(
        this.documentId,
        this.formName,
        user.uid,
        this.auth.currentUser?.displayName ?? 'Anonymous'
      );

      this.configureIdle();
    });
  }

  private configureIdle() {
    this.idle.setIdle(this.idleDuration);
    this.idle.setTimeout(this.timeoutDuration);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.updateUserPresence('online');
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.updateUserPresence('away');
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      this.updateUserPresence('away');
    });

    this.idle.onTimeoutWarning.subscribe(countdown => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    });

    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.idle.watch();
  }

  private updateUserPresence(status: 'online' | 'away') {
    if (this.documentId && this.formName && this.userId) {
      this.presence
        .updatePresence(
          this.documentId,
          this.formName,
          this.userId,
          this.auth.currentUser?.displayName ?? 'Anonymous',
          status
        )
        .catch(error => console.error('Error updating presence:', error));
    } else {
      console.error('Cannot update presence: missing required information');
    }
  }

  private updatePresentUserLists() {
    this.twoFirstPresent = this.presentUsers.slice(0, 2);
    this.remainingPresent = this.presentUsers.slice(2);
  }

  private getInitials(name: string): string {
    return name
      .split(' ')
      .map(n => n[0])
      .join('')
      .toUpperCase();
  }

  private getRandomColor(): string {
    const colors = [
      '#ff0000',
      '#00ff00',
      '#0000ff',
      '#ffff00',
      '#00ffff',
      '#ff00ff',
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  }
}
