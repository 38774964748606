import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { Database, ref as refDatabase, remove } from '@angular/fire/database';
import {
  Storage,
  deleteObject,
  ref as refStorage,
} from '@angular/fire/storage';
import { FileHandle } from 'src/app/core/directives/drag.directive';
import { FileItem } from 'src/app/core/models/file-item';
import { FileService } from 'src/app/core/services/file.service';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { AuthService } from 'src/app/core/services/auth.service';
import { LogService } from 'src/app/core/services/log.service';
import { Article28FormStore } from 'src/app/features/demands/components/article-28-form/article-28-form.store';
import { Article32FormStore } from 'src/app/features/demands/components/article-32-form/article-32-form.store';
import { DesignFormStore } from 'src/app/features/demands/components/design-form/design-form.store';
import { LegalFormStore } from 'src/app/features/demands/components/legal-form/legal-form.store';
import { Auth } from '@angular/fire/auth';

@Component({
  selector: 'app-form-actions-files',
  templateUrl: './form-actions-files.component.html',
  styleUrls: ['./form-actions-files.component.scss'],
})
export class FormActionsFilesComponent implements OnInit {
  @Input() modalData: any;
  @Output() filesRefreshed: EventEmitter<void> = new EventEmitter();
  files: FileItem[] = [];
  droppedFiles: FileHandle[] = [];
  selectedFiles?: FileList;
  progressInfos: any[] = [];
  formName?: string;
  solutionId?: string;
  fieldId?: string;

  isHovering = false;

  uploadfiles: File[] = [];

  private authService: AuthService = inject(AuthService);
  private database: Database = inject(Database);
  private logService: LogService = inject(LogService);
  private storage: Storage = inject(Storage);

  constructor(
    private auth: Auth,
    private article28FormStore: Article28FormStore,
    private article32FormStore: Article32FormStore,
    private designFormStore: DesignFormStore,
    private legalFormStore: LegalFormStore,
    private fileService: FileService,
    private messageService: VeoliaMessageService,
    private cdr: ChangeDetectorRef
  ) {}

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(filesList: FileList) {
    for (let i = 0; i < filesList.length; i++) {
      this.uploadfiles.push(filesList[i]);
    }
  }

  ngOnInit(): void {
    this.solutionId = this.modalData.solutionId;
    this.formName = this.modalData.formName;
    this.fieldId = this.modalData.fieldId;

    this.fetchFiles();
  }

  fetchFiles() {
    if (this.formName && this.solutionId && this.fieldId) {
      this.fileService
        .getChapterFiles(this.formName, this.fieldId, this.solutionId)
        .then(data => {
          this.files = [...(data as FileItem[])];
          this.cdr.detectChanges();
        })
        .catch(error => {
          this.files = [];
          console.log(error);
        });
    }
  }

  selectFiles(event: any): void {
    this.progressInfos = [];
    this.selectedFiles = event.target.files;
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.uploadfiles.push(this.selectedFiles[i]);
      }
    }
  }

  cleanAndReload(index: number) {
    this.cleanFile(index);
    this.fetchFiles();
    this.filesRefreshed.emit();
    this.cdr.detectChanges();
  }

  cleanFile(index: number) {
    this.uploadfiles.splice(index, 1);
  }

  deleteFile(file: FileItem, index: number) {
    const basePath = `/files/${this.formName}/${this.solutionId}/${this.fieldId}`;
    remove(refDatabase(this.database, `${basePath}/${file?.id}`))
      .then(() => {
        const storageRef = refStorage(
          this.storage,
          `${basePath}/${file?.name}`
        );
        deleteObject(storageRef)
          .then(() => {
            this.filesRefreshed.emit();
            this.cleanAndReload(index);
            this.logService.setLogForAction(
              'File',
              this.solutionId!,
              this.formName!,
              {
                action: 'File deleted',
                roleUser: this.authService.userRole,
                fileName: file.name,
                chapter: this.fieldId,
              }
            );
            this.messageService.create(
              {
                icon: 'check',
                title: 'Success',
                content: 'File deleted successfully',
                type: 'info',
              },
              { duration: 5000 }
            );
          })
          .catch(error => {
            this.messageService.create(
              {
                type: 'error',
                title: 'Error',
                content: error.toString() || 'Oops! An error occured',
              },
              { duration: 5000 }
            );
          });
        this.fetchFiles();
      })
      .catch(error => {
        this.messageService.create(
          {
            type: 'error',
            title: 'Error',
            content: error.toString() || 'Oops! An error occured',
          },
          { duration: 5000 }
        );
      });
  }

  get solution() {
    switch (this.formName) {
      case 'Article 28 Form':
        return this.article28FormStore.getSolution();
      case 'Article 32 Form':
        return this.article32FormStore.getSolution();
      case 'Design Form':
        return this.designFormStore.getSolution();
      case 'Legal Form':
        return this.legalFormStore.getSolution();
      default:
        return this.designFormStore.getSolution();
    }
  }

  get isVendorReadOnly() {
    if (this.formName === 'Article 28 Form') {
      return this.solution?.emailVendorReadonlyArt28?.includes(
        this.auth.currentUser?.email!
      );
    } else if (this.formName === 'Article 32 Form') {
      return this.solution?.emailVendorReadonlyArt32?.includes(
        this.auth.currentUser?.email!
      );
    } else if (this.formName === 'Design Form') {
      return this.solution?.emailVendorReadonlyDesign?.includes(
        this.auth.currentUser?.email!
      );
    } else if (this.formName === 'Legal Form') {
      return this.solution?.emailVendorReadonlyLegal?.includes(
        this.auth.currentUser?.email!
      );
    } else {
      return false;
    }
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }
}
