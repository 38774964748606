import { Injectable } from "@angular/core";
import { Country } from "../../../../core/utils/countries";
import { ComponentStore, OnStoreInit } from "@ngrx/component-store";
import { CountryService } from "../../../../core/services/country.service";
import { BusinessUnitService } from "../../../../core/services/business-unit.service";
import { BusinessUnit } from "../../../../core/models/business-unit";
import { AuthService } from "../../../../core/services/auth.service";

export interface FirstConnectionPageState {
  countries: {
    label: string;
    value: string;
  }[];
  businessUnits: {
    label: string;
    value: string;
  }[];
  currentUser: any;
}

@Injectable({
  providedIn: "root"
})
export class FirstConnectionPageStore extends ComponentStore<FirstConnectionPageState> implements OnStoreInit {
  private countries$ = this.select((state) => state.countries);
  private businessUnits$ = this.select((state) => state.businessUnits);
  private currentUser$ = this.select((state) => state.currentUser);

  vm$ = this.select(this.businessUnits$, this.countries$, this.currentUser$, ( businessUnits, countries, currentUser) => ({
    businessUnits,
    countries,
    currentUser
  }));

  constructor(
    private countryService: CountryService,
    private authService: AuthService,
    private businessUnitService: BusinessUnitService
    ) {
    super({
      businessUnits: [],
      countries: [],
      currentUser: null
    });
  }

  ngrxOnStoreInit = () => {
    this.authService.userData.subscribe((user) => {
      if (user) {
        this.patchState({
          currentUser: user
        });
      }
    });
  };

  setCountries = this.updater((state, countries: {
    label: string;
    value: string;
  }[]) => ({
    ...state,
    countries
  }));

  setBusinessUnits = this.updater((state, businessUnits: {
    label: string;
    value: string;
  }[]) => ({
    ...state,
    businessUnits
  }));

  setCurrentUser = this.updater((state, currentUser) => ({
    ...state,
    currentUser
  }));

  loadCountries() {
    const countryList: Country[] = this.countryService.getCountries();
    const mappedCountries = countryList.map((country) => ({ label: country.name, value: country.name }))
    this.setCountries(mappedCountries);
  }

  loadBusinessUnits() {
    this.businessUnitService.getBusinessUnitsList().subscribe(businessUnits => {
      const mappedBusinessUnits = businessUnits.docs.map(doc => ({
        label: (doc.data() as unknown as BusinessUnit).nomBu,
        value: (doc.data() as unknown as BusinessUnit).nomBu,
      }));
      this.setBusinessUnits(mappedBusinessUnits);
    });
  }
}
