<div *ngIf="vm$ | async as vm">
  <nz-spin [nzSpinning]="vm.loading" [nzDelay]="500">
    <div nz-row [nzGutter]="16">
      <div
        nz-col
        class="gutter-row"
        [nzSpan]="5"
        [nzLg]="6"
        [nzMd]="6"
        [nzSm]="24"
        [nzXs]="24">
        <div class="rounded-white spacing-b16">
          <h3>Table of content</h3>
          <div *ngFor="let step of field.fieldGroup; let i = index">
            <app-form-block-button
              *ngIf="step.props?.hidden !== true"
              [id]="(i + 1).toString()!"
              [title]="step.props?.label!"
              (click)="setSelected((i + 1).toString()!)"
              [selected]="vm.selectedId === (i + 1).toString()!"
              [unlocked]="vm.unlockeds.includes((i + 1).toString()!)">
            </app-form-block-button>
          </div>
        </div>
      </div>
      <div
        nz-col
        class="gutter-row"
        [nzLg]="18"
        [nzMd]="18"
        [nzSm]="24"
        [nzXs]="24">
        <div *ngFor="let step of field.fieldGroup; let i = index">
          <app-form-block
            *ngIf="step.props?.hidden !== true"
            [id]="(i + 1).toString()!"
            [selectedId]="vm.selectedId"
            (selected)="setSelected($event)"
            [title]="step.props?.label"
            (submitted)="onSubmit($event)"
            [unlocked]="vm.unlockeds.includes((i + 1).toString()!)"
            [actionTitle]="getButtonTitle(i.toString())"
            [loading]="vm.loading">
            <formly-field [field]="step"></formly-field>
          </app-form-block>
        </div>
      </div>
    </div>
  </nz-spin>
</div>
