import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  WritableSignal,
  signal,
} from '@angular/core';
import { Evaluation } from 'src/app/core/models/evalutation';
import { getTagColor } from 'src/app/core/utils/colors';

@Component({
  selector: 'app-form-block-button',
  templateUrl: './form-block-button.component.html',
  styleUrls: ['./form-block-button.component.scss'],
})
export class FormBlockButtonComponent implements OnChanges {
  @Input() id?: string;
  @Input() showId = true;
  @Input() title?: string;
  @Input() selected?: boolean;
  @Input() unlocked?: boolean;
  @Input() evaluation?: Evaluation;
  @Input() evaluationField?: string;

  status: WritableSignal<string> = signal('');
  statusColor = signal<
    | 'default'
    | 'error'
    | 'warning'
    | 'success'
    | 'information'
    | 'purple'
    | 'orange'
    | 'lightblue'
    | undefined
  >(undefined);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['evaluation'] && !changes['evaluation'].isFirstChange()) {
      this.status.set(
        changes['evaluation'].currentValue[this.evaluationField!]
      );
      this.statusColor.set(
        this.getColor(changes['evaluation'].currentValue[this.evaluationField!])
      );
    }
  }

  getColor(status: string) {
    return getTagColor(status);
  }

  get buttonColor() {
    if (!this.evaluation) return undefined;
    if (!this.evaluation![this.evaluationField!]) return undefined;

    return this.getColor(this.evaluation![this.evaluationField!]);
  }
}
