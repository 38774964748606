import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { User as UserModel } from '../../../core/models/user';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  FormlyFormOptions,
  FormlyFieldConfig,
  FormlyModule,
} from '@ngx-formly/core';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import {
  VeoliaModalComponent,
  VeoliaModalModule,
} from '@veolia.com/vds-angular-components/modal';
import { Observable, catchError, filter, map, of, switchMap } from 'rxjs';
import { ContactApi } from 'src/app/core/apis/contact.api';
import { Solution } from 'src/app/core/models/solution';
import { AuthService } from 'src/app/core/services/auth.service';
import { SolutionService } from 'src/app/core/services/solution.service';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import { NzFormModule } from 'ng-zorro-antd/form';

@Component({
  standalone: true,
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  imports: [
    FormlyModule,
    NzFormModule,
    ReactiveFormsModule,
    VeoliaButtonComponent,
    VeoliaModalModule,
  ],
})
export class ContactFormComponent {
  @ViewChild('modal') modal!: VeoliaModalComponent;

  solutions: Array<Record<string, string>> = [];
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      awesomeIsForced: false,
      solutions: [],
    },
  };

  fields: FormlyFieldConfig[] = [
    {
      key: 'displayName',
      type: 'nz-input',
      props: {
        label: 'Your full name',
        required: true,
        disabled: true,
      },
    },
    {
      key: 'email',
      type: 'nz-input',
      props: {
        label: 'Your email',
        required: true,
        disabled: true,
      },
    },
    {
      key: 'request',
      type: 'nz-select',
      props: {
        label: 'Concerned request',
        options: this.getSolutions(),
        required: true,
        expressions: {
          'templateOptions.options': 'options.formState.solutions',
        },
      },
      wrappers: ['form-field'],
    },
    {
      key: 'subject',
      type: 'nz-input',
      props: {
        label: 'Object',
        required: true,
      },
      wrappers: ['form-field'],
    },
    {
      key: 'content',
      type: 'nz-textarea',
      props: {
        label: 'Content',
        required: true,
      },
      wrappers: ['form-field'],
    },
  ];

  constructor(
    private auth: Auth,
    private authService: AuthService,
    private contactApi: ContactApi,
    private messageService: VeoliaMessageService,
    private solutionService: SolutionService
  ) {}

  open(solution?: Solution) {
    this.model = {
      ...this.model,
      displayName: this.auth.currentUser?.displayName,
      email: this.auth.currentUser?.email,
    };

    if (solution) {
      this.model = {
        ...this.model,
        request: solution?.solutionName,
      };

      this.fields = [
        {
          key: 'displayName',
          type: 'nz-input',
          props: {
            label: 'Your full name',
            required: true,
            disabled: true,
          },
        },
        {
          key: 'email',
          type: 'nz-input',
          props: {
            label: 'Your email',
            required: true,
            disabled: true,
          },
        },
        {
          key: 'request',
          type: 'nz-select',
          props: {
            label: 'Concerned request',
            options: [
              {
                label: solution?.solutionName,
                value: solution?.solutionName,
              },
            ],
            required: true,
            expressions: {
              'templateOptions.options': 'options.formState.solutions',
            },
          },
          wrappers: ['form-field'],
        },
        {
          key: 'subject',
          type: 'nz-input',
          props: {
            label: 'Object',
            required: true,
          },
          wrappers: ['form-field'],
        },
        {
          key: 'content',
          type: 'nz-textarea',
          props: {
            label: 'Content',
            required: true,
          },
          wrappers: ['form-field'],
        },
      ];
    }

    this.modal.open();
  }

  submit() {
    if (this.form.valid) {
      this.contactApi
        .send({
          content: this.model.content,
          displayName: this.model.displayName,
          email: this.model.email,
          request: this.model.request,
          subject: this.model.subject,
          isVendor: this.authService.roles?.vendor ?? false,
        })
        .subscribe({
          next: () => {
            this.resetForm();
            this.messageService.create(
              {
                title: 'DLA support notified',
                icon: 'check',
                content:
                  'We will reply to you directly by email once your request has been processed',
              },
              { duration: 12000 }
            );
          },
          error: (error: HttpErrorResponse) => {
            this.resetForm();
            this.messageService.create(
              {
                title: 'Error',
                icon: 'error',
                content: error.message,
              },
              { duration: 3000 }
            );
          },
        });
    }
  }

  getSolutions(): Observable<
    { label: string; group: { label: string; value: string }[] }[]
  > {
    return this.authService.userData.pipe(
      filter((user): user is UserModel => user !== null), // Filter out null values
      switchMap(user => {
        if (!user.email) {
          return of([]); // Return empty array if no email
        }

        return this.solutionService.getAllVendorAuthorized(user.email).pipe(
          map(data => {
            if (data.empty) {
              return [
                {
                  label: 'About Application',
                  group: [
                    { label: 'Bug report', value: 'Bug report' },
                    { label: 'Other', value: 'Other' },
                  ],
                },
              ];
            }

            return [
              {
                label: 'Solutions',
                group: [
                  ...data.docs.map(doc => {
                    const sol = doc.data() as Solution;
                    return {
                      label: sol.solutionName,
                      value: sol.solutionName,
                    };
                  }),
                ],
              },
              ...[
                {
                  label: 'About Application',
                  group: [
                    { label: 'Bug report', value: 'Bug report' },
                    { label: 'Other', value: 'Other' },
                  ],
                },
              ],
            ];
          }),
          catchError(() => {
            return of([
              {
                label: 'About Application',
                group: [
                  { label: 'Bug report', value: 'Bug report' },
                  { label: 'Other', value: 'Other' },
                ],
              },
            ]); // Return an empty array in case of error
          })
        );
      })
    );
  }

  resetForm() {
    if (this.options.resetModel) {
      this.options.resetModel();
    }
    this.modal.close();
  }
}
