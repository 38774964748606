import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/core/models/user';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss'],
})
export class DocumentationComponent implements OnInit {
  isVisible = false;
  protected user?: User;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.getUserData();
  }

  getUserData() { //ou depuis le authService (userRole) via le guetter get userRole() dans le html via userRole.admin === true etc
    this.authService.userData.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  // get userRole() {
  //   return this.authService.userRole;
  // }
}
