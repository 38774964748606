import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Solution } from '../models/solution';
import { HttpErrorResponse } from '@angular/common/http';
import { SolutionItemStoreState } from './states/solution-item-store.state';
import { NzTableSortOrder } from 'ng-zorro-antd/table';
import { formatDate } from '../utils/date';

@Injectable()
export abstract class SolutionItemsStore<T> extends ComponentStore<
  SolutionItemStoreState<T>
> {
  private currentPage$ = this.select(state => state.currentPage);
  private isLoading$ = this.select(state => state.isLoading);
  private isSearching$ = this.select(state => state.isSearching);
  private error$ = this.select(state => state.error);
  private solutions$ = this.select(state => state.solutions);
  private total$ = this.select(state => state.total);
  private limit$ = this.select(state => state.limit);
  private solutionType$ = this.select(state => state.solutionType);
  private businessUnit$ = this.select(state => state.businessUnit);
  private isForward$ = this.select(state => state.isForward);
  private search$ = this.select(state => state.search);
  private uid$ = this.select(state => state.uid);
  private email$ = this.select(state => state.email);

  vm$ = this.select(
    this.currentPage$,
    this.isLoading$,
    this.isSearching$,
    this.error$,
    this.solutions$,
    this.total$,
    this.limit$,
    this.solutionType$,
    this.businessUnit$,
    this.isForward$,
    this.search$,
    this.uid$,
    this.email$,
    (
      currentPage,
      isLoading,
      isSearching,
      error,
      solutions,
      total,
      limit,
      solutionType,
      businessUnit,
      isForward,
      search,
      uid,
      email
    ) => ({
      currentPage,
      isSearching,
      isLoading,
      error,
      solutions,
      total,
      limit,
      solutionType,
      businessUnit,
      isForward,
      search,
      uid,
      email,
    })
  );

  setUid = this.updater((state, uid: string) => ({
    ...state,
    uid,
  }));

  setEmail = this.updater((state, email: string) => ({
    ...state,
    email,
  }));

  setPrevCursor = this.updater((state, prevCursor: unknown) => ({
    ...state,
    prevCursor,
  }));

  setNextCursor = this.updater((state, nextCursor: unknown) => ({
    ...state,
    nextCursor,
  }));

  setLimit = this.updater((state, limit: number) => ({
    ...state,
    limit,
  }));

  setSolutionType = this.updater((state, solutionType: number) => ({
    ...state,
    solutionType,
  }));

  setIsLoading = this.updater(state => ({
    ...state,
    isLoading: true,
  }));

  setError = this.updater((state, error: HttpErrorResponse) => ({
    ...state,
    isLoading: false,
    error: error.message,
  }));

  setPage = this.updater((state, page: number) => ({
    ...state,
    isForward: page > state.currentPage,
    currentPage: page,
  }));

  setPageReset = this.updater((state, page: number) => ({
    ...state,
    isSearching: false,
    mappedSolutions: [],
    solutions: [],
    prevCursor: null,
    nextCursor: null,
    currentPage: page,
  }));

  setTotal = this.updater((state, total: number) => ({
    ...state,
    total,
  }));

  setSolutions = this.updater((state, solutions: Solution[]) => ({
    ...state,
    isLoading: false,
    solutions,
  }));

  setBusinessUnit = this.updater((state, businessUnit: string) => ({
    ...state,
    businessUnit,
  }));

  setSearch = this.updater((state, search: string) => ({
    ...state,
    isSearching: search.length > 0,
    mappedSolutions: [],
    solutions: [],
    prevCursor: null,
    nextCursor: null,
    search,
  }));

  setSort = this.updater(
    (
      state,
      filter: {
        key: string;
        value: NzTableSortOrder;
      }
    ) => ({
      ...state,
      filter,
      isSearching: false,
      mappedSolutions: [],
      solutions: [],
      prevCursor: null,
      nextCursor: null,
      currentPage: 1,
    })
  );

  getSort() {
    return this.get().filter;
  }

  getSelectedSolution = (idSolution: string): Solution | undefined => {
    return this.get().solutions.find(solution => solution.uid === idSolution);
  };

  filterSearch = (solutions: Solution[]): Solution[] => {
    const searchTerm = this.get().search.toLowerCase();
    return solutions.filter(
      solution =>
        solution.uid.toLowerCase().includes(searchTerm) ||
        solution.solutionName.toLowerCase().includes(searchTerm) ||
        solution.rfpName.toLowerCase().includes(searchTerm) ||
        solution.status.toLowerCase().includes(searchTerm) ||
        solution.designStatus.toLowerCase().includes(searchTerm) ||
        solution.legalStatus.toLowerCase().includes(searchTerm) ||
        solution.article28Status.toLowerCase().includes(searchTerm) ||
        solution.article32Status.toLowerCase().includes(searchTerm) ||
        solution.businessUnitOfSolution.toLowerCase().includes(searchTerm) ||
        solution.editorBrand.toLowerCase().includes(searchTerm) ||
        solution.solutionOwner.toLowerCase().includes(searchTerm) ||
        formatDate(solution.requestDate).includes(searchTerm) ||
        formatDate(solution.lastUpdate).includes(searchTerm)
    );
  };
}
