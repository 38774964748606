import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentStore, OnStoreInit } from '@ngrx/component-store';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { tap, withLatestFrom, map, switchMap, from, of } from 'rxjs';
import SolutionApi from 'src/app/core/apis/solution.api';
import { Solution } from 'src/app/core/models/solution';
import { AuthService } from 'src/app/core/services/auth.service';
import { BusinessUnitService } from 'src/app/core/services/business-unit.service';
import { SolutionService } from 'src/app/core/services/solution.service';
import {
  DemandFormStoreState,
  demandFormStoreInitialState,
} from 'src/app/core/stores/states/demand-form-store.state';

@Injectable()
export class DemandFormStore
  extends ComponentStore<DemandFormStoreState<Solution>>
  implements OnStoreInit
{
  readonly isLoading$ = this.select(state => state.loading);
  readonly selectedId$ = this.select(state => state.selectedId);
  readonly unlockeds$ = this.select(state => state.unlockeds);

  vm$ = this.select(
    this.isLoading$,
    this.selectedId$,
    this.unlockeds$,
    (loading, selectedId, unlockeds) => ({
      loading,
      selectedId,
      unlockeds,
    })
  );

  constructor(
    private authService: AuthService,
    private businessUnitService: BusinessUnitService,
    private veoliaMessageService: VeoliaMessageService,
    private router: Router,
    private solutionApi: SolutionApi,
    private solutionService: SolutionService
  ) {
    super(demandFormStoreInitialState);
  }

  ngrxOnStoreInit = () => {
    this.authService.userData.subscribe(user => {
      if (user) {
        this.patchState({
          currentUser: user,
        });

        this.getEvaluatorList();
      }
    });
  };

  setEvaluatorList = this.updater((state, evaluatorList: string[]) => {
    return {
      ...state,
      evaluatorList,
    };
  });

  setIsLoading = this.updater(state => {
    return {
      ...state,
      loading: true,
    };
  });

  setIsNotLoading = this.updater(state => {
    return {
      ...state,
      loading: false,
    };
  });

  setSelectedId = this.updater((state, selectedId: string) => {
    return {
      ...state,
      selectedId,
    };
  });

  setUnlockeds = this.updater((state, unlockeds: string[]) => {
    return {
      ...state,
      unlockeds: Array.from(new Set([...state.unlockeds, ...unlockeds])),
    };
  });

  setFormData = this.updater((state, formData: any) => {
    return {
      ...state,
      formData,
    };
  });

  resetFormData = this.updater(state => {
    return {
      ...state,
      formData: [],
    };
  });

  getEvaluatorList = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([_, state]) => state),
      switchMap(({ currentUser }) => {
        return this.businessUnitService
          .getEvaluatorList(currentUser?.businessUnit!)
          .then(querySnapshot => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach(doc => {
                const data = doc.data();
                if (data['nomBu'] === currentUser?.businessUnit) {
                  this.setEvaluatorList(data['evaluators']);
                }
              });
            }

            this.setIsNotLoading();
          })
          .catch(error => {
            this.setIsNotLoading();
          });
      })
    );
  });

  createSaaSSolution = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([_, state]) => state),
      switchMap(({ formData, currentUser }) => {
        const newFormData = formData?.map((data: any) => {
          return {
            businessUnitOfSolution: currentUser?.businessUnit,
            uidInitiatorVeolia: currentUser?.uid,
            fullNameInitiatorVeolia: currentUser?.displayName,
            emailInitiatorVeolia: currentUser?.email,
            emailVeoliaAuthorized: [currentUser?.email],
            solutionOwner: currentUser?.email,
            ...data,
          };
        });
        // edit each item in formData to add currentUser info
        return from(newFormData!);
      }),
      map(data => {
        this.solutionService
          .createSolution(data)
          .then(data => {
            //envoie du mail au SO et evaluators BU de l'accusé de reception que sa demande est en pending  request_acknowledgment_SaaS + request_acknowledgment_for_evaluators_SaaS
            //envoi à la DLA ou BU DLA qu'une demande a été créée request_created_SaaS
            this.solutionApi.notifySolutionCreated(data.id).subscribe();
            this.veoliaMessageService.create(
              {
                title: 'Created !',
                icon: 'check',
                content: 'Solution created successfully',
              },
              {
                duration: 10000,
              }
            );
            this.router.navigate(['/']);
          })
          .catch(error => {
            console.log(error);
            this.veoliaMessageService.create(
              {
                title: 'Error',
                icon: 'error',
                content: 'Error creating solution',
              },
              {
                duration: 10000,
              }
            );
          });
      }),
      switchMap(() => {
        this.resetFormData();
        return of(this.setIsNotLoading());
      })
    );
  });
}
