import { Injectable } from '@angular/core';
import { tapResponse } from '@ngrx/component-store';
import { tap, withLatestFrom, map, switchMap, Observable } from 'rxjs';

import { UserService } from '../../../../../core/services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from '../../../../../core/models/user';
import { UserItemsStore } from 'src/app/core/stores/user-items.store';
import {
  UserItemStoreState,
  userItemStoreInitialState,
} from 'src/app/core/stores/states/user-item-store.state';

const userTypes: string[] = ['Veolia', 'Vendor'];

@Injectable()
export class AllUsersStore extends UserItemsStore<UserItemStoreState<User>> {
  constructor(private userService: UserService) {
    super(userItemStoreInitialState);
  }

  getUsers = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(
        ({ uid, limit, userType, isForward, nextCursor, prevCursor }) => {
          const cursor = isForward ? nextCursor : prevCursor;

          return this.userService
            .getAllUsers(
              uid,
              cursor,
              limit,
              userTypes[userType],
              cursor ? isForward : true
            )
            .pipe(
              tapResponse(
                data => {
                  this.setPrevCursor(data.docs[0]);
                  this.setNextCursor(data.docs[data.docs.length - 1]);

                  const userDocs = data.docs.map(user => {
                    return {
                      ...user.data(),
                      uid: user.id,
                    } as User;
                  });
                  this.setUsers(userDocs);
                },
                (err: HttpErrorResponse) => {
                  this.setError(err);
                }
              )
            );
        }
      )
    );
  });

  refreshUsers = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(
        ({ uid, limit, userType, isForward, nextCursor, prevCursor }) => {
          return this.userService
            .getAllUsers(uid, prevCursor, limit, userTypes[userType], true)
            .pipe(
              tapResponse(
                data => {
                  this.setPrevCursor(data.docs[0]);
                  this.setNextCursor(data.docs[data.docs.length - 1]);

                  const userDocs = data.docs.map(user => {
                    return {
                      ...user.data(),
                      uid: user.id,
                    } as User;
                  });
                  this.setUsers(userDocs);
                },
                (err: HttpErrorResponse) => {
                  this.setError(err);
                }
              )
            );
        }
      )
    );
  });

  searchUsers = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoading();
      }),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ uid, userType, search }) => {
        return this.userService
          .searchAllUsers(uid, userTypes[userType], search)
          .pipe(
            tapResponse(
              data => {
                const userDocs = data.docs.map(user => {
                  return {
                    ...user.data(),
                    uid: user.id,
                  } as User;
                });

                this.setUsers(this.filterSearch(userDocs));
              },
              (err: HttpErrorResponse) => {
                this.setError(err);
              }
            )
          );
      })
    );
  });

  getCount = this.effect(trigger$ => {
    return trigger$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ uid, userType }) => {
        return this.userService
          .getCountOfAllUsers(uid, userTypes[userType])
          .then(total => {
            this.setTotal(total);
          })
          .catch((err: HttpErrorResponse) => {
            this.setError(err);
          });
      })
    );
  });

  changeLimit = this.effect((limit$: Observable<number>) => {
    return limit$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([limit, state]) => ({ limit, state })),
      tap(() => this.setPageReset(1)),
      tap(({ limit }) => {
        this.setLimit(limit);
      }),
      tap(() => this.getUsers())
    );
  });

  changeUserType = this.effect((userType$: Observable<number>) => {
    return userType$.pipe(
      tap(() => {
        this.setPageReset(1);
      }),
      withLatestFrom(this.select(state => state)),
      map(([userType, state]) => ({ userType, state })),
      tap(({ userType }) => {
        this.setUserType(userType);
      }),
      tap(() => this.getCount()),
      tap(() => this.getUsers())
    );
  });

  changePage = this.effect((page$: Observable<number>) => {
    return page$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([page, state]) => ({ page, state })),
      tap(({ page }) => {
        this.setPage(page);
      }),
      tap(() => this.getUsers())
    );
  });

  changeSearch = this.effect((search$: Observable<string>) => {
    return search$.pipe(
      withLatestFrom(this.select(state => state)),
      map(([search, state]) => ({ search, state })),
      tap(({ search }) => {
        this.setSearch(search);
      }),
      tap(({ search }) => {
        if (search.length > 0) {
          return this.searchUsers();
        } else {
          this.setPageReset(1);
          return this.getUsers();
        }
      })
    );
  });

  fetchData = () => {
    this.getCount();
    this.getUsers();
  };
}
