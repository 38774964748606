import { Component } from '@angular/core';
import { getTagColor } from 'src/app/core/utils/colors';
import { AuthService } from '../../../../../core/services/auth.service';
import { User } from 'src/app/core/models/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-form-caption',
  templateUrl: './caption.component.html',
  styleUrls: ['./caption.component.scss'],
})
export class CaptionComponent {
  protected user?: User;
  isVisible = false;
  statuses = [
    {
      title: 'The solution has been created but no form has yet been started',
      label: 'Empty',
    },
    {
      title: 'The form has not yet been started by the vendor',
      label: 'New',
    },
    {
      title: 'The form is being completed by the vendor',
      label: 'Vendor is responding',
    },
    {
      title: 'The form is being assessed by evaluator/DLA team',
      label: 'DLA is reviewing',
    },
    {
      title: 'The solution is being completed and/or being evaluated',
      label: 'Ongoing',
    },
    {
      title:
        'The form was fully completed by the vendor and a notification has been sent to the evaluator/DLA team and SO',
      label: 'Completed',
    },
    {
      title: 'The form/solution has been positively assessed',
      label: 'Recommended',
    },
    {
      title: 'The form/solution has been positively assessed but with reservations',
      label: 'Reservations',
    },
    {
      title: 'The form/solution has been negatively assessed',
      label: 'Not recommended',
    },
    {
      title: 'The solution is pending approval',
      label: 'Pending',
    },
    {
      title: 'The solution has been canceled',
      label: 'Canceled',
    },
  ];

  statusesForms = [
    {
      title: 'The form is being fixed by the vendor during assessment',
      label: 'Vendor is responding', //bleu
    },
    {
      title: 'The form is being assessed by evaluator/DLA team',
      label: 'DLA is reviewing', //bleu
    },
    {
      title: 'The form has not yet been assessed',
      label: 'Form Assessement', //gris
    },
    {
      title: 'The chapter has not yet been assessed',
      label: 'DLA Assessement', //gris
    },
    {
      title: 'The chapter has been assessed as not applicable',
      label: 'Not applicable', //gris
    },
    {
      title: 'The chapter has been assessed positively',
      label: 'Recommended', //vert
    },
    {
      title: 'The chapter has been assessed negatively',
      label: 'Not recommended', //rouge
    },
    {
      title: 'The chapter has been assessed positively with reservations',
      label: 'Reservations', //orange
    },
    {
      title: 'The chapter has been assessed as on hold',
      label: 'On hold', //jaune
    },
  ];

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  isHomeRoute() {
    return this.router.url === '/';
  }

  isEvaluatorRoute() {
    return this.router.url === '/evaluator';
  }

  isAdminSolutionsRoute() {
    return this.router.url === '/admin/solutions';
  }

  isAdminBuRoute() {
    return this.router.url === '/admin/business-units';
  }

  isAdminUsersRoute() {
    return this.router.url === '/admin/users';
  }

  isFormsRoute() {
    const re = new RegExp(
      '^(/designForm/)\\w+|(/legalForm/)\\w+|(/article32Form/)\\w+|(/article28Form/)\\w+'
    );
    if (this.router.url.match(re)) {
      return true;
    } else {
      return false;
    }
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  getTagColor(status: string) {
    return getTagColor(status);
  }

  get userType() {
    return this.authService.type;
  }
}
