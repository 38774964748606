import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';

@Component({
  standalone: true,
  selector: 'app-go-back',
  template: `
    <veolia-icon
      title="Go back"
      [size]="24"
      [name]="'chevron_left'"
      class="pointer backbtn"
      (click)="goBack()"></veolia-icon>
  `,
  styles: [
    `
      .pointer {
        cursor: pointer;
      }

      .backbtn{
        cursor: pointer;
        margin-right: 4px;
    }

    .backbtn:hover {
        background-color: var(--components-header-header-background);
        color:white
      }
    `,
  ],
  imports: [VeoliaIconComponent],
})
export class GoBackComponent {
  constructor(private location: Location) {}

  goBack() {
    this.location.back();
  }
}
