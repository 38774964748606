import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { catchError, map, of } from 'rxjs';
import { BusinessUnitService } from '../services/business-unit.service';

export const BusinessUnitResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  businessUnitService: BusinessUnitService = inject(BusinessUnitService),
  router: Router = inject(Router)
) => {
  const solutionId = route.paramMap.get('id');

  if (!solutionId) {
    router.navigate(['/not-found']);
    return of(null);
  }

  return businessUnitService.getBySolutionId(solutionId).pipe(
    map(businessUnitData => {
      if (!businessUnitData) {
        throw new Error('Business unit not found');
      }
      return {
        ...businessUnitData,
      };
    }),
    catchError(error => {
      let errorPage = '/error';

      if (error.message.includes('does not exist')) {
        errorPage = '/not-found';
      } else if (error.code === 'permission-denied') {
        errorPage = '/unauthorized';
      }

      router.navigate([errorPage]);
      return of(null);
    })
  );
};
