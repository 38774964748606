import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { FormlyFieldSelectProps } from '@ngx-formly/core/select';
import { getTagColor } from 'src/app/core/utils/colors';

interface RadioProps extends FormlyFieldProps, FormlyFieldSelectProps {}

@Component({
  selector: 'app-radio-status',
  template: `
    <nz-radio-group
      [formControl]="formControl"
      (ngModelChange)="props.change && props.change(field, $event)">
      <label
        class="spacing-v8"
        nz-radio
        *ngFor="
          let option of props.options | formlySelectOptions: field | async
        "
        [nzValue]="option.value"
        [nzDisabled]="option.disabled || props.disabled!">
        <veolia-tag
          [color]="getColorStatus(option.value)"
          [label]="option.label"
          [align]="'left'"
          (click)="props.change && props.change(field, $event)"></veolia-tag>
      </label>
    </nz-radio-group>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormStatusTypeComponent extends FieldType<
  FieldTypeConfig<RadioProps>
> {
  getColorStatus(status: string) {
    return getTagColor(status);
  }
}
