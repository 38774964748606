import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssessmentsSummaryPageComponent } from './pages/assessments-summary-page/assessments-summary-page.component';
import { BaseLayoutComponent } from 'src/app/shared/features/layout/components/base-layout/base-layout.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { SolutionResolver } from 'src/app/core/resolvers/solution-resolver';
import { LogsPageComponent } from './pages/logs-page/logs-page.component';
import { BusinessUnitResolver } from 'src/app/core/resolvers/business-unit-resolver';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'summary/:id',
        component: AssessmentsSummaryPageComponent,
        resolve: {
          solution: SolutionResolver,
          businessUnit: BusinessUnitResolver,
        },
      },
      {
        path: 'logs/:id',
        component: LogsPageComponent,
        resolve: {
          solution: SolutionResolver,
          businessUnit: BusinessUnitResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SolutionsRoutingModule {}
