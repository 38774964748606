import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { LegalFormService } from 'src/app/core/services/legal-form.service';
import { LegalFormStore } from './legal-form.store';
import { Solution } from 'src/app/core/models/solution';
import { ScrollService } from 'src/app/core/services/scroll.service';
import { Chapter } from 'src/app/core/types/chapter';
import { TranslateService } from '@ngx-translate/core';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  yesOrNoNAOptions,
  yesOrNoOptions,
  yesOrNoPlannedOptions,
} from 'src/app/core/forms/checkbox-options';
import { FormName } from 'src/app/core/enums/form-name';
import { LegalForm } from 'src/app/core/models/legal-form';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { AuthService } from 'src/app/core/services/auth.service';
import { Auth } from '@angular/fire/auth';
import { SolutionService } from 'src/app/core/services/solution.service';
import SolutionApi from 'src/app/core/apis/solution.api';
import { LogService } from 'src/app/core/services/log.service';
import { serverTimestamp } from '@angular/fire/database';
import { serverTimestamp as fireServerTimestamp } from '@angular/fire/firestore';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-legal-form',
  templateUrl: './legal-form.component.html',
  styleUrls: ['./legal-form.component.scss'],
  providers: [LegalFormStore],
})
export class LegalFormComponent implements OnInit {
  @Input() solutionId = '';
  @Input() solution?: Solution;
  @Input() businessUnit?: BusinessUnit;
  @Output() updateFormDate: EventEmitter<{
    formName: string;
    lastUpdate: Timestamp;
    lastUpdateBy: string;
    status: string;
  }> = new EventEmitter<{
    formName: string;
    lastUpdate: Timestamp;
    lastUpdateBy: string;
    status: string;
  }>();
  @Output() notifyDirty: EventEmitter<boolean> = new EventEmitter();

  authService = inject(AuthService);

  formName: FormName = FormName.Legal;
  showEvaluationModal?: boolean;
  isDirty = false;
  isSaving = false;

  vm$ = this.legalFormStore.vm$;

  formChapters: string[] = ['LA_0', 'LA_1', 'LA_2', 'LA_3', 'LA_4', 'LA_5'];
  formData: any = {};

  selectedChapter? = this.formChapters[0];
  fields: { [key: string]: Chapter } = {};

  constructor(
    private auth: Auth,
    private cd: ChangeDetectorRef,
    private legalFormService: LegalFormService,
    private legalFormStore: LegalFormStore,
    private logService: LogService,
    public messageService: NzMessageService,
    public solutionService: SolutionService,
    public scrollService: ScrollService,
    public solutionApi: SolutionApi,
    private translateService: TranslateService,
    private veoliaMessageService: VeoliaMessageService
  ) {}

  ngOnInit(): void {
    this.legalFormStore.resetData();
    this.legalFormStore.setSolutionId(this.solutionId);
    this.legalFormStore.setSolution(this.solution!);
    this.legalFormStore.setBusinessUnit(this.businessUnit!);
    this.legalFormStore.setFormName('Legal Form');
    this.legalFormStore.getLegalForm();
    this.legalFormStore.getEvaluation();
    this.legalFormStore.getAllReservations();
    this.legalFormStore.getAllComments();
    this.legalFormStore.getAllFiles();
    this.legalFormStore.getStatuses();

    this.legalFormStore.form$.subscribe(data => {
      this.updateFormDate.emit({
        formName: this.formName,
        lastUpdate: data?.lastUpdate!,
        lastUpdateBy: data?.lastUpdateBy!,
        status: data?.legalStatus!,
      });
    });

    this.fillFields();
  }

  get formCompletionStatus() {
    if ((this.legalFormStore.getForm() as LegalForm)?.legalStatus) {
      if (
        ['New', 'Vendor is responding'].includes(
          (this.legalFormStore.getForm() as LegalForm)?.legalStatus
        )
      ) {
        return false;
      } else {
        return true;
      }
    }

    return false;
  }

  get formStatus() {
    return (this.legalFormStore.getForm() as LegalForm)?.legalStatus || 'New';
  }

  get isVendor() {
    return this.solution?.emailVendorAuthorized.includes(
      this.auth.currentUser?.email!
    );
  }

  get isVendorReadOnly() {
    return (
      this.solution?.emailVendorReadonlyLegal?.includes(
        this.auth.currentUser?.email!
      ) || false
    );
  }

  set dirty(isDirty: boolean) {
    this.isDirty = isDirty;
    this.notifyDirty.emit(isDirty);
  }

  selectChapter(id: string) {
    this.selectedChapter = id;
    this.scrollService.scrollToElementById(id);
  }

  formEdited(data: any) {
    this.formData = { ...this.formData, ...data };
    this.dirty = true;
  }

  editChapter(data: any) {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;

    const messageId = this.messageService.loading('Saving...', {
      nzDuration: 0,
    }).messageId;
    setTimeout(() => {

      //if the form has been already assessed, the status for the form stay evaluated in 'solutions' and in 'legalForm' and in rtdb
      if (
        this.solution?.legalStatus === 'Recommended' ||
        this.solution?.legalStatus === 'Reservations' ||
        this.solution?.legalStatus === 'Not recommended'
      ) {
        this.legalFormService
          .update(
            this.solutionId,
            {
              ...data,
            },
            true
          )
          .then(() => {
            return this.solutionService.updateSolution(this.solutionId, {
              lastUpdate: fireServerTimestamp(),
            });
          })
          .then(() => {
            this.isSaving = false;
            this.dirty = false;
            this.messageService.remove(messageId);
            this.veoliaMessageService.create(
              {
                title: 'Saved successfully',
              },
              { duration: 3000 }
            );
          })
          .catch(error => {
            this.isSaving = false;
            console.log(error);
            this.messageService.remove(messageId);
          });
      } else {
        //if the form has not been assessed
        this.legalFormService
          .update(
            this.solutionId,
            {
              ...data,
              legalStatus: 'Vendor is responding',
            },
            true
          )
          .then(() => {
            return this.solutionService.updateSolution(this.solutionId, {
              lastUpdate: fireServerTimestamp(),
              legalStatus: 'Vendor is responding',
              status: 'Ongoing',
            });
          })
          .then(() => {
            this.isSaving = false;
            this.dirty = false;
            this.messageService.remove(messageId);
            this.veoliaMessageService.create(
              {
                title: 'Saved successfully',
              },
              { duration: 3000 }
            );
          })
          .catch(error => {
            console.log(error);
            this.isSaving = false;
            this.messageService.remove(messageId);
          });
      }
    }, 2000);
  }

  saveAll() {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;

    const messageId = this.messageService.loading('Saving All', {
      nzDuration: 0,
    }).messageId;
    setTimeout(() => {
      //if the form has been already assessed, the status for the form stay evaluated in 'solutions' and in 'legalForm' and in rtdb
      if (
        this.solution?.legalStatus === 'Recommended' ||
        this.solution?.legalStatus === 'Reservations' ||
        this.solution?.legalStatus === 'Not recommended'
      ) {
        this.legalFormService
          .update(
            this.solutionId,
            {
              ...this.formData,
            },
            true
          )
          .then(() => {
            this.dirty = false;
            return this.solutionService.updateSolution(this.solutionId, {
              lastUpdate: fireServerTimestamp(),
            });
          })
          .then(() => {
            this.isDirty = false;
            this.isSaving = false;
            this.messageService.remove(messageId);
            this.logService.setLogForAction(
              'SaveForm',
              this.solutionId!,
              this.formName!,
              {
                action: 'Save form',
                userEmail: this.auth.currentUser?.email!,
                dateTime: serverTimestamp(),
                userId: this.auth.currentUser?.uid!,
              }
            );
            this.veoliaMessageService.create(
              {
                title: 'Saved All successfully',
              },
              { duration: 3000 }
            );
          })
          .catch(error => {
            console.log(error);
            this.isSaving = false;
            this.dirty = true;
            this.messageService.remove(messageId);
          });
      } else {
        //if the form has not been assessed
        this.legalFormService
          .update(
            this.solutionId,
            {
              ...this.formData,
              legalStatus: 'Vendor is responding',
            },
            true
          )
          .then(() => {
            this.dirty = false;
            return this.solutionService.updateSolution(this.solutionId, {
              lastUpdate: fireServerTimestamp(),
              legalStatus: 'Vendor is responding',
              status: 'Ongoing',
            });
          })
          .then(() => {
            this.isSaving = false;
            this.messageService.remove(messageId);
            this.logService.setLogForAction(
              'SaveForm',
              this.solutionId!,
              this.formName!,
              {
                action: 'Save form',
                userEmail: this.auth.currentUser?.email!,
                dateTime: serverTimestamp(),
                userId: this.auth.currentUser?.uid!,
              }
            );
            this.veoliaMessageService.create(
              {
                title: 'Saved All successfully',
              },
              { duration: 3000 }
            );
          })
          .catch(error => {
            this.dirty = true;
            this.isSaving = false;
            console.log(error);
            this.messageService.remove(messageId);
          });
      }
    }, 2000);
  }

  markAsComplete(status: boolean) {
    const messageId = this.messageService.loading('Upading completion status', {
      nzDuration: 0,
    }).messageId;

    setTimeout(() => {
      this.legalFormService
        .update(
          this.solutionId,
          {
            legalStatus: status ? 'Vendor is responding' : 'Completed',
          },
          true
        )
        .then(() => {
          const data: any = {
            legalStatus: status ? 'Vendor is responding' : 'Completed',
          };

          if (status === false) {
            data['status'] = 'Ongoing';
          }

          return this.solutionService.update(this.solutionId, data, true);
        })
        .then(() => {
          this.messageService.remove(messageId);

          if (status == false) {
            this.veoliaMessageService.create(
              {
                title: 'Marked as completed successfully',
              },
              { duration: 3000 }
            );

            this.logService.setLogForAction(
              'SaveCompleteForm',
              this.solutionId!,
              this.formName!,
              {
                action: 'Save complete form',
                userEmail: this.auth.currentUser?.email!,
                dateTime: serverTimestamp(),
                userId: this.auth.currentUser?.uid!,
              }
            );

            this.solutionApi
              .notifySolutionFormCompleted(this.solutionId, {
                formName: this.formName,
                formNameRoute: 'legalForm',
              })
              .subscribe();
          } else {
            this.veoliaMessageService.create(
              {
                title: 'Marked as uncompleted successfully',
              },
              { duration: 3000 }
            );
          }

          this.refreshForm();
        })
        .catch(error => {
          console.log(error);
          this.messageService.remove(messageId);
        });
    }, 2000);
  }

  refreshForm() {
    this.legalFormStore.getLegalForm();
    this.legalFormStore.getEvaluation();
    this.legalFormStore.getAllReservations();
  }

  fillFields() {
    this.fields = {
      LA_0: {
        evaluation: {
          commentField: 'LA_0_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'LA_0_RADIO',
        },
        label: 'LA_0',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  solutionId: this.solutionId,
                  formName: 'Legal Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'LA_0_RADIO',
                    commentField: 'LA_0_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'LA_0_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_0_01_RADIO'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_0_02',
                    type: 'nz-datepicker',
                    props: {
                      label: this.translateService.instant('LA_0_02'),
                    },
                    expressions: {
                      hide: 'model.LA_0_01_RADIO === "No" || !model.LA_0_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      LA_1: {
        evaluation: {
          commentField: 'LA_1_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'LA_1_RADIO',
        },
        label: 'LA_1',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'LA_10',
                  solutionId: this.solutionId,
                  formName: 'Legal Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'LA_10_RADIO',
                    commentField: 'LA_10_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'LA_10_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_10_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'LA_11',
                  solutionId: this.solutionId,
                  formName: 'Legal Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'LA_11_RADIO',
                    commentField: 'LA_11_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'LA_111_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_111_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_111_RADIO_comment',
                        fieldId: 'LA_111_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_111_RADIO',
                        statusKey: 'LA_111_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_111_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_111_02'),
                    },
                    expressions: {
                      hide: 'model.LA_111_01_RADIO === "No" || !model.LA_111_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_112_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_112_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_112_RADIO_comment',
                        fieldId: 'LA_112_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_112_RADIO',
                        statusKey: 'LA_112_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_113_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_113_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_113_RADIO_comment',
                        fieldId: 'LA_113_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_113_RADIO',
                        statusKey: 'LA_113_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    template: `<p> 
                      ${this.translateService.instant('LA_113_02_A')}
                      ${this.translateService.instant('LA_113_02_B')}
                      <li>${this.translateService.instant('LA_113_02_PJ1')}</li>
                      <li>${this.translateService.instant('LA_113_02_PJ2')}</li>
                    </p>`,
                    expressions: {
                      hide: 'model.LA_113_01_RADIO !== "Yes" || !model.LA_111_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_114_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_114_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_114_RADIO_comment',
                        fieldId: 'LA_114_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_114_RADIO',
                        statusKey: 'LA_114_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_114_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_114_02'),
                    },
                    expressions: {
                      hide: 'model.LA_114_01_RADIO === "No" || !model.LA_114_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_114_03',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_114_03'),
                    },
                    expressions: {
                      hide: 'model.LA_114_01_RADIO === "Yes" || !model.LA_114_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_115_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_115_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_115_RADIO_comment',
                        fieldId: 'LA_115_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_115_RADIO',
                        statusKey: 'LA_115_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_115_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_115_02'),
                    },
                    expressions: {
                      hide: 'model.LA_115_01_RADIO === "No" || !model.LA_115_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_115_03',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_115_03'),
                    },
                    expressions: {
                      hide: 'model.LA_115_01_RADIO === "Yes" || !model.LA_115_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_116_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_116_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_116_RADIO_comment',
                        fieldId: 'LA_116_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_115_RADIO',
                        statusKey: 'LA_115_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_116_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_116_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_116_03',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_116_03'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'LA_12',
                  solutionId: this.solutionId,
                  formName: 'Legal Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'LA_12_RADIO',
                    commentField: 'LA_12_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'LA_121_b_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_121_b'),
                      options: yesOrNoPlannedOptions,
                      extraTemplate: ` 
                      <li><a style="float: left" target="_blank" rel="noopener" href="https://www.dataprivacyframework.gov/s/">EU-U.S.& Swiss-US Data Privacy Framework</a></li>
                      <li><a style="float: left" target="_blank" rel="noopener" href="https://www.dataprivacyframework.gov/s/">Swiss-US Data Privacy Framework</a></li>
                      <p>
                      ${this.translateService.instant('LA_121_b_01_RADIO')}
                      </p>
                      `,
                      evaluation: {
                        commentField: 'LA_1212_RADIO_comment',
                        fieldId: 'LA_121_b',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_1212_RADIO',
                        statusKey: 'LA_1212_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_121_b_02_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label: this.translateService.instant('LA_121_b_02'),
                    },
                    expressions: {
                      hide: 'model.LA_121_b_01_RADIO !== "Planned" || !model.LA_121_b_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_122_01_A_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_122_01'),
                      options: [
                        {
                          value:
                            'Data processing agreement based on article 28 of GDPR',
                          label:
                            'Data processing agreement based on article 28 of GDPR',
                        },
                        {
                          value:
                            'Data processing agreement based on article 26 of GDPR',
                          label:
                            'Data processing agreement based on article 26 of GDPR',
                        },
                      ],
                      extraTemplate: `
                      <p> 
                      <a style="float: left" target="_blank" rel="noopener" href="http://eur-lex.europa.eu/legal-content/EN/TXT/?qid=1494401818099&uri=CELEX:32016R0679">(REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT)</a>
                      </p>
                      <p>
                      ${this.translateService.instant('LA_122_01_A')}
                      </p>`,
                      evaluation: {
                        commentField: 'LA_122_RADIO_comment',
                        fieldId: 'LA_122_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_122_RADIO',
                        statusKey: 'LA_122_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_122_01_B_CHECKBOX',
                    type: 'nz-checkbox',
                    props: {
                      label: this.translateService.instant(
                        'LA_122_01_B_CHECKBOX'
                      ),
                      options: [
                        {
                          value: 'Module 1 CONTROLLER TO CONTROLLER',
                          label: 'Module 1 CONTROLLER TO CONTROLLER',
                        },
                        {
                          value: 'Module 2 CONTROLLER TO PROCESSOR',
                          label: 'Module 2 CONTROLLER TO PROCESSOR',
                        },
                        {
                          value: 'Module 3 PROCESSOR TO PROCESSOR',
                          label: 'Module 3 PROCESSOR TO PROCESSOR',
                        },
                        {
                          value: 'Module 4 PROCESSOR TO CONTROLLER',
                          label: 'Module 4 PROCESSOR TO CONTROLLER',
                        },
                      ],
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_123_01_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_123_0'),
                      evaluation: {
                        commentField: 'LA_123_RADIO_comment',
                        fieldId: 'LA_123_0',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_123_RADIO',
                        statusKey: 'LA_123_RADIO',
                        solutionId: this.solutionId,
                      },
                      extraTemplate: `
                      <p>${this.translateService.instant('LA_123_01_01')}</p>
                      <p> 
                          <li>${this.translateService.instant('LA_123_0_A')}</li>
                          <li>${this.translateService.instant('LA_123_01')}</li>
                      </p>`,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_123_01_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_123_01_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_123_01_03',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_123_01_03'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    template: `<p> 
                      ${this.translateService.instant('LA_123_0_B')}
                      <li>${this.translateService.instant('LA_123_02')}</li>
                    </p>`,
                  },
                  {
                    key: 'LA_123_02_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_123_02_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_124_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_124_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_124_RADIO_comment',
                        fieldId: 'LA_124_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_124_RADIO',
                        statusKey: 'LA_124_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_125_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_125_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_125_RADIO_comment',
                        fieldId: 'LA_125_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_125_RADIO',
                        statusKey: 'LA_125_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_126_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_126_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_126_RADIO_comment',
                        fieldId: 'LA_126_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_126_RADIO',
                        statusKey: 'LA_126_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_126_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_126_02'),
                    },
                    expressions: {
                      hide: 'model.LA_126_01_RADIO === "No" || !model.LA_126_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_127_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_127_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_127_RADIO_comment',
                        fieldId: 'LA_127_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_127_RADIO',
                        statusKey: 'LA_127_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_127_02_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_127_02'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      hide: 'model.LA_127_01_RADIO === "No" || !model.LA_127_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_128_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_128_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_128_RADIO_comment',
                        fieldId: 'LA_128_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_128_RADIO',
                        statusKey: 'LA_128_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_129_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_129_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_129_RADIO_comment',
                        fieldId: 'LA_129_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_129_RADIO',
                        statusKey: 'LA_129_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_129_02_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_129_02'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_1210_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_1210_0'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_1210_RADIO_comment',
                        fieldId: 'LA_1210_0',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_1210_RADIO',
                        statusKey: 'LA_1210_RADIO',
                        solutionId: this.solutionId,
                      },
                      extaTemplate: `<p> 
                      ${this.translateService.instant('LA_1210_01')}
                    </p>`,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_1210_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_1210_02'),
                    },
                    expressions: {
                      hide: 'model.LA_1210_01_RADIO === "No" || !model.LA_1210_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_1211_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_1211_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_1211_RADIO_comment',
                        fieldId: 'LA_1211_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_1211_RADIO',
                        statusKey: 'LA_1211_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_1211_02_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_1211_02'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      hide: 'model.LA_1211_01_RADIO === "No" || !model.LA_1211_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'LA_13',
                  solutionId: this.solutionId,
                  formName: 'Legal Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'LA_13_RADIO',
                    commentField: 'LA_13_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'LA_131_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_131_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_132_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_132_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_132_RADIO_comment',
                        fieldId: 'LA_132_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_132_RADIO',
                        statusKey: 'LA_132_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_132_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_132_02'),
                    },
                    expressions: {
                      hide: 'model.LA_132_01_RADIO === "No" || !model.LA_132_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_132_03',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_132_03'),
                    },
                    expressions: {
                      hide: 'model.LA_132_01_RADIO === "No" || !model.LA_132_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_132_01_02_CHECKBOX',
                    type: 'nz-checkbox',
                    props: {
                      label: this.translateService.instant(
                        'LA_132_01_02_CHECKBOX'
                      ),
                      options: [
                        {
                          value:
                            'Data storage for backup and other purposes that do not require access to data in the clear',
                          label:
                            'Data storage for backup and other purposes that do not require access to data in the clear',
                        },
                        {
                          value:
                            'Need of encryption of data to protect it from access by the public authorities of the third country of the importer when it transits between the exporter and its importer',
                          label:
                            'Need of encryption of data to protect it from access by the public authorities of the third country of the importer when it transits between the exporter and its importer',
                        },
                        {
                          value:
                            'Transfer to cloud service providers or other controllers or processors who need to access the data in the clear',
                          label:
                            'Transfer to cloud service providers or other controllers or processors who need to access the data in the clear',
                        },
                        {
                          value:
                            'Transfer of personal data for business purposes, including by way of remote access, requiring accessing data in the clear',
                          label:
                            'Transfer of personal data for business purposes, including by way of remote access, requiring accessing data in the clear',
                        },
                      ],
                    },
                    expressions: {
                      hide: 'model.LA_132_01_RADIO === "No" || !model.LA_132_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    type: 'nz-sub-chapter',
                    fieldGroup: [
                      {
                        props: {
                          label: 'LA_14',
                          solutionId: this.solutionId,
                          formName: 'Legal Form',
                          solutionName: this.solution?.solutionName,
                          evaluation: {
                            statusField: 'LA_14_RADIO',
                            commentField: 'LA_14_RADIO_comment',
                          },
                        },
                        fieldGroup: [
                          {
                            key: 'LA_141_01_RADIO',
                            type: 'radio',
                            props: {
                              label: this.translateService.instant('LA_141_01'),
                              options: yesOrNoOptions,
                              evaluation: {
                                commentField: 'LA_141_RADIO_comment',
                                fieldId: 'LA_141_01',
                                formName: this.formName,
                                solutionName: this.solution?.solutionName,
                                statusField: 'LA_141_RADIO',
                                statusKey: 'LA_141_RADIO',
                                solutionId: this.solutionId,
                              },
                            },
                            expressions: {
                              'props.disabled': 'formState.disabled',
                            },
                            wrappers: ['form-field-assessment-wrapper'],
                          },
                          {
                            key: 'LA_141_02',
                            type: 'nz-textarea',
                            props: {
                              label: this.translateService.instant('LA_141_02'),
                            },
                            expressions: {
                              hide: 'model.LA_141_01_RADIO === "No" || !model.LA_141_01_RADIO',
                              'props.disabled': 'formState.disabled',
                            },
                          },
                          {
                            key: 'LA_142_01_RADIO',
                            type: 'radio',
                            props: {
                              label: this.translateService.instant('LA_142_01'),
                              options: yesOrNoOptions,
                              evaluation: {
                                commentField: 'LA_142_RADIO_comment',
                                fieldId: 'LA_142_01',
                                formName: this.formName,
                                solutionName: this.solution?.solutionName,
                                statusField: 'LA_142_RADIO',
                                statusKey: 'LA_142_RADIO',
                                solutionId: this.solutionId,
                              },
                            },
                            expressions: {
                              'props.disabled': 'formState.disabled',
                            },
                            wrappers: ['form-field-assessment-wrapper'],
                          },
                          {
                            key: 'LA_142_02',
                            type: 'nz-textarea',
                            props: {
                              label: this.translateService.instant('LA_142_02'),
                            },
                            expressions: {
                              hide: 'model.LA_142_01_RADIO === "No" || !model.LA_142_01_RADIO',
                              'props.disabled': 'formState.disabled',
                            },
                          },
                          {
                            key: 'LA_142_03_RADIO',
                            type: 'radio',
                            props: {
                              label: this.translateService.instant('LA_142_03'),
                              options: yesOrNoOptions,
                            },
                          },
                          {
                            key: 'LA_142_04',
                            type: 'nz-textarea',
                            props: {
                              label: this.translateService.instant('LA_142_04'),
                            },
                            expressions: {
                              hide: 'model.LA_142_03_RADIO === "No" || !model.LA_142_03_RADIO',
                              'props.disabled': 'formState.disabled',
                            },
                          },
                          {
                            key: 'LA_142_05',
                            type: 'nz-textarea',
                            props: {
                              label: this.translateService.instant('LA_142_05'),
                            },
                            expressions: {
                              'props.disabled': 'formState.disabled',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'LA_15',
                  solutionId: this.solutionId,
                  formName: 'Legal Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'LA_15_RADIO',
                    commentField: 'LA_15_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'LA_151_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_151_01'),
                      evaluation: {
                        commentField: 'LA_151_RADIO_comment',
                        fieldId: 'LA_151_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_151_RADIO',
                        statusKey: 'LA_151_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_151_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_151_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_152_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_152_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_152_RADIO_comment',
                        fieldId: 'LA_152_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_152_RADIO',
                        statusKey: 'LA_152_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_152_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_152_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_153_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_153_01'),
                      evaluation: {
                        commentField: 'LA_153_RADIO_comment',
                        fieldId: 'LA_153_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_153_RADIO',
                        statusKey: 'LA_153_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_153_02_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_153_02'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      LA_2: {
        evaluation: {
          commentField: 'LA_2_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'LA_2_RADIO',
        },
        label: 'LA_2',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: '',
                  solutionId: this.solutionId,
                  formName: 'Legal Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'LA_2_RADIO',
                    commentField: 'LA_2_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'LA_21_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_21_01'),
                      evaluation: {
                        commentField: 'LA_21_RADIO_comment',
                        fieldId: 'LA_21_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_21_RADIO',
                        statusKey: 'LA_21_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_22_01_CHECKBOX',
                    type: 'nz-checkbox',
                    props: {
                      label: this.translateService.instant('LA_22_01_CHECKBOX'),
                      options: [
                        {
                          value: 'in the USA',
                          label: 'in the USA',
                        },
                        {
                          value: 'in China',
                          label: 'in China',
                        },
                        {
                          value: 'in Russia',
                          label: 'in Russia',
                        },
                      ],
                      evaluation: {
                        commentField: 'LA_22_RADIO_comment',
                        fieldId: 'LA_22_01_CHECKBOX',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_22_RADIO',
                        statusKey: 'LA_22_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_23_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_23_01'),
                      evaluation: {
                        commentField: 'LA_23_RADIO_comment',
                        fieldId: 'LA_23_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_23_RADIO',
                        statusKey: 'LA_23_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_24_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_24_01'),
                      evaluation: {
                        commentField: 'LA_24_RADIO_comment',
                        fieldId: 'LA_24_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_24_RADIO',
                        statusKey: 'LA_24_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_25_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_25_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_25_RADIO_comment',
                        fieldId: 'LA_25_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_25_RADIO',
                        statusKey: 'LA_25_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_26_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_26_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_26_RADIO_comment',
                        fieldId: 'LA_26_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_26_RADIO',
                        statusKey: 'LA_26_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                ],
              },
            ],
          },
        ],
      },
      LA_3: {
        evaluation: {
          commentField: 'LA_3_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'LA_3_RADIO',
        },
        label: 'LA_3',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: '',
                  solutionId: this.solutionId,
                  formName: 'Legal Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'LA_3_RADIO',
                    commentField: 'LA_3_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'LA_31_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_31_01'),
                      evaluation: {
                        commentField: 'LA_31_RADIO_comment',
                        fieldId: 'LA_31_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_31_RADIO',
                        statusKey: 'LA_31_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_32_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_32_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_32_RADIO_comment',
                        fieldId: 'LA_32_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_32_RADIO',
                        statusKey: 'LA_32_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    template: `<p> 
                      ${this.translateService.instant('LA_33_0')}
                    </p>`,
                  },
                  {
                    key: 'LA_33_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_33_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_33_RADIO_comment',
                        fieldId: 'LA_33_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_33_RADIO',
                        statusKey: 'LA_33_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_33_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_33_02'),
                    },
                    expressions: {
                      hide: 'model.LA_33_01_RADIO === "No" || !model.LA_33_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_34_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_34_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_34_RADIO_comment',
                        fieldId: 'LA_34_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_34_RADIO',
                        statusKey: 'LA_34_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_34_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_34_02'),
                    },
                    expressions: {
                      hide: 'model.LA_34_01_RADIO === "No" || !model.LA_34_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      LA_4: {
        evaluation: {
          commentField: 'LA_4_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'LA_4_RADIO',
        },
        label: 'LA_4',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: '',
                  solutionId: this.solutionId,
                  formName: 'Legal Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'LA_4_RADIO',
                    commentField: 'LA_4_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'LA_41_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_41_01'),
                      evaluation: {
                        commentField: 'LA_41_RADIO_comment',
                        fieldId: 'LA_41_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_41_RADIO',
                        statusKey: 'LA_41_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_42_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_42_01'),
                      options: [
                        {
                          value:
                            'NO (Not Applicable), because it’s a SaaS service with a constant monitoring',
                          label:
                            'NO (Not Applicable), because it’s a SaaS service with a constant monitoring',
                        },
                        {
                          value: 'YES (please fill in the following questions)',
                          label: 'YES (please fill in the following questions)',
                        },
                      ],
                      evaluation: {
                        commentField: 'LA_42_RADIO_comment',
                        fieldId: 'LA_42_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_42_RADIO',
                        statusKey: 'LA_42_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_43_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_43_01'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    template: `<p> 
                      ${this.translateService.instant('LA_44_0')}
                    </p>`,
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_01'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_01_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_01_RADIO === "Yes" || !model.LA_44_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_02_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_02'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_02_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_02_RADIO === "Yes" || !model.LA_44_02_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_03_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_03'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_03_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_03_RADIO === "Yes" || !model.LA_44_03_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_04_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_04'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_04_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_04_RADIO === "Yes" || !model.LA_44_04_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_05_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_05'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_05_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_05_RADIO === "Yes" || !model.LA_44_05_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_06_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_06'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_06_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_06_RADIO === "Yes" || !model.LA_44_06_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_07_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_07'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_07_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_07_RADIO === "Yes" || !model.LA_44_07_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_08_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_08'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_08_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_08_RADIO === "Yes" || !model.LA_44_08_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_09_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_09'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_09_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_09_RADIO === "Yes" || !model.LA_44_00_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_10_CHECKBOX',
                    type: 'nz-checkbox',
                    props: {
                      label: this.translateService.instant('LA_44_10_CHECKBOX'),
                      options: [
                        {
                          value:
                            'the Audit clause(s) based on which the Audit will be conductedR',
                          label:
                            'the Audit clause(s) based on which the Audit will be conducted',
                        },
                        {
                          value:
                            'the methodology based on which the Audit will be conducted',
                          label:
                            'the methodology based on which the Audit will be conducted',
                        },
                        {
                          value: 'the planning envisaged for Audit operations',
                          label: 'the planning envisaged for Audit operations',
                        },
                        {
                          value:
                            'the list of signed Agreements and Order Forms between Editor and Customer or Customer Affiliate',
                          label:
                            'the list of signed Agreements and Order Forms between Editor and Customer or Customer Affiliate',
                        },
                        {
                          value:
                            'the list of software products within the Audit scope, with applicable metric for each of these software products',
                          label:
                            'the list of software products within the Audit scope, with applicable metric for each of these software products',
                        },
                      ],
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_11_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_11'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_11_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_11_RADIO === "Yes" || !model.LA_44_11_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_12_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_12'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_12_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_12_RADIO === "Yes" || !model.LA_44_12_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_13_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_13'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_13_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_13_RADIO === "Yes" || !model.LA_44_13_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_14_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_14'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_14_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_14_RADIO === "Yes" || !model.LA_44_14_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_15_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_15'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_15_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_15_RADIO === "Yes" || !model.LA_44_15_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_16_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_16'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_16_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_16_RADIO === "Yes" || !model.LA_44_16_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_17_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_17'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_17_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_17_RADIO === "Yes" || !model.LA_44_17_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_18_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_18'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_18_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_18_RADIO === "Yes" || !model.LA_44_18_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_19_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_19'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_19_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_19_RADIO === "Yes" || !model.LA_44_19_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_20_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_20'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_20_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_20_RADIO === "Yes" || !model.LA_44_20_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_21_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_21'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_21_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_21_RADIO === "Yes" || !model.LA_44_21_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_22_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_44_22'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: 'model.LA_42_01_RADIO === "NO (Not Applicable), because it’s a SaaS service with a constant monitoring" || !model.LA_42_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'LA_44_22_NONA',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('LA_44_NONA'),
                    },
                    expressions: {
                      hide: 'model.LA_44_22_RADIO === "Yes" || !model.LA_44_22_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      LA_5: {
        evaluation: {
          commentField: 'LA_5_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'LA_5_RADIO',
        },
        label: 'LA_5',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: '',
                  solutionId: this.solutionId,
                  formName: 'Legal Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'LA_5_RADIO',
                    commentField: 'LA_5_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'LA_51_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_51_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_51_RADIO_comment',
                        fieldId: 'LA_51_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_51_RADIO',
                        statusKey: 'LA_51_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_52_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_52_01'),
                      options: yesOrNoOptions,
                      evaluation: {
                        commentField: 'LA_52_RADIO_comment',
                        fieldId: 'LA_52_01',
                        formName: this.formName,
                        solutionName: this.solution?.solutionName,
                        statusField: 'LA_52_RADIO',
                        statusKey: 'LA_52_RADIO',
                        solutionId: this.solutionId,
                      },
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                    wrappers: ['form-field-assessment-wrapper'],
                  },
                  {
                    key: 'LA_52_02_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('LA_52_02'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      hide: 'model.LA_52_01_RADIO === "No" || !model.LA_52_01_RADIO',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  }
}
