import { Injectable } from '@angular/core';

import { map, switchMap, tap, withLatestFrom } from 'rxjs';
import { DesignForm } from 'src/app/core/models/design-form';
import { ChapterCompletionStatusService } from 'src/app/core/services/chapter-completion-status.service';
import { CommentService } from 'src/app/core/services/comment.service';
import { DesignFormService } from 'src/app/core/services/design-form.service';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { FileService } from 'src/app/core/services/file.service';
import { ReservationService } from 'src/app/core/services/reservation.service';
import { FormStore } from 'src/app/core/stores/form.store';

@Injectable({
  providedIn: 'root',
})
export class DesignFormStore extends FormStore {
  constructor(
    protected override evaluationService: EvaluationService,
    protected override reservationService: ReservationService,
    protected override chapterCompletionService: ChapterCompletionStatusService,
    protected override commentService: CommentService,
    protected override fileService: FileService,
    private designFormService: DesignFormService
  ) {
    super(
      evaluationService,
      reservationService,
      chapterCompletionService,
      commentService,
      fileService
    );
  }

  getDesignForm = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => this.setNotReady()),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ solution, solutionId }) => {
        return this.designFormService
          .get(solutionId)
          .then(data => {
            if (data.exists()) {
              this.setForm({
                uid: solution?.uid,
                ...data.data(),
              } as DesignForm);
              this.setReady();
            } else { //injection in firebase
              this.designFormService
                .create(
                  this.initForm(solution, 'design') as unknown as DesignForm,
                  solution?.uid
                )
                .then(() => { //injection in store to display data
                  this.setForm({
                    uid: solution?.uid,
                    ...this.initForm(solution, 'design'),
                  } as unknown as DesignForm);

                  this.setReady();
                })
                .catch(() => this.setReady());
            }
          })
          .catch(error => {
            console.log(error);
            this.setReady();
          });
      })
    );
  });
}
