<div class="spacing-v16">
  <nz-card [nzTitle]="reservation_header">
    <p class="row row-space-between">
      <strong>Issued on:</strong>
      <span *ngIf="reservation?.postedAt">
        {{ formatDate(reservation.postedAt.toString()) }}
      </span>
    </p>
    <p>
      <span class="row row-space-between">
        <strong>By:</strong>
        <span>
          <a href="mailto:{{ reservation.postedBy }}">{{
            reservation.postedBy
          }}</a>
          <span *ngIf="reservation?.userRole">
            <small>({{ reservation.userRole }})</small>
          </span>
        </span>
      </span>
    </p>
    <div *ngIf="reservation?.content">
      <p class="row row-space-between">
        <strong>Description:</strong>
        <span nz-typography nzCopyable [nzContent]="reservation.content">
          <code>{{ reservation.content }}</code>
        </span>
      </p>
    </div>
    <div *ngIf="reservation?.deadlineDate">
      <p class="row row-space-between">
        <strong>Expected deadline:</strong>
        <span>
          {{ reservation.deadlineDate | date: 'MMMM d, y' }}
        </span>
      </p>
    </div>
    <hr />
    <div *ngIf="reservation?.fixed && reservation?.fixedOn">
      <p class="row row-space-between">
        <strong>Fixed on :</strong>
        <span>
          {{ reservation.fixedOn | date: 'MMMM d, y, h:mm a' }}
        </span>
      </p>
    </div>
    <div *ngIf="reservation?.fixed && reservation?.fixedOn">
      <p class="row row-space-between">
        <strong>Fixed by :</strong>
        <span>
          {{ reservation.fixedBy }}
        </span>
      </p>
    </div>

    <nz-divider *ngIf="editMode"></nz-divider>
    <form
      nz-form
      [formGroup]="form"
      nzLayout="vertical"
      (ngSubmit)="submitForm()">
      <nz-form-item>
        <nz-form-control>
          <p class="row row-space-between">
            <strong>Reservation fixed ?:</strong>
            <nz-switch
              [nzDisabled]="!editMode"
              nzCheckedChildren="Yes"
              nzUnCheckedChildren="No"
              formControlName="fixed"></nz-switch>
          </p>
        </nz-form-control>
      </nz-form-item>
      <nz-divider *ngIf="editMode"></nz-divider>
      <div class="row row-space-between" *ngIf="editMode">
        <nz-form-item>
          <nz-form-control>
            <veolia-button
              [variant]="'secondary'"
              [size]="'default'"
              [htmlType]="'button'"
              (click)="close()"
              >Cancel
            </veolia-button>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <veolia-button
              [size]="'default'"
              [htmlType]="'submit'"
              [icon]="'save'"
              >Save
            </veolia-button>
          </nz-form-control>
        </nz-form-item>
      </div>
    </form>
  </nz-card>
</div>
<ng-template #reservation_header>
  <div class="row row-space-between">
    <span>
      <strong [ngClass]="getHeaderColor(reservation.fixed)">
        {{ reservation.reference | translate }}
      </strong>
    </span>
    <div>
      <div class="row">
        <veolia-button
          *ngIf="
            !editMode &&
            solution?.status !== 'Canceled' &&
            solution?.status !== 'Pending' &&
            (hasFullRights || userIsVendorAuthorized)
          "
          [size]="'default'"
          [htmlType]="'button'"
          [icon]="'edit'"
          (click)="edit()"
          >Edit
        </veolia-button>
        <veolia-button
          [variant]="'secondary'"
          [size]="'default'"
          [htmlType]="'button'"
          [icon]="'close'"
          (click)="close()"
          *ngIf="editMode"
          >Close
        </veolia-button>
      </div>
    </div>
  </div>
</ng-template>
