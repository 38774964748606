import { Component, EventEmitter, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-login-option",
  templateUrl: "./login-option.component.html",
  styleUrls: ["./login-option.component.scss"],
})
export class LoginOptionComponent {
  @Output() openLoginForm = new EventEmitter<void>();
  @Output() onSAML = new EventEmitter<void>();

  constructor(private translate: TranslateService) {}

  loginWithGoogle() {
    this.onSAML.emit();
  }

  loginWithUsernameAndPassword() {
    this.openLoginForm.emit();
  }

}
