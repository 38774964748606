import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PresenceService } from './core/services/presence.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import { AuthService } from './core/services/auth.service';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';
import { Subscription } from 'rxjs';
import { TabService } from './core/services/tab.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'frontend-v2';
  countdown?: number;
  lastPing?: Date;
  idleState = 'NOT_STARTED';
  private subscriptions: Subscription[] = [];
  showConfirm = false;
  private tabId: string;
  private tabCount = 0;

  @ViewChild('modal') modal!: VeoliaModalComponent;

  constructor(
    private auth: Auth,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private presenceService: PresenceService,
    private translate: TranslateService,
    private tabService: TabService
  ) {
    translate.setDefaultLang('en');
    translate.use('en');
    this.tabId = Math.random().toString(36).substr(2, 9);

    // Presence
    // set idle parameters
    this.idle.setIdle(60);
    this.idle.setTimeout(3600);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'IDLE';
      this.presenceService.updateOnAway();
      this.presenceService.setPresence(this.auth.currentUser?.uid!, 'away');
    });
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'NOT_IDLE';
      this.presenceService.setPresence(this.auth.currentUser?.uid!, 'online');
      this.countdown = undefined;
      this.cd.detectChanges();
    });
    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'TIMED_OUT';
      this.presenceService.updateOnDisconnect();
      this.authService.SignOut();
    });
    this.idle.onTimeoutWarning.subscribe(seconds => (this.countdown = seconds));

    this.keepalive.interval(15);
    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
  }

  ngOnInit() {
    onAuthStateChanged(this.auth, user => {
      if (user) {
        this.tabService.initializeTabManager(user.uid, this.tabId);
        this.subscriptions.push(
          this.tabService.getShowConfirm().subscribe(show => {
            if (show) {
              this.modal.open();
            }
            this.showConfirm = show;
          })
        );
        this.subscriptions.push(
          this.tabService.getShouldClose().subscribe(shouldClose => {
            if (shouldClose) {
              this.showConfirm = false;
              this.modal.close();
              // setTimeout(() => {
              window.location.href = 'https://www.veolia.com';
              // }, 5000);
            }
          })
        );

        this.subscriptions.push(
          this.tabService.getTabCount().subscribe(count => {
            this.tabCount = count;
          })
        );
      }
    });

    window.addEventListener('beforeunload', this.onBeforeUnload.bind(this));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    window.removeEventListener('beforeunload', this.onBeforeUnload.bind(this));
    this.tabService.closeTabOnDestroy();
  }

  setActiveTab() {
    this.tabService.setActiveTab();
    this.modal.close();
  }

  private onBeforeUnload() {
    this.tabService.closeTab();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'NOT_IDLE';
    this.countdown = undefined;
    this.lastPing = undefined;
  }
}
