import { Component } from "@angular/core";
import { Router, Event as NavigationEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from "@angular/router";

@Component({
  selector: 'app-base-layout',
  styleUrls: ['./base-layout.component.scss'],
  templateUrl: './base-layout.component.html'
})
export class BaseLayoutComponent {
  loading = false;

  constructor(private router: Router) {
    this.router.events.subscribe((event: NavigationEvent) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }
}
