import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-caption-item',
  templateUrl: './caption-item.component.html',
  styleUrls: ['./caption-item.component.scss'],
})
export class CaptionItemComponent {
  @Input() title = '';
}
