import { User } from "../models/user";
import { EmailedUser } from "../types/emailed-user";

const UserMapper = {
  toEmailedUser: (user: User): EmailedUser => ({
    id: user.uid,
    email: user.email,
    name: user.firstname || user.lastname ? `${user.firstname} ${user.lastname} - ${user.email}` : user.email,
  }),
}

export default UserMapper;
