import { Injectable } from "@angular/core";
import { BaseApi } from "./api";
import { Observable } from "rxjs";
import { UpdateReservationApiDTO } from "../dtos/update-reservation-api.dto";

@Injectable()
export class ReservationApi extends BaseApi {
  notifyReservation(solution_id: string, data: UpdateReservationApiDTO): Observable<any> {
    return this.post(`/api/reservations/notify/${solution_id}`, data);
  }
}
