<div *ngIf="vm$ | async as vm">
  <app-simple-spinner-placeholder *ngIf="!vm.ready" />
  <app-form-toolbar
    *ngIf="vm.ready"
    [comments]="vm.comments"
    [currentForm]="vm.form"
    [fileList]="vm.fileList"
    [files]="vm.files"
    [evaluation]="vm.evaluation"
    [formName]="formName"
    [formCompletionStatus]="formCompletionStatus"
    [formStatus]="formStatus"
    [solution]="solution"
    [businessUnit]="businessUnit"
    (saveAll)="saveAll()"
    (markAsComplete)="markAsComplete(formCompletionStatus)"
    (refreshForm)="refreshForm()"
    [isDirty]="isDirty"
    [isSaving]="isSaving" />
  <div nz-row [nzGutter]="16">
    <div
      *ngIf="vm.ready"
      nz-col
      class="gutter-row"
      [nzSpan]="5"
      [nzLg]="6"
      [nzMd]="6"
      [nzSm]="24"
      [nzXs]="24">
      <div class="rounded-white spacing-b16">
        <h4 nz-typography>Table of content</h4>
        <div>
          <app-form-block-button
            [showId]="false"
            id="1"
            [title]="'DA_9' | translate"
            (click)="selectChapter('DA_9')"
            [evaluation]="vm.evaluation"
            evaluationField="DA_9_RADIO"
            [selected]="selectedChapter === 'DA_9'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="2"
            [title]="'DA_1' | translate"
            (click)="selectChapter('DA_1')"
            [evaluation]="vm.evaluation"
            evaluationField="DA_1_RADIO"
            [selected]="selectedChapter === 'DA_1'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="3"
            [title]="'DA_2' | translate"
            (click)="selectChapter('DA_2')"
            [evaluation]="vm.evaluation"
            evaluationField="DA_2_RADIO"
            [selected]="selectedChapter === 'DA_2'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="4"
            [title]="'DA_3' | translate"
            (click)="selectChapter('DA_3')"
            [evaluation]="vm.evaluation"
            evaluationField="DA_3_RADIO"
            [selected]="selectedChapter === 'DA_3'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="5"
            [title]="'DA_4' | translate"
            (click)="selectChapter('DA_4')"
            [evaluation]="vm.evaluation"
            evaluationField="DA_4_RADIO"
            [selected]="selectedChapter === 'DA_4'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="6"
            [title]="'DA_5' | translate"
            (click)="selectChapter('DA_5')"
            [evaluation]="vm.evaluation"
            evaluationField="DA_5_RADIO"
            [selected]="selectedChapter === 'DA_5'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="7"
            [title]="'DA_6' | translate"
            (click)="selectChapter('DA_6')"
            [evaluation]="vm.evaluation"
            evaluationField="DA_6_RADIO"
            [selected]="selectedChapter === 'DA_6'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="8"
            [title]="'DA_7' | translate"
            (click)="selectChapter('DA_7')"
            [evaluation]="vm.evaluation"
            evaluationField="DA_7_RADIO"
            [selected]="selectedChapter === 'DA_7'"
            [unlocked]="true">
          </app-form-block-button>
          <app-form-block-button
            [showId]="false"
            id="9"
            [title]="'DA_8' | translate"
            (click)="selectChapter('DA_8')"
            [evaluation]="vm.evaluation"
            evaluationField="DA_8_RADIO"
            [selected]="selectedChapter === 'DA_8'"
            [unlocked]="true">
          </app-form-block-button>
        </div>
      </div>
    </div>
    <div
      id="form-chapters"
      *ngIf="vm.ready"
      nz-col
      class="gutter-row chapters"
      [nzLg]="18"
      [nzMd]="18"
      [nzSm]="24"
      [nzXs]="24">
      <app-form-chapter
        *ngIf="fields['DA_9']?.fields"
        [evaluation]="fields['DA_9']['evaluation']!"
        [formEvaluation]="evaluation"
        [chapterId]="'DA_9'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Design Form"
        [formModel]="vm.form"
        [formFields]="fields['DA_9'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('DA_9')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['DA_1']?.fields"
        [evaluation]="fields['DA_1']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'DA_1'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Design Form"
        [formModel]="vm.form"
        [formFields]="fields['DA_1'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('DA_1')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['DA_2']?.fields"
        [evaluation]="fields['DA_2']['evaluation']!"
        [formEvaluation]="evaluation"
        [chapterId]="'DA_2'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Design Form"
        [formFields]="fields['DA_2'].fields"
        [formModel]="vm.form"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('DA_2')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['DA_3']?.fields"
        [evaluation]="fields['DA_3']['evaluation']!"
        [formEvaluation]="evaluation"
        [chapterId]="'DA_3'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Design Form"
        [formModel]="vm.form"
        [formFields]="fields['DA_3'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('DA_3')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['DA_4']?.fields"
        [evaluation]="fields['DA_4']['evaluation']!"
        [formEvaluation]="evaluation"
        [chapterId]="'DA_4'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Design Form"
        [formModel]="vm.form"
        [formFields]="fields['DA_4'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('DA_4')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['DA_5']?.fields"
        [evaluation]="fields['DA_5']['evaluation']!"
        [formEvaluation]="evaluation"
        [chapterId]="'DA_5'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Design Form"
        [formModel]="vm.form"
        [formFields]="fields['DA_5'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('DA_5')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['DA_6']?.fields"
        [evaluation]="fields['DA_6']['evaluation']!"
        [formEvaluation]="evaluation"
        [chapterId]="'DA_6'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Design Form"
        [formModel]="vm.form"
        [formFields]="fields['DA_6'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('DA_6')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['DA_7']?.fields"
        [evaluation]="fields['DA_7']['evaluation']!"
        [formEvaluation]="evaluation"
        [chapterId]="'DA_7'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Design Form"
        [formModel]="vm.form"
        [formFields]="fields['DA_7'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('DA_7')"
        (formEdited)="formEdited($event)"></app-form-chapter>
      <app-form-chapter
        *ngIf="fields['DA_8']?.fields"
        [evaluation]="fields['DA_8']['evaluation']!"
        [formEvaluation]="evaluation"
        [chapterId]="'DA_8'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Design Form"
        [formModel]="vm.form"
        [formFields]="fields['DA_8'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('DA_8')"
        (formEdited)="formEdited($event)"></app-form-chapter>
    </div>
  </div>
</div>
