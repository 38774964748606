import { Component } from '@angular/core';
import { HomePageStore } from './home-page.store';
import { SolutionService } from '../../../../core/services/solution.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  providers: [SolutionService, HomePageStore],
})
export class HomePageComponent {
  vm$ = this.homePageStore.vm$;

  tabOptions = [{ label: 'SaaS', value: 0 }];

  constructor(
    private authService: AuthService,
    private homePageStore: HomePageStore,
    private router: Router
  ) {}

  get isVeolia() {
    return this.authService.userData.value?.isVeolia;
  }

  onSolutionTypeChange(type: number) {
    this.homePageStore.setSolutionType(type);
  }

  goToNewDemand() {
    this.router.navigate(['/demands/form']);
  }

  redirectToReleaseNotes() {
    window.open(
      'https://lookerstudio.google.com/u/0/reporting/5a17bdf9-97bc-46aa-adaf-bcadfc31c358/page/v6eMD',
      '_blank'
    );
  }
}
