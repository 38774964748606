import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { VendorSolutionsStore } from './vendor-solutions.store';
import { AuthService } from 'src/app/core/services/auth.service';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SolutionService } from 'src/app/core/services/solution.service';

@Component({
  selector: 'app-home-page-vendor-solutions',
  templateUrl: './vendor-solutions.component.html',
  styleUrls: ['./vendor-solutions.component.scss'],
  providers: [VendorSolutionsStore],
})
export class VendorSolutionsComponent
  extends DataTableComponent
  implements OnInit, OnChanges
{
  @Input() solutionType = 0;

  vm$ = this.vendorSolutionsStore.vm$;

  constructor(
    public override authService: AuthService,
    public override router: Router,
    public override translateService: TranslateService,
    private vendorSolutionsStore: VendorSolutionsStore,
    public override solutionService: SolutionService
  ) {
    super(authService, router, translateService, solutionService);
  }

  ngOnInit(): void {
    this.authService.userData.subscribe(user => {
      if (user) {
        this.vendorSolutionsStore.setUid(user?.uid);
        this.vendorSolutionsStore.setEmail(user?.email);
        this.vendorSolutionsStore.setSolutionType(this.solutionType);
        this.vendorSolutionsStore.getCount();
        this.vendorSolutionsStore.getSolutions();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['solutionType']['firstChange']) {
      this.vendorSolutionsStore.changeSolutionType(
        changes['solutionType'].currentValue
      );
    }
  }

  changePage(page: number) {
    this.vendorSolutionsStore.changePage(page);
  }

  changeSize(size: Event) {
    const limit = parseInt((size.target as HTMLSelectElement).value);
    this.vendorSolutionsStore.changeLimit(limit);
  }

  onSearch(event: string) {
    this.vendorSolutionsStore.changeSearch(event);
  }
}
