import { Component, Input } from '@angular/core';
import { VeoliaIconName } from '@veolia.com/vds-angular-components/icons';

@Component({
  selector: 'app-logs-tab-icon',
  templateUrl: './logs-tab-icon.component.html',
  styleUrls: ['./logs-tab-icon.component.scss'],
})
export class LogsTabIconComponent {
  @Input() icon_name?: VeoliaIconName;
  @Input() title = '';
}
