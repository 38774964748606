<div class="row row-right spacing-v8">
  <nz-input-group [nzSuffix]="searchIcon" class="search-input-group">
    <input *ngIf="!isAdminUsersRoute() && !isAdminBusRoute()" nz-input placeholder="Search by all fields" #search />
    <input *ngIf="isAdminUsersRoute()" nz-input placeholder="Search by all fields" #search />
    <input *ngIf="isAdminBusRoute()" nz-input placeholder="Search by BU name" #search />
  </nz-input-group>
  <ng-template #searchIcon>
    <i nz-icon nzType="search"></i>
    <i *ngIf="isSearching" nz-icon nzType="close-circle" class="clear-icon" (click)="clearSearch()"></i>
  </ng-template>
</div>
