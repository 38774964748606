import { User } from '../../models/user';

export interface UserItemStoreState<T> {
  currentPage: number;
  isLoading: boolean;
  isSearching: boolean;
  error: string | null;
  users: User[];
  total: number;
  limit: number;
  userType: number;
  businessUnit: string;
  isForward: boolean;
  prevCursor: any;
  nextCursor: any;
  search: string;
  uid: string;
  email: string;
}

export const userItemStoreInitialState = {
  currentPage: 1,
  isLoading: false,
  isSearching: false,
  error: null,
  users: [],
  total: 0,
  limit: 10,
  userType: 0,
  businessUnit: '',
  isForward: true,
  prevCursor: null,
  nextCursor: null,
  search: '',
  uid: '',
  email: '',
};
