import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { map, switchMap, tap, withLatestFrom } from 'rxjs';
import UserMapper from 'src/app/core/mappers/user.mapper';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { Solution } from 'src/app/core/models/solution';
import { BusinessUnitService } from 'src/app/core/services/business-unit.service';
import { SolutionService } from 'src/app/core/services/solution.service';
import { UserService } from 'src/app/core/services/user.service';
import { EmailedUser } from 'src/app/core/types/emailed-user';

interface AccessRightsStoreState {
  businessUnit?: BusinessUnit;
  error?: string;
  evaluatorList: string[];
  isLoading: boolean;
  isLoadingUsers: boolean;
  isLoadingEvaluatorList: boolean;
  mode: number;
  options: string[];
  solution?: Solution;
  solutionId?: string;
  users: EmailedUser[];
}

@Injectable()
export class AccessRightsStore extends ComponentStore<AccessRightsStoreState> {
  readonly error$ = this.select(state => state.error);
  readonly evaluatorList$ = this.select(state => state.evaluatorList);
  readonly isLoading$ = this.select(state => state.isLoading);
  readonly isLoadingEvaluatorList$ = this.select(
    state => state.isLoadingEvaluatorList
  );
  readonly isLoadingUsers$ = this.select(state => state.isLoadingUsers);
  readonly mode$ = this.select(state => state.mode);
  readonly options$ = this.select(state => state.options);
  readonly solution$ = this.select(state => state.solution);
  readonly solutionId$ = this.select(state => state.solutionId);
  readonly users$ = this.select(state => state.users);

  vm$ = this.select(
    this.evaluatorList$,
    this.isLoading$,
    this.isLoadingEvaluatorList$,
    this.isLoadingUsers$,
    this.mode$,
    this.options$,
    this.solution$,
    this.solutionId$,
    this.users$,
    (
      evaluatorList,
      isLoading,
      isLoadingEvaluatorList,
      isLoadingUsers,
      mode,
      options,
      solution,
      solutionId,
      users
    ) => ({
      evaluatorList,
      isLoading,
      isLoadingEvaluatorList,
      isLoadingUsers,
      mode,
      options,
      solution,
      solutionId,
      users,
    })
  );

  constructor(
    private businessUnitService: BusinessUnitService,
    private solutionService: SolutionService,
    private userService: UserService
  ) {
    super({
      evaluatorList: [],
      isLoading: false,
      isLoadingEvaluatorList: false,
      isLoadingUsers: false,
      mode: 0,
      options: ['User List', 'Access Management'],
      users: [],
    });
  }

  readonly setBusinessUnit = this.updater(
    (state, businessUnit: BusinessUnit) => {
      return {
        ...state,
        businessUnit,
      };
    }
  );

  readonly setIsLoadingUsers = this.updater(
    (state, isLoadingUsers: boolean) => ({
      ...state,
      error: undefined,
      isLoadingUsers,
    })
  );

  readonly setSolution = this.updater((state, solution: Solution) => {
    return {
      ...state,
      solution,
    };
  });

  readonly setUsers = this.updater((state, users: EmailedUser[]) => ({
    ...state,
    users,
  }));

  readonly setSolutionId = this.updater((state, solutionId: string) => ({
    ...state,
    solutionId,
  }));

  handleIndexChange(e: number): void {
    this.patchState({
      mode: e,
    });
  }

  getEvaluatorList = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.patchState({
          isLoadingEvaluatorList: true,
        });
      }),
      withLatestFrom(this.select(state => state)),
      map(([_, state]) => state),
      switchMap(({ solution }) => {
        return this.businessUnitService
          .getEvaluatorList(solution?.businessUnitOfSolution!)
          .then(querySnapshot => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach(doc => {
                const data = doc.data();

                if (data['nomBu'] === solution?.businessUnitOfSolution) {
                  this.patchState({
                    evaluatorList: data['evaluators'],
                  });
                }
              });
            }

            this.patchState({
              isLoadingEvaluatorList: false,
            });
          })
          .catch(error => {
            this.patchState({
              isLoadingEvaluatorList: false,
            });
          });
      })
    );
  });

  getUsers = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoadingUsers(true);
      }),
      switchMap(() => {
        return this.userService.observeVeoliaUsers().pipe(
          tap(data => {
            this.setUsers(data.map(user => UserMapper.toEmailedUser(user)));
            this.setIsLoadingUsers(false);
          })
        );
      })
    );
  });

  getSolution = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.patchState({
          isLoading: true,
        });
      }),
      withLatestFrom(this.select(state => state)),
      map(([, state]) => state),
      switchMap(({ solutionId }) => {
        return this.solutionService
          .get(solutionId!)
          .then(doc => {
            if (doc.exists()) {
              this.patchState({
                solution: { uid: solutionId, ...doc.data() } as Solution,
                isLoading: false,
              });
            }

            this.patchState({
              isLoading: false,
            });
          })
          .catch(error => {
            this.patchState({
              isLoading: false,
            });
          });
      }),
      tap(() => {
        this.getEvaluatorList();
      })
    );
  });

  // getBusinessUnit = () => {
  //   return this.get().solution?.businessUnitOfSolution;
  // };
}
