import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  getDatabase,
  ref,
  onValue,
  set,
  remove,
  push,
  Database,
  get,
  off,
} from '@angular/fire/database';

export type UserTab = {
  id: string;
  lastActive: number;
  shouldClose: boolean;
  timestamp: number;
};

export type TabDict = {
  [key: string]: UserTab;
};

@Injectable({
  providedIn: 'root',
})
export class TabService {
  private tabId?: string;
  private userId: string | null = null;
  private tabCountSubject = new BehaviorSubject<number>(0);
  private showConfirmSubject = new BehaviorSubject<boolean>(false);
  private shouldCloseSubject = new BehaviorSubject<boolean>(false);
  private db: Database;
  private activeTabListener: any;
  private isConfirmedActive = false;

  constructor(private zone: NgZone) {
    this.db = getDatabase();
  }

  initializeTabManager(userId: string, tabId: string) {
    this.userId = userId;
    this.tabId = tabId;
    this.cleanupOldTabs();
    this.addTab();
    this.listenForTabChanges();
  }

  private addTab() {
    const tabsRef = ref(this.db, `usersStatus/${this.userId}/tabs`);

    get(tabsRef).then(snapshot => {
      const tabs = snapshot.val();
      let tabExists = false;

      if (tabs) {
        Object.values(tabs).forEach((tab: any) => {
          if (tab.id === this.tabId) {
            tabExists = true;
          }
        });
      }

      if (!tabExists) {
        push(tabsRef, {
          id: this.tabId,
          timestamp: Date.now(),
          lastActive: Date.now(),
          shouldClose: false,
        });
      } else {
        this.updateLastActive();
      }
    });
  }

  private stopListeningForActiveTabChanges() {
    if (this.activeTabListener) {
      const activeTabRef = ref(this.db, `usersStatus/${this.userId}/activeTab`);
      off(activeTabRef, 'value', this.activeTabListener);
      this.activeTabListener = null;
    }
  }

  private listenForTabChanges() {
    const tabsRef = ref(this.db, `usersStatus/${this.userId}/tabs`);
    onValue(tabsRef, snapshot => {
      const tabs = snapshot.val();

      const tabCount = tabs ? Object.keys(tabs).length : 0;
      this.tabCountSubject.next(tabCount);

      if (tabCount > 1 && !this.isConfirmedActive) {
        this.showConfirmModal();
      } else if (tabCount === 1) {
        Object.entries(tabs).forEach(([_key, value]: [string, any]) => {
          set(ref(this.db, `usersStatus/${this.userId}/activeTab`), value.id);
        });
        this.stopListeningForActiveTabChanges();
        this.isConfirmedActive = false;
      }

      // Check if this tab should close
      if (tabs) {
        Object.entries(tabs).forEach(([key, value]: [string, any]) => {
          if (value.id === this.tabId && value.shouldClose) {
            this.shouldCloseSubject.next(true);
            // Reset the shouldClose flag
            set(
              ref(
                this.db,
                `usersStatus/${this.userId}/tabs/${key}/shouldClose`
              ),
              false
            );
          }
        });
      }
    });

    // Update lastActive every 5 minutes
    setInterval(() => this.updateLastActive(), 300000);
  }

  setActiveTab() {
    this.isConfirmedActive = true;
    const activeTabRef = ref(this.db, `usersStatus/${this.userId}/activeTab`);
    set(activeTabRef, this.tabId).then(() => {
      const tabsRef = ref(this.db, `usersStatus/${this.userId}/tabs`);
      get(tabsRef).then(snapshot => {
        const tabs = snapshot.val();
        if (tabs) {
          Object.entries(tabs).forEach(([key, value]: [string, any]) => {
            if (value.id === this.tabId) {
              set(
                ref(
                  this.db,
                  `usersStatus/${this.userId}/tabs/${key}/shouldClose`
                ),
                false
              );
            } else {
              set(
                ref(
                  this.db,
                  `usersStatus/${this.userId}/tabs/${key}/shouldClose`
                ),
                true
              );
            }
          });
        }
      });
    });

    this.showConfirmSubject.next(false);
  }

  private updateLastActive() {
    if (this.userId && this.tabId) {
      const tabsRef = ref(this.db, `usersStatus/${this.userId}/tabs`);
      get(tabsRef).then(snapshot => {
        const tabs = snapshot.val();
        if (tabs) {
          Object.entries(tabs).forEach(([key, value]: [string, any]) => {
            if (value.id === this.tabId) {
              set(
                ref(
                  this.db,
                  `usersStatus/${this.userId}/tabs/${key}/lastActive`
                ),
                Date.now()
              );
            }
          });
        }
      });
    }
  }

  getTabCount(): Observable<number> {
    return this.tabCountSubject.asObservable();
  }

  getShowConfirm(): Observable<boolean> {
    return this.showConfirmSubject.asObservable();
  }

  getShouldClose(): Observable<boolean> {
    return this.shouldCloseSubject.asObservable();
  }

  closeTab() {
    const tabsRef = ref(this.db, `usersStatus/${this.userId}/tabs`);
    get(tabsRef).then(snapshot => {
      const tabs = snapshot.val();
      if (tabs) {
        Object.entries(tabs).forEach(([key, value]: [string, any]) => {
          if (value.id === this.tabId) {
            remove(ref(this.db, `usersStatus/${this.userId}/tabs/${key}`));
          }
        });
      }
    });

    this.stopListeningForActiveTabChanges();
  }

  private showConfirmModal() {
    this.zone.run(() => {
      this.showConfirmSubject.next(true);
    });
  }

  private cleanupOldTabs() {
    if (this.userId) {
      const tabsRef = ref(this.db, `usersStatus/${this.userId}/tabs`);
      get(tabsRef).then(snapshot => {
        const tabs = snapshot.val();
        if (tabs) {
          const currentTime = Date.now();
          Object.entries(tabs).forEach(([key, value]: [string, any]) => {
            if (currentTime - value.lastActive > 330000) {
              // 5 minutes 30 secondes
              remove(ref(this.db, `usersStatus/${this.userId}/tabs/${key}`));
            }
          });
        }
      });
    }
  }

  isActiveTab(): Promise<boolean> {
    return new Promise(resolve => {
      const activeTabRef = ref(this.db, `usersStatus/${this.userId}/activeTab`);
      get(activeTabRef).then(snapshot => {
        resolve(snapshot.val() === this.tabId);
      });
    });
  }

  closeTabOnDestroy() {
    if (this.userId && this.tabId) {
      const tabsRef = ref(this.db, `usersStatus/${this.userId}/tabs`);
      get(tabsRef).then(snapshot => {
        const tabs = snapshot.val();
        if (tabs) {
          Object.entries(tabs).forEach(([key, value]: [string, any]) => {
            if (value.id === this.tabId) {
              remove(ref(this.db, `usersStatus/${this.userId}/tabs/${key}`));
            }
          });
        }
      });

      // Stop listening for changes
      this.stopListeningForActiveTabChanges();

      // Reset service state
      this.tabId = undefined;
      this.userId = null;
      this.isConfirmedActive = false;
      this.tabCountSubject.next(0);
      this.showConfirmSubject.next(false);
      this.shouldCloseSubject.next(false);
    }
  }
}
