import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import SolutionApi from 'src/app/core/apis/solution.api';
import { Solution } from 'src/app/core/models/solution';

@Component({
  selector: 'app-access-right-button',
  templateUrl: './access-right-button.component.html',
  styleUrls: ['./access-right-button.component.scss'],
})
export class AccessRightButtonComponent implements OnInit {
  @Input() solution: Solution | undefined;
  @Input() email: string | undefined;
  @Input() form: string | undefined;
  @Input() value?: boolean;
  @Input() disabled?: boolean;
  @Output() changed: EventEmitter<void> = new EventEmitter<void>();

  access = 0;
  isLoading = false;

  permissions = [
    { label: '', value: 'List', icon: 'read' },
    { label: '', value: 'Kanban', icon: 'edit' },
  ];

  constructor(
    private veoliaMessageService: VeoliaMessageService,
    private solutionApi: SolutionApi
  ) {}

  ngOnInit() {
    switch (this.form) {
      case 'legal':
        this.access = this.solution?.emailVendorReadonlyLegal?.includes(
          this.email!
        )
          ? 0
          : 1;
        break;
      case 'design':
        this.access = this.solution?.emailVendorReadonlyDesign?.includes(
          this.email!
        )
          ? 0
          : 1;
        break;
      case 'art28':
        this.access = this.solution?.emailVendorReadonlyArt28?.includes(
          this.email!
        )
          ? 0
          : 1;
        break;
      case 'art32':
        this.access = this.solution?.emailVendorReadonlyArt32?.includes(
          this.email!
        )
          ? 0
          : 1;
        break;
    }
  }

  handlePermissionChange(e: number): void {
    this.isLoading = true;
    this.solutionApi
      .updateAccessRight(this.solution!.uid, this.email!, this.form!, e)
      .subscribe({
        next: response => {
          this.access = e;
          this.isLoading = false;
          this.veoliaMessageService.create(
            {
              title: 'Updated !',
              icon: 'check',
              content: response.message,
            },
            {
              duration: 10000,
            }
          );
          this.changed.emit();
        },
        error: error => {
          this.isLoading = false;
          this.veoliaMessageService.create(
            {
              title: 'Error',
              icon: 'error',
              content: error.message,
            },
            {
              duration: 10000,
            }
          );
        },
      });
  }
}
