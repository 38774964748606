<!-- <nz-modal [nzWidth]="350" [nzVisible]="isVisible" nzTitle="Sign In" (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleOk()" [nzOkLoading]="isOkLoading" [nzClassName]="'login-modal'"> -->
<!-- <div id="login-form" *nzModalContent> -->
<form
  nz-form
  [formGroup]="validateForm"
  class="login-form"
  (ngSubmit)="submitForm()">
  <nz-form-item>
    <nz-form-control nzErrorTip="Please input your username!">
      <nz-input-group nzPrefixIcon="user" class="light-gray">
        <input
          class="light-gray"
          type="text"
          nz-input
          formControlName="userName"
          placeholder="Username" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-control nzErrorTip="Please input your Password!">
      <nz-input-group nzPrefixIcon="lock" class="light-gray">
        <input
          class="light-gray"
          type="password"
          nz-input
          formControlName="password"
          placeholder="Password" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <div nz-row class="login-form-margin">
    <div nz-col [nzSpan]="12">
      <a (click)="launchResetPassword()" class="login-form-forgot"
        >Forgot password</a
      >
    </div>
  </div>
  <veolia-divider [variant]="'light'" [orientation]="'horizontal'" />
  <div class="row row-right">
    <div>
      <veolia-button
        [size]="'default'"
        [variant]="'secondary'"
        [htmlType]="'reset'">
        Cancel
      </veolia-button>
    </div>
    <div>
      <veolia-button
        [size]="'default'"
        [variant]="'primary'"
        [htmlType]="'submit'">
        Log In
      </veolia-button>
    </div>
  </div>
</form>
<!-- </div>
</nz-modal> -->
<ng-template #forgotPassword>
  <app-reset-form></app-reset-form>
</ng-template>
