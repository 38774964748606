<veolia-modal #modal>
  <div veolia-modal-title>
    {{ 'Comments of' | translate }}
    <strong>{{ solution?.solutionName }} - {{ formName }} </strong>
  </div>
  <div veolia-modal-content>
    <veolia-button
      *ngIf="comments"
      size="default"
      variant="primary"
      htmlType="button"
      (click)="generatePDF()">
      Download PDF
    </veolia-button>
    <br />
    <div>
      <nz-collapse>
        <nz-collapse-panel
          (nzActiveChange)="opened = i"
          *ngFor="let field of fields(); let i = index"
          [nzActive]="i === opened"
          [nzHeader]="field | translate">
          <p style="margin: 0">
            <nz-list>
              <nz-list-item *ngFor="let item of comments![field]!">
                <ng-template #commentAuthor>
                  <strong class="black">{{ item.postedBy }}</strong>
                  <span *ngIf="item.taggedUser">
                    to
                    <strong class="blue-700"
                      >{{ item.taggedUser }}
                    </strong></span
                  >
                </ng-template>
                <ng-template #commentDate>
                  <span class="blue-700">{{ item.postedAt.toString() }}</span>
                </ng-template>
                <nz-comment
                  [nzAuthor]="commentAuthor"
                  [nzDatetime]="commentDate">
                  <nz-avatar
                    nz-comment-avatar
                    nzIcon="user"
                    [nzSrc]=""></nz-avatar>
                  <nz-comment-content>
                    <p>{{ item.content }}</p>
                  </nz-comment-content>
                </nz-comment>
              </nz-list-item>
              <nz-list-empty
                *ngIf="comments![field]!.length === 0"></nz-list-empty>
            </nz-list>
          </p>
        </nz-collapse-panel>
      </nz-collapse>
      <nz-list-empty
        nzNoResult="No comment"
        *ngIf="fields()!.length === 0"></nz-list-empty>
    </div>
  </div>
</veolia-modal>
