<app-layout-page-header>
  <veolia-page-header variant="light">
    <div veolia-page-header-title style="display: flex">
      <veolia-icon
        class="backbtn"
        (click)="backtoAdminHome()"
        title="Back to Admin Home"
        [size]="24"
        [name]="'arrow_back'"></veolia-icon>
      Business Units administration
    </div>

    <div veolia-page-header-bottom>
      <div class="right-actions">
        <app-form-caption></app-form-caption>
        <app-documentation></app-documentation>
      </div>
      <div>
        <veolia-button
          [size]="'default'"
          [variant]="'primary'"
          [icon]="'add'"
          [iconFilled]="true"
          [iconPosition]="'left'"
          (click)="modal.open()">
          New Business Unit
        </veolia-button>
      </div>
    </div>
  </veolia-page-header>
</app-layout-page-header>
<div class="layout-body">
  <div class="layout-body-content">
    <ng-container *ngIf="vm$ | async as vm">
      <nz-alert
        *ngIf="vm.error"
        nzType="error"
        [nzMessage]="vm.error"
        nzShowIcon></nz-alert>
      <div>
        <div class="row row-right row-bottom">
          <search-input
            [isSearching]="vm.isSearching"
            (onChange)="onSearch($event)"></search-input>
        </div>
        <simple-spinner
          style="text-align: center !important"
          *ngIf="vm.isLoading"></simple-spinner>
        <veolia-table
          [hideSelection]="true"
          [datasource]="vm.businessUnits"
          size="small"
          [showPagination]="false">
          <veolia-table-column>
            <veolia-table-header>Uid</veolia-table-header>
            <veolia-table-cell>
              <ng-template let-row>{{ row.uid }}</ng-template>
            </veolia-table-cell>
          </veolia-table-column>

          <veolia-table-column>
            <veolia-table-header>Name</veolia-table-header>
            <veolia-table-cell>
              <ng-template let-row>{{ row.nomBu }}</ng-template>
            </veolia-table-cell>
          </veolia-table-column>

          <veolia-table-column>
            <veolia-table-header>Evaluators count</veolia-table-header>
            <veolia-table-cell>
              <ng-template let-row>
                {{ row.evaluators ? row.evaluators.length : '0' }}
              </ng-template>
            </veolia-table-cell>
          </veolia-table-column>

          <veolia-table-column fixedRight [width]="34">
            <veolia-table-header></veolia-table-header>
            <veolia-table-cell [withoutBackground]="true">
              <ng-template let-row>
                <a nz-dropdown [nzDropdownMenu]="menu">
                  <span nz-icon nzType="more"></span>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu nzSelectable>
                    <li nz-menu-item>
                      <veolia-link
                        size="small"
                        icon="domain_add"
                        (click)="onEdit(row.uid)">
                        Edit
                      </veolia-link>
                    </li>
                  </ul>
                </nz-dropdown-menu>
              </ng-template>
            </veolia-table-cell>
          </veolia-table-column>
        </veolia-table>
      </div>
      <app-pagination
        *ngIf="!vm.isSearching || vm.search === ''"
        [loading]="vm.isLoading || vm.isSearching"
        [size]="vm.limit"
        [page]="vm.currentPage"
        [total]="vm.total"
        (onPageChange)="changePage($event)"
        (onSizeChange)="changeSize($event)"></app-pagination>
    </ng-container>
  </div>
</div>

<veolia-modal #modal [closable]="true">
  <div veolia-modal-title>Edit Business Unit form</div>
  <div veolia-modal-content>
    <div class="content">
      <div class="column column-space-between">
        <span>ID: {{ businessUnitUid }}</span>
        <br />
        <div>
          <form
            nz-form
            [formGroup]="form"
            (ngSubmit)="onSubmit()"
            [nzLayout]="'vertical'">
            <div class="row row-space-between">
              <div class="column">
                <nz-form-item>
                  <nz-form-label>Name</nz-form-label>
                  <nz-form-control nzErrorTip="Please input the name!">
                    <input
                      nz-input
                      class="light-gray"
                      formControlName="nomBu" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label>Zone</nz-form-label>
                  <nz-form-control nzErrorTip="Please input the zone!">
                    <input
                      nz-input
                      class="light-gray"
                      formControlName="nomZone" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label>Delegated zone</nz-form-label>
                  <nz-form-control
                    nzErrorTip="Please input the email of the Business Unit!">
                    <input
                      nz-input
                      class="light-gray"
                      formControlName="delegatedZone" />
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-label>Country</nz-form-label>
                  <nz-form-control
                    nzErrorTip="Please input the country of the Business Unit!">
                    <input
                      nz-input
                      class="light-gray"
                      formControlName="country" />
                  </nz-form-control>
                </nz-form-item>
                <ng-container *ngIf="vm$ | async as vm">
                  <nz-form-item>
                    <nz-form-label>Evaluators</nz-form-label>
                    <nz-form-control>
                      <nz-select
                        nzMode="multiple"
                        nzPlaceHolder="Select a veolia user"
                        nzAllowClear
                        (nzOnSearch)="onSearch($event)"
                        class="light-gray full-width"
                        formControlName="evaluators">
                        <ng-container *ngFor="let u of vm.users">
                          <nz-option
                            [nzValue]="u.email"
                            [nzLabel]="u.name"></nz-option>
                        </ng-container>
                        <nz-option
                          *ngIf="vm.isLoadingUsers"
                          nzDisabled
                          nzCustomContent>
                          <span
                            nz-icon
                            nzType="loading"
                            class="loading-icon"></span>
                          Loading Data...
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </ng-container>
                <nz-form-item>
                  <nz-form-label>Email of Business Unit</nz-form-label>
                  <nz-form-control
                    nzErrorTip="Please input the email of the Business Unit!">
                    <input
                      nz-input
                      class="light-gray"
                      formControlName="emailDLABU" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div veolia-modal-footer>
    <ng-container *ngIf="vm$ | async as vm">
      <veolia-button
        *ngIf="!vm.isLoadingUsers"
        variant="secondary"
        htmlType="reset"
        (click)="reset()">
        Reset
      </veolia-button>
      <veolia-button nz-button variant="primary" (click)="onSubmit()">
        Validate
      </veolia-button>
      <simple-spinner *ngIf="vm.isLoading"></simple-spinner>
    </ng-container>
  </div>
</veolia-modal>
