import { Injectable } from '@angular/core';
import { EntityService } from './entity.service';
import { SecurityScoreCard } from '../models/security-score-card';
import { Entity, Insertable, Updateable, Updates } from '../models/bases';
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from '@firebase/firestore';
import { updateDoc } from '@angular/fire/firestore';

@Injectable()
export class SecurityScoreCardService extends EntityService<SecurityScoreCard> {
  override get collectionName(): string {
    return 'securityScoreCard';
  }

  override async create(
    entity:
      | Insertable<SecurityScoreCard>
      | Updates<SecurityScoreCard>
      | Updateable<SecurityScoreCard>,
    id?: Entity['uid']
  ) {
    if (id) {
      return await setDoc(doc(this._firestore, this.collectionName, id), {
        createdAt: serverTimestamp(),
        firstUpdate: serverTimestamp(),
        ...entity,
        firstUpdateBy: this._auth.currentUser?.email,
      });
    } else {
      return await addDoc(collection(this._firestore, this.collectionName), {
        createdAt: serverTimestamp(),
        firstUpdate: serverTimestamp(),
        ...entity,
        firstUpdateBy: this._auth.currentUser?.email,
      });
    }
  }

  override async update(
    id: Entity['uid'],
    updates: Updates<SecurityScoreCard> | Updateable<SecurityScoreCard>
  ) {
    return await updateDoc(doc(this._firestore, this.collectionName, id), {
      ...updates,
      lastUpdate: serverTimestamp(),
      lastUpdateBy: this._auth.currentUser?.email,
    });
  }
}
