import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

import { OwnedSolutionsStore } from './owned-solutions.store';
import { AuthService } from '../../../../core/services/auth.service';
import { Router } from '@angular/router';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';
import { TranslateService } from '@ngx-translate/core';
import { Timestamp } from '@angular/fire/firestore';
import { formatDate } from 'src/app/core/utils/date';
import { getTagColor } from 'src/app/core/utils/colors';
import { SolutionService } from 'src/app/core/services/solution.service';
import { FollowedSolutionsStore } from '../followed-solutions/followed-solutions.store';
import { EvaluatorSolutionsStore } from '../evaluator-solutions/evaluator-solutions.store';

@Component({
  selector: 'app-home-page-owned-solutions',
  templateUrl: './owned-solutions.component.html',
  styleUrls: ['./owned-solutions.component.scss'],
  providers: [
    OwnedSolutionsStore,
    FollowedSolutionsStore,
    EvaluatorSolutionsStore,
  ],
})
export class OwnedSolutionsComponent
  extends DataTableComponent
  implements OnInit, OnChanges
{
  @Input() solutionType = 0;

  vm$ = this.ownedSolutionsStore.vm$;

  constructor(
    public override authService: AuthService,
    public override router: Router,
    public override translateService: TranslateService,
    public ownedSolutionsStore: OwnedSolutionsStore,
    public followedSolutionsStore: FollowedSolutionsStore,
    public evaluatorSolutionsStore: EvaluatorSolutionsStore,
    public override solutionService: SolutionService
  ) {
    super(authService, router, translateService, solutionService);
  }

  ngOnInit(): void {
    this.authService.userData.subscribe(user => {
      if (user) {
        this.ownedSolutionsStore.setUid(user?.uid);
        this.ownedSolutionsStore.setSolutionType(this.solutionType);
        this.ownedSolutionsStore.getCount();
        this.ownedSolutionsStore.getSolutions();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['solutionType']['firstChange']) {
      this.ownedSolutionsStore.changeSolutionType(
        changes['solutionType'].currentValue
      );
    }
  }

  changePage(page: number) {
    this.ownedSolutionsStore.changePage(page);
  }

  changeSize(size: Event) {
    const limit = parseInt((size.target as HTMLSelectElement).value);
    this.ownedSolutionsStore.changeLimit(limit);
  }

  onSearch(event: string) {
    this.ownedSolutionsStore.changeSearch(event);
  }

  getTagColor(status: string) {
    return getTagColor(status);
  }

  getFormattedDate(date: string | Timestamp) {
    return formatDate(date);
  }

  onRefreshAfterEmit() {
    this.ownedSolutionsStore.refreshSolutions();
    //this.followedSolutionsStore.refreshSolutions();
    //this.evaluatorSolutionsStore.refreshSolutions();
  }
}
