<app-layout-page-header>
  <div class="row row-space-between row-bottom">
    <div veolia-page-header-title class="title">
      <app-go-back />
      New DLA request
    </div>
  </div>
</app-layout-page-header>
<div class="layout-body">
  <div class="layout-body-content">
    <form
      nz-form
      [nzLayout]="'vertical'"
      [formGroup]="form"
      (ngSubmit)="submit()">
      <formly-form
        [model]="model"
        [fields]="fields"
        [options]="options"
        [form]="form">
        <ng-template formlyTemplate="text-zone" let-field>
          <div class="spacing-v8">
            <veolia-alert
              *ngIf="field.expressions.hide"
              [description]="field.props.text | translate"
              [message]="'Important'"
              [status]="'error'" />
          </div>
        </ng-template>
        <ng-template formlyTemplate="iaas-info" let-field>
          <div class="spacing-v16 row">
            <a
              class="row"
              href="https://oneintranet.veolia.com/corp-ist-norms/ls/content/3975734231102957/security/design-legal-assessment-copy"
              target="_blank">
              <veolia-icon [size]="24" [name]="'info'"></veolia-icon>
              For IaaS & PaaS DLA requests, please check DLA for XaaS e-Norms
            </a>
          </div>
        </ng-template>
        <ng-template formlyTemplate="divider" let-field>
          <nz-divider></nz-divider>
        </ng-template>
      </formly-form>
    </form>
  </div>
</div>
