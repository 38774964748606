import { Injectable, inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import {
  Database,
  getDatabase,
  ref,
  onValue,
  push,
  child,
  set,
} from '@angular/fire/database';

@Injectable()
export class LogService {
  solutionId?: string;
  formName?: string;
  logName?: string;

  public _auth = inject(Auth);

  constructor(public __database: Database = getDatabase()) {}

  getFormLogs(solutionId: string, formName: string, actionName: string) {
    return new Promise((resolve, reject) => {
      const logsRef = ref(
        this.__database,
        `logs/${formName}/${solutionId}/${actionName}`
      );
      onValue(logsRef, snapshot => {
        if (!snapshot.exists()) {
          reject('No logs found');
        }

        const data = snapshot.val();
        resolve(data);
      });
    });
  }

  getCurrentDate() {
    const today = new Date(Date.now());
    const dd = today.getDate();
    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    return dd + '-' + mm + '-' + yyyy;
  }

  buildPath(solutionId: string, formName: string, logName: string) {
    this.solutionId = solutionId;
    this.formName = formName;
    this.logName = logName;
    return `logs/${formName}/${solutionId}/${logName}/${this.getCurrentDate()}`;
  }

  getIdRefLogs() {
    return this.solutionId + '-' + this.formName + '-' + this.getCurrentDate();
  }

  getNewKey(path: string) {
    return push(child(ref(this.__database), path)).key;
  }

  setLogForAction(
    actionName: string,
    solutionId: string,
    formName: string,
    data: any
  ) {
    const path = this.buildPath(solutionId, formName, actionName);
    const newLogKey = this.getNewKey(path);
    return set(ref(this.__database, `${path}/${newLogKey}`), {
      idRefLogs: this.getIdRefLogs(),
      ...data,
      dateTime: new Date(Date.now()).toString(),
      userEmail: this._auth.currentUser?.email,
      userId: this._auth.currentUser?.uid,
    });
  }

  setLogForConnection(solutionId: string, formName: string, data: any) {
    const path = this.buildPath(solutionId, formName, 'Connection');
    const newConnectionKey = this.getNewKey(path);
    return set(ref(this.__database, `${path}/${newConnectionKey}`), data);
  }

  setLogForSaveForm(solutionId: string, formName: string, data: any) {
    const path = this.buildPath(solutionId, formName, 'SaveForm');
    const newSaveFormKey = this.getNewKey(path);
    return set(ref(this.__database, `${path}/${newSaveFormKey}`), data);
  }

  setLogForSaveCompleteForm(solutionId: string, formName: string, data: any) {
    const path = this.buildPath(solutionId, formName, 'SaveCompleteForm');
    const newSaveFormKey = this.getNewKey(path);
    return set(ref(this.__database, `${path}/${newSaveFormKey}`), data);
  }

  setLogForFile(solutionId: string, formName: string, data: any) {
    const path = this.buildPath(solutionId, formName, 'File');
    const newSaveFormKey = this.getNewKey(path);
    return set(ref(this.__database, `${path}/${newSaveFormKey}`), data);
  }

  setLogChapSubChapCompletion(solutionId: string, formName: string, data: any) {
    const path = this.buildPath(solutionId, formName, 'File');
    const newChapSubChapKey = this.getNewKey(path);
    return set(ref(this.__database, `${path}/${newChapSubChapKey}`), data);
  }

  setLogAcunetix(solutionId: string, formName: string, data: any) {
    const path = this.buildPath(solutionId, formName, 'Acunetix');
    const newAcunetixKey = this.getNewKey(path);
    return set(ref(this.__database, `${path}/${newAcunetixKey}`), data);
  }
}
