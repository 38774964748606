import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { DesignForm } from 'src/app/core/models/design-form';
import { LegalForm } from 'src/app/core/models/legal-form';
import { Reservation } from 'src/app/core/models/reservation';
import { Solution } from 'src/app/core/models/solution';
import { AuthService } from 'src/app/core/services/auth.service';
import { getTagColor } from 'src/app/core/utils/colors';

@Component({
  selector: 'app-form-summary-panel',
  templateUrl: 'form-summary-panel.component.html',
  styleUrls: ['form-summary-panel.component.scss'],
})
export class FormSummaryPanelComponent {
  @Input() solutionId?: string;
  @Input() solution?: Solution;
  @Input() businessUnit?: BusinessUnit;
  @Input() formName?: string;
  @Input() formStatus?: string;
  @Input() form?: Article28Form | Article32Form | DesignForm | LegalForm;
  @Input() reservations?: Reservation[];
  @Input() title?: string;
  @Output() onOpenOverview: EventEmitter<string> = new EventEmitter<string>();
  @Output() onOpenEvaluation: EventEmitter<string> = new EventEmitter<string>();
  @Output() onOpenReservation: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(
    private router: Router,
    private authService: AuthService,
    private auth: Auth
  ) {}

  getFormStatusTheme(status: string) {
    return getTagColor(status);
  }

  get fixedCount() {
    return this.reservations?.filter(reservation => reservation.fixed === true)
      .length;
  }

  get notFixedCount() {
    return this.reservations?.filter(reservation => reservation.fixed === false)
      .length;
  }

  launchForm() {
    let formName = undefined;

    if (this.formName === 'Design Form') {
      formName = 'designForm';
    }

    if (this.formName === 'Legal Form') {
      formName = 'legalForm';
    }

    if (this.formName === 'Article 32 Form') {
      formName = 'article32Form';
    }

    if (this.formName === 'Additional Forms') {
      formName = 'article28Form';
    }

    if (formName) {
      this.router.navigate([`/${formName}/${this.solutionId}`]);
    }
  }

  get userIsSoOfSolution() {
    if (this.solution?.solutionOwner === this.auth.currentUser?.email)
      return true;
    else return false;
  }

  get userIsVeoliaAuthorized() {
    if (
      !this.solution?.emailVeoliaAuthorized?.includes(
        this.auth.currentUser!.email!
      )
    ) {
      return false;
    } else {
      return true;
    }
  }

  get userIsVendorAuthorized() {
    if (
      !this.solution?.emailVendorAuthorized?.includes(
        this.auth.currentUser!.email!
      )
    ) {
      return false;
    } else {
      return true;
    }
  }

  get isBusinessUnitEvaluator() {
    if (
      !this.businessUnit?.evaluators?.includes(this.auth.currentUser!.email!)
    ) {
      return false;
    } else {
      return true;
    }
  }

  get isSolutionEvaluator() {
    return this.solution?.emailEvaluatorAuthorized.includes(
      this.auth.currentUser!.email!
    );
  }

  get hasFullRights() {
    return (
      this.authService.roles?.admin ||
      (this.authService.roles?.evaluator && this.isBusinessUnitEvaluator) ||
      this.isSolutionEvaluator ||
      this.userIsSoOfSolution
    );
  }
}
