import { Component } from '@angular/core';
import { BusinessUnitEvaluatorPageStore } from './business-unit-evaluator-page.store';
import { SolutionService } from '../../../../core/services/solution.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-business-unit-evaluator-page',
  templateUrl: './business-unit-evaluator-page.component.html',
  styleUrls: ['./business-unit-evaluator-page.component.scss'],
  providers: [SolutionService, BusinessUnitEvaluatorPageStore],
})
export class BusinessUnitEvaluatorPageComponent {
  vm$ = this.businessUnitEvaluatorStore.vm$;

  constructor(
    private authService: AuthService,
    private businessUnitEvaluatorStore: BusinessUnitEvaluatorPageStore,
    private router: Router
  ) {}

  onSolutionTypeChange(type: number) {
    this.businessUnitEvaluatorStore.setSolutionType(type);
  }
}
