import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { AllUsersStore } from './all-users.component.store';
import { AuthService } from '../../../../../core/services/auth.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-admin-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss'],
  providers: [AllUsersStore],
})
export class AllUsersComponent implements OnInit, OnChanges {
  @Input() userType = 0;

  vm$ = this.allUsersStore.vm$;

  constructor(
    private authService: AuthService,
    private viewContainerRef: ViewContainerRef,
    private modalService: NzModalService,
    private allUsersStore: AllUsersStore
  ) {}

  ngOnInit(): void {
    this.authService.userData.subscribe(user => {
      if (user) {
        this.allUsersStore.setUid(user.uid);
        this.allUsersStore.setUserType(this.userType);
        this.allUsersStore.getCount();
        this.allUsersStore.getUsers();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['userType']['firstChange']) {
      this.allUsersStore.changeUserType(changes['userType'].currentValue);
    }
  }

  changePage(page: number) {
    this.allUsersStore.changePage(page);
  }

  changeSize(size: Event) {
    const limit = parseInt((size.target as HTMLSelectElement).value);
    this.allUsersStore.changeLimit(limit);
  }

  onSearch(event: string) {
    this.allUsersStore.changeSearch(event);
  }

  onEdit(event: string) {
    console.log(event);
  }

  onRefreshAfterEmit() {
    this.allUsersStore.refreshUsers();
  }
}
