import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function noGenericEmailValidator(
  control: AbstractControl
): ValidationErrors | null {
  return /((.*)(?<!veolia.*)$)((.*)(?<!gmail.*)$)((.*)(?<!outlook.*)$)((.*)(?<!yahoo.*)$)((.*)(?<!hotmail.*)$)((.*)(?<!ymail.*)$)((.*)(?<!free.com)$)((.*)(?<!free.fr)$)/.test(
    control.value
  )
    ? null
    : { 'no-generic-email': true };
}

export function noGenericEmailValidatorMessage(err: any, field: any) {
  return 'Generic email address such as info@... contact@... and personal email address (gmail, outlook, yahoo, hotmail, ymail, yopmail, free...) are not authorized';
}

export function noBlockedWordsInEmailValidator(
  control: AbstractControl
): ValidationErrors | null {
  return /^(?!.*contact.*@)(?!.*info.*@)(?!.*no-reply.*@)(?!.*noreply.*@).*$/.test(
    control.value
  )
    ? null
    : { 'no-blocked-word': true };
}

export function noBlockedWordsInEmailValidatorMessage(err: any, field: any) {
  return 'Email address can not start with contact, info, no-reply';
}
