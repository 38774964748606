<div *ngIf="vm$ | async as vm">
  <app-simple-spinner-placeholder *ngIf="!vm.ready" />
  <app-form-toolbar
    *ngIf="vm.ready"
    [comments]="vm.comments"
    [fileList]="vm.fileList"
    [files]="vm.files"
    [evaluation]="vm.evaluation"
    [currentForm]="vm.form"
    [formName]="formName"
    [formCompletionStatus]="formCompletionStatus"
    [formStatus]="formStatus"
    [solution]="solution"
    [businessUnit]="businessUnit"
    (saveAll)="saveAll()"
    (markAsComplete)="markAsComplete(formCompletionStatus)"
    (refreshForm)="refreshForm()"
    [isDirty]="isDirty"
    [isSaving]="isSaving" />
  <div nz-row [nzGutter]="16">
    <div
      *ngIf="vm.ready"
      nz-col
      class="gutter-row"
      [nzSpan]="5"
      [nzLg]="6"
      [nzMd]="6"
      [nzSm]="24"
      [nzXs]="24">
      <div class="rounded-white spacing-b16">
        <h4 nz-typography>Table of content</h4>
        <div>
          <app-form-block-button
            [showId]="false"
            id="1"
            [title]="'AR32_00' | translate"
            (click)="selectChapter('AR32_00')"
            [selected]="selectedChapter === 'AR32_00'"
            [unlocked]="true" />
          <app-form-block-button
            [showId]="false"
            id="2"
            [title]="'AR32_0' | translate"
            (click)="selectChapter('AR32_0')"
            [selected]="selectedChapter === 'AR32_0'"
            [unlocked]="true"
            [evaluation]="vm.evaluation"
            evaluationField="AR32_0_RADIO" />
          <app-form-block-button
            [showId]="false"
            id="3"
            [title]="'AR32_1' | translate"
            (click)="selectChapter('AR32_1')"
            [selected]="selectedChapter === 'AR32_1'"
            [unlocked]="true"
            [evaluation]="vm.evaluation"
            evaluationField="AR32_1_RADIO" />
          <app-form-block-button
            [showId]="false"
            id="4"
            [title]="'AR32_2' | translate"
            (click)="selectChapter('AR32_2')"
            [selected]="selectedChapter === 'AR32_2'"
            [unlocked]="true"
            [evaluation]="vm.evaluation"
            evaluationField="AR32_2_RADIO" />
          <app-form-block-button
            [showId]="false"
            id="5"
            [title]="'AR32_3' | translate"
            (click)="selectChapter('AR32_3')"
            [selected]="selectedChapter === 'AR32_3'"
            [unlocked]="true"
            [evaluation]="vm.evaluation"
            evaluationField="AR32_3_RADIO" />
          <app-form-block-button
            [showId]="false"
            id="6"
            [title]="'AR32_4' | translate"
            (click)="selectChapter('AR32_4')"
            [selected]="selectedChapter === 'AR32_4'"
            [unlocked]="true"
            [evaluation]="vm.evaluation"
            evaluationField="AR32_4_RADIO" />
          <app-form-block-button
            [showId]="false"
            id="7"
            [title]="'AR32_5' | translate"
            (click)="selectChapter('AR32_5')"
            [selected]="selectedChapter === 'AR32_5'"
            [unlocked]="true"
            [evaluation]="vm.evaluation"
            evaluationField="AR32_5_RADIO" />
          <app-form-block-button
            [showId]="false"
            id="8"
            [title]="'AR32_6' | translate"
            (click)="selectChapter('AR32_6')"
            [selected]="selectedChapter === 'AR32_6'"
            [unlocked]="true"
            [evaluation]="vm.evaluation"
            evaluationField="AR32_6_RADIO" />
          <app-form-block-button
            [showId]="false"
            id="9"
            [title]="'AR32_7' | translate"
            (click)="selectChapter('AR32_7')"
            [selected]="selectedChapter === 'AR32_7'"
            [unlocked]="true"
            [evaluation]="vm.evaluation"
            evaluationField="AR32_7_RADIO" />
          <app-form-block-button
            [showId]="false"
            id="10"
            [title]="'AR32_8' | translate"
            (click)="selectChapter('AR32_8')"
            [selected]="selectedChapter === 'AR32_8'"
            [unlocked]="true"
            [evaluation]="vm.evaluation"
            evaluationField="AR32_8_RADIO" />
        </div>
      </div>
    </div>
    <div
      *ngIf="vm.ready"
      nz-col
      class="gutter-row chapters"
      [nzLg]="18"
      [nzMd]="18"
      [nzSm]="24"
      [nzXs]="24">
      <app-form-chapter
        *ngIf="fields['AR32_00']?.fields"
        [assessable]="fields['AR32_00']['assessable']!"
        [evaluation]="fields['AR32_00']['evaluation']!"
        [chapterId]="'AR32_00'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Article 32 Form"
        [formModel]="vm.form"
        [formFields]="fields['AR32_00'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('AR32_00')"
        (formEdited)="formEdited($event)" />
      <app-form-chapter
        *ngIf="fields['AR32_0']?.fields"
        [evaluation]="fields['AR32_0']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'AR32_0'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Article 32 Form"
        [formModel]="vm.form"
        [formFields]="fields['AR32_0'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('AR32_0')"
        (formEdited)="formEdited($event)" />
      <app-form-chapter
        *ngIf="fields['AR32_1']?.fields"
        [evaluation]="fields['AR32_1']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'AR32_1'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Article 32 Form"
        [formModel]="vm.form"
        [formFields]="fields['AR32_1'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('AR32_1')"
        (formEdited)="formEdited($event)" />
      <app-form-chapter
        *ngIf="fields['AR32_2']?.fields"
        [evaluation]="fields['AR32_2']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'AR32_2'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Article 32 Form"
        [formModel]="vm.form"
        [formFields]="fields['AR32_2'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('AR32_2')"
        (formEdited)="formEdited($event)" />

      <app-form-chapter
        *ngIf="fields['AR32_3']?.fields"
        [evaluation]="fields['AR32_3']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'AR32_3'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Article 32 Form"
        [formModel]="vm.form"
        [formFields]="fields['AR32_3'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('AR32_3')"
        (formEdited)="formEdited($event)" />
      <app-form-chapter
        *ngIf="fields['AR32_4']?.fields"
        [evaluation]="fields['AR32_4']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'AR32_4'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Article 32 Form"
        [formModel]="vm.form"
        [formFields]="fields['AR32_4'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('AR32_4')"
        (formEdited)="formEdited($event)" />
      <app-form-chapter
        *ngIf="fields['AR32_5']?.fields"
        [evaluation]="fields['AR32_5']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'AR32_5'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Article 32 Form"
        [formModel]="vm.form"
        [formFields]="fields['AR32_5'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('AR32_5')"
        (formEdited)="formEdited($event)" />
      <app-form-chapter
        *ngIf="fields['AR32_6']?.fields"
        [evaluation]="fields['AR32_6']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'AR32_6'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Article 32 Form"
        [formModel]="vm.form"
        [formFields]="fields['AR32_6'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('AR32_6')"
        (formEdited)="formEdited($event)" />
      <app-form-chapter
        *ngIf="fields['AR32_7']?.fields"
        [evaluation]="fields['AR32_7']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'AR32_7'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Article 32 Form"
        [formModel]="vm.form"
        [formFields]="fields['AR32_7'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('AR32_7')"
        (formEdited)="formEdited($event)" />
      <app-form-chapter
        *ngIf="fields['AR32_8']?.fields"
        [evaluation]="fields['AR32_8']['evaluation']!"
        [formEvaluation]="vm.evaluation"
        [chapterId]="'AR32_8'"
        [solution]="solution"
        [currentChapterId]="selectedChapter"
        formName="Article 32 Form"
        [formModel]="vm.form"
        [formFields]="fields['AR32_8'].fields"
        (chapterEdited)="editChapter($event)"
        (chapterSelected)="selectChapter('AR32_8')"
        (formEdited)="formEdited($event)" />
    </div>
  </div>
</div>
