import { Component, ChangeDetectionStrategy, Type } from '@angular/core';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
} from '@ngx-formly/core';
import { FormlyFieldProps } from '@ngx-formly/ng-zorro-antd/form-field';

type TextAreaProps = FormlyFieldProps;

export interface FormlyTextAreaFieldConfig
  extends FormlyFieldConfig<TextAreaProps> {
  type: 'nz-textarea' | Type<FormlyFieldTextArea>;
}

@Component({
  selector: 'app-nz-textarea',
  template: `
    <textarea
      nz-input
      rows="4"
      [disabled]="props.disabled!"
      [placeholder]="props.placeholder!"
      [ngModel]="formControl.value"
      (ngModelChange)="onChange($event)"></textarea>
  `,
  styles: [
    `
      textarea {
        background: var(
          --components-input-light-input-light-background-disabled
        ) !important;
      }
      textarea[disabled] {
        color: var(--components-table-body-table-cell-foreground-default);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldTextArea extends FieldType<
  FieldTypeConfig<TextAreaProps>
> {
  onChange(value: any) {
    this.formControl.markAsDirty();
    this.formControl.patchValue(value);
    this.formControl.markAsTouched();
  }
}
