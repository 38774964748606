<div class="rounded-white spacing-b16">
  <ng-template #header>
    <h4 nz-typography [id]="chapterId">
      {{ chapterId | translate | truncate: 35 }}
    </h4>
  </ng-template>
  <ng-template #actions>
    {{ chapterTitle }}
    <app-form-actions
      [assessable]="assessable"
      [evaluation]="evaluation"
      [formEvaluation]="formEvaluation"
      [fieldId]="chapterId"
      [fieldTitle]="chapterId | translate"
      [isChapter]="true"
      [solutionId]="solution?.uid!"
      [solutionName]="solution?.solutionName!"
      [completable]="true"
      [formName]="formName"></app-form-actions>
  </ng-template>
  <nz-collapse [nzBordered]="false">
    <nz-collapse-panel
      [nzHeader]="header"
      [nzExtra]="actions"
      [nzActive]="chapterId === currentChapterId"
      (nzActiveChange)="chapterSelected.emit(chapterId)"
      [ngStyle]="{
        background: '#ffffff',
        'border-radius': '4px',
        border: '0px'
      }">
      <div>
        <form
          nz-form
          [nzLayout]="'vertical'"
          [formGroup]="form"
          (ngSubmit)="submit()">
          <formly-form
            [model]="model"
            [fields]="fields"
            [options]="options"
            [form]="form"
            (modelChange)="modelChanged($event)"></formly-form>
        </form>
        <ng-content></ng-content>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
</div>
