import { Component, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { VeoliaModalComponent, VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';

@Component({
  standalone: true,
  selector: 'app-legal-notices',
  templateUrl: './legal-notices.component.html',
  styleUrls: ['./legal-notices.component.scss'],
  imports: [VeoliaModalModule, TranslateModule],
})
export class LegalNoticesComponent {
  @ViewChild('modal') modal!: VeoliaModalComponent;

  open() {
    this.modal.open();
  }
}
