import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { BaseLayoutComponent } from 'src/app/shared/features/layout/components/base-layout/base-layout.component';
import { DemandFormPageComponent } from './pages/demand-form-page/demand-form-page.component';
import { DemandFormEditPageComponent } from './pages/demand-form-edit-page/demand-form-edit-page.component';
import { SolutionResolver } from 'src/app/core/resolvers/solution-resolver';
import { DemandFormsPageComponent } from './pages/demand-forms-page/demand-forms-page.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'form',
        component: DemandFormPageComponent,
      },
      {
        path: 'form/:id/edit',
        component: DemandFormEditPageComponent,
        resolve: {
          solution: SolutionResolver,
        },
      },
      {
        path: 'forms/:id/:type',
        component: DemandFormsPageComponent,
        resolve: {
          solution: SolutionResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DemandsRoutingModule {}
