import { NgModule } from '@angular/core';
import { CommentSummaryComponent } from './components/comment-summary/comment-summary.component';
import { CommonModule } from '@angular/common';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { TranslateModule } from '@ngx-translate/core';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';

@NgModule({
  declarations: [CommentSummaryComponent],
  imports: [
    CommonModule,
    NzAvatarModule,
    NzCollapseModule,
    NzCommentModule,
    NzDividerModule,
    NzListModule,
    NzModalModule,
    VeoliaButtonComponent,
    VeoliaModalModule,
    TranslateModule,
  ],
  exports: [CommentSummaryComponent],
})
export class CommentSummaryModule {}
