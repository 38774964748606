import { HttpParams } from "@angular/common/http";

const HttpMapper = {
  mapToHttpParams: (params: any) => {
    return Object.keys(params).reduce((httpParams, key) => {
      return httpParams.set(key, params[key]);
    }, new HttpParams());
  }
}

export default HttpMapper;
