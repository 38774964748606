<form [formGroup]="contactForm!" (ngSubmit)="submit()">
  <app-form-label [label]="'Add new comment'"></app-form-label>
  <veolia-textarea
    formControlName="message"
    [variant]="'light'"
    [placeholder]="'Your comment...' | translate" />
  <app-form-label [label]="'Send comment to'"></app-form-label>
  <veolia-select
    formControlName="taggedUser"
    [options]="[
      {
        label: 'All veolia users from this request',
        value: 'veoliasList'
      },
      {
        label: 'All vendor users from this request',
        value: 'vendorsList'
      },
      {
        label: 'All DLA BU evaluators and evaluators added for this request',
        value: 'evaluatorsBUList'
      },
      {
        label: 'Tag a veolia user',
        value: 'veolia_user',
        children: emailVeoliaAuthorized
      },
      {
        label: 'Tag a vendor user',
        value: 'veolia_vendor',
        children: emailVendorAuthorized
      },
      {
        label: 'Tag an evaluator',
        value: 'veolia_evaluator',
        children: emailEvaluatorAuthorized
      }
    ]"
    [size]="'default'"
    [mode]="'default'"></veolia-select>
  <div class="row row-right spacing-v8">
    <div class="row row-right">
      <div>
        <veolia-button
          [size]="'default'"
          [variant]="'secondary'"
          htmlType="reset">
          {{ 'Cancel' | translate }}
        </veolia-button>
      </div>
      <div>
        <veolia-button
          [size]="'default'"
          [variant]="'primary'"
          [htmlType]="'submit'"
          [disabled]="contactForm?.invalid!">
          {{
            editCommentKey !== undefined
              ? ('Edit comment' | translate)
              : ('Add comment' | translate)
          }}
        </veolia-button>
      </div>
    </div>
  </div>
</form>
<nz-divider class="spacing-v16"></nz-divider>
<h5 nz-typography>Comments history</h5>
<nz-list
  [nzDataSource]="comments"
  [nzRenderItem]="item"
  [nzItemLayout]="'horizontal'">
  <ng-template #item let-item let-index="index">
    <div *ngIf="currentUserEmail === item.postedBy" class="row row-right">
      <div class="spacing-l4">
        <veolia-button
          [size]="'small'"
          [variant]="'secondary'"
          [htmlType]="'button'"
          (click)="editComment(item)"
          [icon]="'edit'"></veolia-button>
      </div>
      <div class="spacing-l4">
        <veolia-button
          [size]="'small'"
          [variant]="'secondary'"
          [htmlType]="'button'"
          (click)="deleteComment(item, index)"
          [icon]="'delete'"></veolia-button>
      </div>
    </div>
    <ng-template #commentAuthor>
      <strong class="black">{{ item.postedBy }}</strong>
      <span *ngIf="item.taggedUser">
        to
        <strong class="blue-700">{{ item.taggedUser }} </strong>
      </span>
    </ng-template>
    <ng-template #commentDate>
      <span class="blue-700">{{ item.postedAt.toString() }}</span>
    </ng-template>
    <nz-comment [nzAuthor]="commentAuthor" [nzDatetime]="commentDate">
      <nz-avatar
        nz-comment-avatar
        nzIcon="user"
        [nzSrc]="item.avatar"></nz-avatar>
      <nz-comment-content>
        <p>{{ item.content }}</p>
      </nz-comment-content>
    </nz-comment>
    <nz-divider></nz-divider>
  </ng-template>
</nz-list>
