<div class="disclaimer">
  Welcome !<br />
  You are about to make a new DLA request. <br />
  In the case it is a first DLA request for that solution, you checked in the
  <a
    href="https://docs.google.com/presentation/d/1dsxnMs0YJvDZsNTER7b7VAPv1h8VEGcsQNfnHnN0JIc/edit#slide=id.g20a6bc5aa72_0_15"
    title="SaaS solution list PDF"
    target="_blank"
    >SaaS Solutions List</a
  >
  that there is no already assessed solution that would meet the need.<br /><br />
  You will be the Solution Owner and the main Veolia contact for that
  request.<br />
  In this sense, you will have to follow-up that request as described in the
  <a
    href="https://docs.google.com/presentation/d/1kqMltFibPvQjwLI2bcVVdQAQ2w7Stf-MIw78wsZUkOI/edit#slide=id.g85055ddc33_0_1700"
    alt="Solution Owner Guide"
    target="_blank"
    >Solution Owner Guide</a
  >
  namely by:<br />
  <ul>
    <li>
      Making sure all information regarding the request is accurate & complete.
    </li>
    <li>Managing the relationship with the Vendor & other stakeholders</li>
    <li>Reviving the Vendor to complete the forms</li>
    <li>Informing the DLA Team if a DLA request has to be canceled</li>
  </ul>
  <nz-radio-group
    [formControl]="formControl"
    (ngModelChange)="props.change && props.change(field, $event)">
    <label
      nz-radio
      *ngFor="let option of props.options | formlySelectOptions: field | async"
      [nzValue]="option.value"
      [nzDisabled]="option.disabled">
      {{ option.label }}
    </label>
  </nz-radio-group>
</div>
