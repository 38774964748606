import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

export interface HomePageState {
  solutionType: number;
}

@Injectable()
export class HomePageStore extends ComponentStore<HomePageState> {
  private solutionType$ = this.select(state => state.solutionType);

  vm$ = this.select(this.solutionType$, solutionType => ({
    solutionType,
  }));

  constructor() {
    super({
      solutionType: 0,
    });
  }

  setSolutionType = this.updater((state, solutionType: number) => ({
    ...state,
    solutionType,
  }));
}
