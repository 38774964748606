import { Injectable } from '@angular/core';
import { Auth, onAuthStateChanged } from '@angular/fire/auth';
import {
  Database,
  child,
  get,
  getDatabase,
  onValue,
  ref,
  serverTimestamp,
  update,
} from '@angular/fire/database';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class PresenceService {
  tabId = this.getUniqueId();

  constructor(
    private auth: Auth,
    private db: Database = getDatabase()
  ) {}

  getUniqueId() {
    let value = window.sessionStorage.getItem('unique-id');

    if (!value || !window.name) {
      value = uuid();
      window.sessionStorage.setItem('unique-id', value);
    }

    window.name = value;
    return value;
  }

  getTabId() {
    return this.tabId;
  }

  getUser() {
    return this.auth.currentUser;
  }

  getPresence(uid: string) {
    return new Promise((resolve, reject) => {
      onValue(ref(this.db, `status/${uid}`), snapshot => {
        if (!snapshot.exists()) {
          reject('No presences found');
        }

        const data = snapshot.val();
        resolve(data);
      });
    });
  }

  setPresence(uid: string, status = 'online') {
    const user = this.getUser();
    const timestamp = serverTimestamp();

    if (user) {
      get(child(ref(this.db), `status/${uid}`))
        .then(resp => {
          if (resp.exists()) {
            const data = resp.val();
            const tabs = data?.tabs || [];
            const currentTabIndex = tabs.findIndex(
              (element: { tabId: string }) => element.tabId === this.tabId
            );

            if (currentTabIndex !== -1) {
              tabs[currentTabIndex] = {
                tabId: this.tabId,
                timestamp: timestamp,
              };
            } else {
              tabs.push({ tabId: this.tabId, timestamp: timestamp });
            }

            update(ref(this.db, `status/${user.uid}`), {
              presence: status,
              status: tabs.length > 1 ? 'confirming' : 'confirmed',
              timestamp: timestamp,
              tabId: tabs.length > 1 ? '' : this.tabId,
              tabs,
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  confirmPresence(uid: string, tabId: string, status = 'online') {
    const user = this.getUser();

    if (user) {
      get(child(ref(this.db), `status/${uid}`))
        .then(resp => {
          if (resp.exists()) {
            const data = resp.val();
            const tabs = data?.tabs || [];
            const confirmedTabs = tabs.map(
              (element: { [x: string]: string }) => {
                if (element['tabId'] !== tabId) {
                  element['action'] = 'quit';
                }
                return element;
              }
            );

            update(ref(this.db, `status/${user.uid}`), {
              presence: status,
              status: 'confirmed',
              timestamp: serverTimestamp(),
              tabId: tabs.length > 1 ? '' : this.tabId,
              tabs: confirmedTabs,
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  cleanPresence(uid: string, status = 'online') {
    const user = this.getUser();

    if (user) {
      get(child(ref(this.db), `status/${uid}`))
        .then(resp => {
          const data = resp.val();
          const tabs = data?.tabs || [];
          const confirmedTabs = tabs.filter(
            (element: { [x: string]: any }) => !element['action']
          );

          update(ref(this.db, `status/${user.uid}`), {
            presence: status,
            status: 'confirmed',
            timestamp: serverTimestamp(),
            tabId: tabs.length > 1 ? '' : this.tabId,
            tabs: confirmedTabs,
          });
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  updateOnUser() {
    onAuthStateChanged(this.auth, (user: any) => {
      if (user) {
        onValue(ref(this.db, '.info/connected'), snap => {
          const status = snap.val() === true ? true : false;
          this.setPresence(user?.uid, status ? 'online' : 'offline');
        });
      }
    });
  }

  updateOnAway() {
    document.onvisibilitychange = e => {
      if (document.visibilityState === 'hidden') {
        this.setPresence(this.auth.currentUser?.uid!, 'away');
      } else {
        this.setPresence(this.auth.currentUser?.uid!, 'online');
      }
    };
  }

  updateOnDisconnect() {
    onAuthStateChanged(this.auth, (user: any) => {
      if (user) {
        ref(this.db, `status/${user.uid}`);
        update(ref(this.db, `status/${user.uid}`), {
          status: 'offline',
          timestamp: serverTimestamp(),
        });
      }
    });
  }
}
