import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { BusinessUnitSolutionsStore } from './business-unit-solutions.store';
import { AuthService } from '../../../../core/services/auth.service';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SolutionService } from 'src/app/core/services/solution.service';

@Component({
  selector: 'app-home-page-business-unit-solutions',
  templateUrl: './business-unit-solutions.component.html',
  styleUrls: ['./business-unit-solutions.component.scss'],
  providers: [BusinessUnitSolutionsStore],
})
export class BusinessUnitSolutionsComponent
  extends DataTableComponent
  implements OnInit, OnChanges
{
  @Input() solutionType = 0;
  @ViewChild('search') search: ElementRef<HTMLInputElement> = {} as ElementRef;

  vm$ = this.businessUnitSolutionsStore.vm$;

  constructor(
    public override authService: AuthService,
    public override router: Router,
    public override translateService: TranslateService,
    public businessUnitSolutionsStore: BusinessUnitSolutionsStore,
    public override solutionService: SolutionService
  ) {
    super(authService, router, translateService, solutionService);
  }

  ngOnInit(): void {
    this.authService.userData.subscribe(user => {
      if (user) {
        this.businessUnitSolutionsStore.setBusinessUnit(user?.businessUnit);
        this.businessUnitSolutionsStore.setSolutionType(this.solutionType);
        this.businessUnitSolutionsStore.getCount();
        this.businessUnitSolutionsStore.getSolutions();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['solutionType']['firstChange']) {
      this.businessUnitSolutionsStore.changeSolutionType(
        changes['solutionType'].currentValue
      );
    }
  }

  changePage(page: number) {
    this.businessUnitSolutionsStore.changePage(page);
  }

  changeSize(size: Event) {
    const limit = parseInt((size.target as HTMLSelectElement).value);
    this.businessUnitSolutionsStore.changeLimit(limit);
  }

  onSearch(event: string) {
    this.businessUnitSolutionsStore.changeSearch(event);
  }
}
