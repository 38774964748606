import { Injectable } from '@angular/core';
import { BaseApi } from './api';
import { Observable } from 'rxjs';

@Injectable()
class UserApi extends BaseApi {
  addVendorToSolutionIfExistsOrNotByVendorOrVeolia(
    solutionId: string,
    data: any,
    currentUserEmail: string,
    requester: string
  ): Observable<any> {
    return this.post('/api/admin/share_solution_to_vendor', {
      solutionId,
      ...data,
      currentUserEmail,
      requester,
    });
  }

  addVeoliaToSolutionByVeolia(
    solutionId: string,
    emailVeolia: string,
    isNew: boolean,
    currentUserEmail: string,
    requester: string
  ): Observable<any> {
    return this.post('/api/admin/share_solution_to_veolia', {
      solutionId,
      emailVeolia,
      isNew,
      currentUserEmail,
      requester,
    });
  }

  disableVendor(vendorId: string, vendorEmail: string) {
    return this.post('/api/admin/disable_vendor', { vendorId, vendorEmail });
  }

  enableVendor(vendorId: string, vendorEmail: string) {
    return this.post('/api/admin/enable_vendor', { vendorId, vendorEmail });
  }

  notify_password_changed() {
    return this.get('/api/user/password_changed');
  }
}

export default UserApi;
