import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class TermsGuard {
  constructor(
    public authService: AuthService,
    public router: Router,
    public userService: UserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {
    return new Promise((resolve, reject) => {
      this.authService.user$.subscribe(user => {
        if (user) {
          this.userService
            .getUserData(user?.uid)
            .then(user => {
              const userData = user.data() as User;
              if (userData.termsAccepted !== true) {
                resolve(true);
              } else {
                this.router.navigate(['/']);
                reject(false);
              }
            })
            .catch(error => {
              this.router.navigate(['/']);
              reject(false);
            });
        } else {
          this.router.navigate(['/']);
          reject(false);
        }
      });
    });
  }
}
