import { RouterModule, Routes } from '@angular/router';

import { BaseLayoutComponent } from '../../shared/features/layout/components/base-layout/base-layout.component';
import { NgModule } from '@angular/core';

import { BusinessUnitEvaluatorPageComponent } from './pages/business-unit-evaluator-page/business-unit-evaluator-page.component';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { EvaluatorGuard } from 'src/app/core/guards/evaluator.guard';

const routes: Routes = [
  {
    path: 'evaluator',
    component: BaseLayoutComponent,
    canActivate: [AuthGuard, EvaluatorGuard],
    children: [
      {
        path: '',
        component: BusinessUnitEvaluatorPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BusinessUnitEvaluatorRoutingModule {}
