<div class="actions-label">
  <nz-segmented
    *ngIf="!isLoading"
    [nzSize]="'small'"
    [nzOptions]="permissions"
    [ngModel]="access"
    [disabled]="disabled!"
    (nzValueChange)="handlePermissionChange($event)"></nz-segmented>
  <nz-spin *ngIf="isLoading" nzSimple></nz-spin>
</div>
