import { Component } from '@angular/core';
import { AdminSolutionsPageStore } from './admin-solutions-page.store';
import { SolutionService } from '../../../../core/services/solution.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-solutions-page',
  templateUrl: './admin-solutions-page.component.html',
  styleUrls: ['./admin-solutions-page.component.scss'],
  providers: [SolutionService, AdminSolutionsPageStore],
})
export class AdminSolutionsPageComponent {
  vm$ = this.adminSolutionsPageStore.vm$;

  solutionsCount: any;

  constructor(
    private adminSolutionsPageStore: AdminSolutionsPageStore,
    private router: Router
  ) {}

  onSolutionTypeChange(type: number) {
    this.adminSolutionsPageStore.setSolutionType(type);
  }

  backtoAdminHome() {
    this.router.navigate(['/admin']);
  }
}
