import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';
import { Article28Form } from 'src/app/core/models/article-28-form';
import { Article32Form } from 'src/app/core/models/article-32-form';
import { DesignForm } from 'src/app/core/models/design-form';
import { LegalForm } from 'src/app/core/models/legal-form';
import { Solution } from 'src/app/core/models/solution';
import { Article28FormService } from 'src/app/core/services/article-28-form.service';
import { Article32FormService } from 'src/app/core/services/article-32-form.service';
import { DesignFormService } from 'src/app/core/services/design-form.service';
import { LegalFormService } from 'src/app/core/services/legal-form.service';
import { BusinessUnitService } from 'src/app/core/services/business-unit.service';
import { Router } from '@angular/router';
import { FileApi } from 'src/app/core/apis/file.api';

@Component({
  selector: 'app-solution-info-summary',
  styleUrls: ['./solution-info-summary.component.scss'],
  templateUrl: './solution-info-summary.component.html',
})
export class SolutionInfoSummaryComponent implements AfterContentChecked {
  @Input() solution?: Solution;
  @Input() formName?: string;

  @ViewChild('infoModal') infoModal!: VeoliaModalComponent;
  @ViewChild('infoModal2') infoModal2!: VeoliaModalComponent;

  article28Form?: Article28Form;
  article32Form?: Article32Form;
  designForm?: DesignForm;
  legalForm?: LegalForm;

  emailEvaluatorsBu: Array<string> = [];

  constructor(
    private cDRef: ChangeDetectorRef,
    private article28FormService: Article28FormService,
    private article32FormService: Article32FormService,
    private designFormService: DesignFormService,
    private legalFormService: LegalFormService,
    private businessUnitService: BusinessUnitService,
    private router: Router,
    private fileApi: FileApi
  ) {}

  ngAfterContentChecked(): void {
    this.cDRef.detectChanges();
  }

  loadForms(): void {
    if (this.solution?.uid) {
      this.article28FormService.get(this.solution?.uid).then(data => {
        this.article28Form = { uid: data.id, ...data.data() } as Article28Form;
      });

      this.article32FormService.get(this.solution?.uid).then(data => {
        if (data.exists()) {
          this.article32Form = {
            uid: data.id,
            ...data.data(),
          } as Article32Form;
        }
      });

      this.designFormService.get(this.solution?.uid).then(data => {
        if (data.exists()) {
          this.designForm = { uid: data.id, ...data.data() } as DesignForm;
        }
      });

      this.legalFormService.get(this.solution?.uid).then(data => {
        if (data.exists()) {
          this.legalForm = { uid: data.id, ...data.data() } as LegalForm;
        }
      });
    }
  }

  getEvaluatorsListFromBU() {
    this.businessUnitService
      .getEvaluatorList(this.solution?.businessUnitOfSolution!)
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(doc => {
            const data = doc.data();
            this.emailEvaluatorsBu = data['evaluators'];
          });
        }
      });
  }

  open(solution: Solution) {
    this.solution = solution;
    this.loadForms();
    this.getEvaluatorsListFromBU();
    if (this.isSummarySolutionRoute()) {
      //for 'about this request' in summary/id page
      this.infoModal2.open();
    }
    if (!this.isSummarySolutionRoute() || this.isLogsSolutionRoute()) {
      //for INFO button in all tables + 'about this request' in all forms + logs/id page
      this.infoModal.open();
    }
  }

  generatePDF() {
    let containsAllForms = 'no';
    if (this.isFormsRoute()) {
      containsAllForms = 'no';
      this.fileApi
        .exportSolutionInfoPDF(this.solution?.uid!, {
          formName: this.formName!,
          formNameUrl: this.toCamelCase(this.formName),
          containsAllForms,
        })
        .subscribe({
          next: response => {
            const blob = new Blob([response.body], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            let fileName = '';

            if (this.formName === 'Article 28 Form') {
              fileName = 'Additional Forms';
            } else {
              fileName = this.formName!;
            }

            link.download = `Solution and ${fileName} overview for ${this.solution?.solutionName}.pdf`;
            link.click();
            window.URL.revokeObjectURL(url);
          },
          error: error => {
            console.error('Error downloading PDF:', error);
          },
        });
    } else {
      containsAllForms = 'yes';
      this.fileApi
        .exportSolutionInfoPDF(this.solution?.uid!, {
          formName: this.formName!,
          formNameUrl: this.toCamelCase(this.formName),
          containsAllForms,
        })
        .subscribe({
          next: response => {
            const blob = new Blob([response.body], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `Solution and forms overview for ${this.solution?.solutionName}.pdf`;
            link.click();
            window.URL.revokeObjectURL(url);
          },
          error: error => {
            console.error('Error downloading PDF:', error);
          },
        });
    }
  }

  toCamelCase(str?: string) {
    if (str === undefined) {
      return '';
    }
    // Remove leading and trailing whitespace and split into words
    const words = str.trim().split(/\s+/);

    // Convert the first word to lowercase
    const firstWord = words[0].toLowerCase();

    // Convert the first letter of subsequent words to uppercase
    const restOfWords = words
      .slice(1)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

    // Join all parts
    return firstWord + restOfWords.join('');
  }

  isHomeRoute() {
    return this.router.url === '/';
  }

  isEvaluatorRoute() {
    return this.router.url === '/evaluator';
  }

  isAdminSolutionsRoute() {
    return this.router.url === '/admin/solutions';
  }

  isAdminBuRoute() {
    return this.router.url === '/admin/business-units';
  }

  isAdminUsersRoute() {
    return this.router.url === '/admin/users';
  }

  isSummarySolutionRoute() {
    const re = new RegExp('^(/summary/)\\w+');
    if (this.router.url.match(re)) {
      return true;
    } else {
      return false;
    }
  }

  isLogsSolutionRoute() {
    const re = new RegExp('^(/logs/)\\w+');
    if (this.router.url.match(re)) {
      return true;
    } else {
      return false;
    }
  }

  isFormsRoute() {
    const re = new RegExp(
      '^(/designForm/)\\w+|(/legalForm/)\\w+|(/article32Form/)\\w+|(/article28Form/)\\w+'
    );
    if (this.router.url.match(re)) {
      return true;
    } else {
      return false;
    }
  }
}
