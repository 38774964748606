import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
  signal,
  WritableSignal,
  effect,
} from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { map } from 'rxjs';
import { CommentApi } from 'src/app/core/apis/comment.api';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { Comment } from 'src/app/core/models/comment';
import { Solution } from 'src/app/core/models/solution';
import { AuthService } from 'src/app/core/services/auth.service';
import { BusinessUnitService } from 'src/app/core/services/business-unit.service';
import { CommentService } from 'src/app/core/services/comment.service';
import { LogService } from 'src/app/core/services/log.service';
import { SolutionService } from 'src/app/core/services/solution.service';

type SelectOption = {
  label: string;
  value: string;
};

@Component({
  selector: 'app-form-action-comment',
  templateUrl: './form-action-comment.html',
  styleUrls: ['./form-action-comment.scss'],
})
export class FormActionCommentComponent implements OnInit {
  @Input() modalData: any;
  @Output() commentsRefreshed: EventEmitter<void> = new EventEmitter<void>();

  loading?: boolean;
  comments: Comment[] = [];
  formName?: string;
  solutionId?: string;
  solution?: Solution;
  solutionSignal: WritableSignal<Solution | undefined> = signal<
    Solution | undefined
  >(undefined);
  fieldId?: string;
  emailVeoliaAuthorized: SelectOption[] = [];
  emailVendorAuthorized: SelectOption[] = [];
  emailEvaluatorAuthorized: SelectOption[] = [];
  emailBUEvaluator: SelectOption[] = [];
  editCommentKey?: string;
  formNameRoute?: string;

  contactForm?: FormGroup;
  public _auth: Auth = inject(Auth);
  private authService: AuthService = inject(AuthService);

  constructor(
    private auth: Auth,
    private cdr: ChangeDetectorRef,
    private commentApi: CommentApi,
    private commentService: CommentService,
    private messageService: VeoliaMessageService,
    private formBuilder: NonNullableFormBuilder,
    private logService: LogService,
    private solutionService: SolutionService,
    private businessUnitService: BusinessUnitService
  ) {
    effect(() => {
      const solSign = this.solutionSignal();

      if (solSign) {
        if (this.isVendorReadOnly) {
          this.contactForm?.disable();
        }
      }
    });
  }

  ngOnInit(): void {
    const { formName, solutionId, fieldId } = this.modalData;
    this.formName = formName;
    this.solutionId = solutionId;
    this.fieldId = fieldId;

    if (this.formName === 'Article 28 Form') {
      this.formNameRoute = 'article28Form';
    }
    if (this.formName === 'Article 32 Form') {
      this.formNameRoute = 'article32Form';
    }
    if (this.formName === 'Legal Form') {
      this.formNameRoute = 'legalForm';
    }
    if (this.formName === 'Design Form') {
      this.formNameRoute = 'designForm';
    }

    this.fetchSolution();
    this.fetchComments();
    this.contactForm = this.formBuilder.group({
      message: ['', [Validators.required]],
      taggedUser: ['', [Validators.required]],
    });
  }

  get currentUserEmail() {
    return this._auth.currentUser?.email;
  }

  get isVendor() {
    if (!this.solution?.emailVendorAuthorized) {
      return false;
    }
    return this.solution?.emailVendorAuthorized.includes(
      this.auth.currentUser?.email!
    );
  }

  get isVendorReadOnly() {
    if (this.formName === 'Article 28 Form') {
      return this.solution?.emailVendorReadonlyArt28?.includes(
        this.auth.currentUser?.email!
      );
    } else if (this.formName === 'Article 32 Form') {
      return this.solution?.emailVendorReadonlyArt32?.includes(
        this.auth.currentUser?.email!
      );
    } else if (this.formName === 'Design Form') {
      return this.solution?.emailVendorReadonlyDesign?.includes(
        this.auth.currentUser?.email!
      );
    } else if (this.formName === 'Legal Form') {
      return this.solution?.emailVendorReadonlyLegal?.includes(
        this.auth.currentUser?.email!
      );
    } else {
      return false;
    }
  }

  fetchComments() {
    if (this.formName && this.solutionId && this.fieldId) {
      this.commentService
        .getComments(this.formName, this.solutionId, this.fieldId)
        .then(data => {
          this.comments = data as Comment[];
        })
        .catch(error => {
          this.comments = [];
          console.log(error);
        });
    }
  }

  addComment() {
    const path = `/comments/${this.formName}/${this.solutionId}/${this.fieldId}`;
    const postedAt = new Date(Date.now()).toString();

    const comment = {
      content: this.contactForm?.value.message,
      formIs: this.formName,
      idRef: `${this.solutionId}-${this.fieldId}`,
      idSolution: this.solutionId,
      postedAt: postedAt,
      postedBy: this._auth.currentUser?.email,
      reference: this.fieldId,
      solutionName: this.solution?.solutionName,
      taggedUser: this.contactForm?.value.taggedUser,
      userId: this._auth.currentUser?.uid,
    };

    if (!this.editCommentKey) {
      this.commentService
        .addComment(path, comment)
        .then(() => {
          this.logService.setLogForAction(
            'Comment',
            this.solutionId!,
            this.formName!,
            {
              action: 'Comment added',
              chapter: this.fieldId,
              content: this.contactForm?.value.message,
              roleUser: this.authService.userRole,
              taggedUser: this.contactForm?.value.taggedUser,
            }
          );

          this.commentApi
            .notifyCommentAdd(this.solutionId!, {
              action: 'add',
              chapter: this.fieldId!,
              content: this.contactForm?.value.message,
              displayName: this.authService.displayName!,
              formName: this.formName!,
              postedAt: postedAt,
              taggedUser: this.contactForm?.value.taggedUser,
              formNameRoute: this.formNameRoute!,
            })
            .subscribe();

          this.contactForm?.reset();
          this.fetchComments();
          this.commentsRefreshed.emit();

          this.messageService.create(
            {
              icon: 'check',
              title: 'Success',
              content: 'Comment added !',
              type: 'success',
            },
            { duration: 5000 }
          );
        })
        .catch(error => {
          console.log('error', error);
        });
    } else {
      this.commentService
        .updateComment(`${path}/${this.editCommentKey}`, comment)
        .then(() => {
          this.logService.setLogForAction(
            'Comment',
            this.solutionId!,
            this.formName!,
            {
              action: 'Comment edited',
              chapter: this.fieldId,
              content: this.contactForm?.value.message,
              roleUser: this.authService.userRole,
              taggedUser: this.contactForm?.value.taggedUser,
            }
          );

          this.commentApi
            .notifyCommentAdd(this.solutionId!, {
              action: 'edited',
              chapter: this.fieldId!,
              content: this.contactForm?.value.message,
              displayName: this.authService.displayName!,
              formName: this.formName!,
              postedAt: postedAt,
              taggedUser: this.contactForm?.value.taggedUser,
              formNameRoute: this.formNameRoute!,
            })
            .subscribe();

          this.editCommentKey = undefined;
          this.contactForm?.reset();
          this.fetchComments();
          this.commentsRefreshed.emit();

          this.messageService.create(
            {
              icon: 'check',
              title: 'Success',
              content: 'Comment edited !',
              type: 'success',
            },
            { duration: 5000 }
          );
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  }

  editComment(comment: Comment) {
    this.editCommentKey = comment.id;
    this.contactForm?.setValue({
      message: comment.content,
      taggedUser: comment.taggedUser,
    });
  }

  cleanAndReload(index: number) {
    this.cleanFile(index);
    this.fetchComments();
    this.commentsRefreshed.emit();
    this.cdr.detectChanges();
  }

  cleanFile(index: number) {
    this.comments.splice(index, 1);
  }

  deleteComment(comment: Comment, index: number) {
    const path = `/comments/${this.formName}/${this.solutionId}/${this.fieldId}`;
    this.commentService
      .deleteComment(`${path}/${comment.id}`)
      .then(() => {
        this.commentsRefreshed.emit();
        this.cleanAndReload(index);
        this.logService.setLogForAction(
          'Comment',
          this.solutionId!,
          this.formName!,
          {
            action: 'Comment deleted',
            chapter: this.fieldId,
            content: this.contactForm?.value.message,
            roleUser: this.authService.userRole,
            taggedUser: this.contactForm?.value.taggedUser,
          }
        );

        this.editCommentKey = undefined;
        this.contactForm?.reset();
        this.fetchComments();
        this.commentsRefreshed.emit();

        this.messageService.create(
          {
            icon: 'check',
            title: 'Success',
            content: 'Comment deleted !',
            type: 'info',
          },
          { duration: 5000 }
        );
      })
      .catch(error => {
        console.log('error', error);
      });
  }

  fetchSolution() {
    if (this.solutionId) {
      this.solutionService.get(this.solutionId).then(doc => {
        if (doc.exists()) {
          this.solution = doc.data() as Solution;
          this.solutionSignal.set(this.solution);
          this.emailVeoliaAuthorized = this.solution.emailVeoliaAuthorized
            .filter(this.filterEmail)
            .map(this.mapEmail);
          this.emailVendorAuthorized = this.solution.emailVendorAuthorized
            .filter(this.filterEmail)
            .map(this.mapEmail);
          this.emailEvaluatorAuthorized = this.solution.emailEvaluatorAuthorized
            .filter(this.filterEmail)
            .map(this.mapEmail);

          this.businessUnitService
            .getBySolutionId(this.solutionId!)
            .pipe(
              map(businessUnitData => {
                if (businessUnitData) {
                  this.emailBUEvaluator = (
                    businessUnitData as BusinessUnit
                  ).evaluators
                    .filter(this.filterEmail)
                    .map(this.mapEmail);
                  const evaluators = [
                    ...new Set([
                      ...this.solution!.emailEvaluatorAuthorized, //comprend donc les evaluators de la BU + evaluators ajoutés à la solution
                      ...(businessUnitData as BusinessUnit).evaluators,
                    ]),
                  ];
                  this.emailEvaluatorAuthorized = evaluators
                    .filter(this.filterEmail)
                    .map(this.mapEmail);
                }
              })
            )
            .subscribe();
        }
      });
    }
  }

  filterEmail = (email: string) => {
    return email !== null && email !== this._auth.currentUser?.email;
  };

  mapEmail(email: string) {
    return { label: email, value: email };
  }

  submit() {
    if (this.contactForm?.valid) {
      this.addComment();
    }
  }
}
