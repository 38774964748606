import { Component, ViewChild, inject } from '@angular/core';
import { LoginPageStore } from './login-page.store';

import { LoginPasswordFormValue } from '../../authentication.type';
import { AuthService } from '../../../../core/services/auth.service';
import {
  VeoliaModalComponent,
  VeoliaModalService,
} from '@veolia.com/vds-angular-components/modal';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { Auth, sendPasswordResetEmail } from '@angular/fire/auth';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
  vm$ = this.loginPageStore.vm$;
  loading = false;
  passwordVisible = false;
  validateForm: FormGroup<{
    userName: FormControl<string>;
    password: FormControl<string>;
  }> = this.fb.group({
    userName: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  forgotPasswordForm: FormGroup<{
    email: FormControl<string>;
  }> = this.fb.group({
    email: ['', [Validators.required]],
  });

  private readonly authService = inject(AuthService);

  @ViewChild('modal') modal!: VeoliaModalComponent;
  @ViewChild('forgotPasswordModal') forgotPasswordmodal!: VeoliaModalComponent;
  #service = inject(VeoliaModalService);

  constructor(
    private auth: Auth,
    private fb: NonNullableFormBuilder,
    private loginPageStore: LoginPageStore,
    private veoliaMessageService: VeoliaMessageService
  ) {}

  public closeLoginForm(): void {
    this.loginPageStore.closeLoginForm();
  }

  public submitLoginForm(data: LoginPasswordFormValue): void {
    this.authService
      .SignIn(data.userName, data.password)
      .then(data => {
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
      });
  }

  public googleLogin(): void {
    this.authService.GoogleAuth();
  }

  public launchResetPassword() {
    this.modal.close();
    this.forgotPasswordmodal.open();
  }

  public resetLoginForm() {
    this.validateForm.reset();
    this.modal.close();
  }

  public resetForgotPasswordForm() {
    this.forgotPasswordForm.reset();
    this.forgotPasswordmodal.close();
  }

  submitForgotPageForm() {
    this.loading = true;
    if (this.forgotPasswordForm.valid) {
      const { email } = this.forgotPasswordForm.value;

      if (email) {
        sendPasswordResetEmail(this.auth, email)
          .then(data => {
            this.loading = false;
            this.veoliaMessageService.create(
              {
                icon: 'check',
                title: 'Success',
                content: 'Email sent',
              },
              { duration: 3000 }
            );
            this.loading = false;
            this.loading = false;
            this.resetForgotPasswordForm();
          })
          .catch(error => {
            this.veoliaMessageService.create(
              {
                icon: 'error',
                title: 'Error',
                content: 'Error on password reset',
              },
              { duration: 3000 }
            );
            this.loading = false;
            this.resetForgotPasswordForm();
          });
      }
    }
  }

  submitForm(): void {
    if (this.validateForm.valid) {
      const { userName, password } = this.validateForm.value;
      if (userName && password) {
        this.loading = true;
        this.submitLoginForm({ userName, password });
      }
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
