import { Injectable } from '@angular/core';
import { BaseApi } from './api';
import { Observable } from 'rxjs';
import { CommentAddedDTO } from '../dtos/comment-added.dto';

@Injectable()
export class CommentApi extends BaseApi {
  notifyCommentAdd(
    solution_id: string,
    data: CommentAddedDTO
  ): Observable<any> {
    return this.post(`/api/comments/notify/comment_add/${solution_id}`, data);
  }
}
