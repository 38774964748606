<div id="login-option">
  <div class="login-option_logo">
    <img
      src="/assets/logos/dla-logo.png"
      alt="DLA Logo"
      width="167"
      height="61" />
  </div>
  <div class="login-option_description">
    <p>
      <span class="dt">{{
        'features.authentication.pages.login.dla_description_one' | translate
      }}</span>
      {{
        'features.authentication.pages.login.dla_description_two' | translate
      }}
    </p>
  </div>
  <div class="login-option_buttons">
    <div class="login-option_buttons_button_container">
      <div class="login-option_buttons_button_header">
        <h3>
          {{ 'features.authentication.pages.login.veolia_user' | translate }}
        </h3>
      </div>
      <button
        nz-button
        nzType="primary"
        nzSize="large"
        (click)="loginWithGoogle()"
        nzBlock>
        <span nz-icon nzType="google"></span>
        <span>{{
          'features.authentication.pages.login.buttons.signInWithGoogle'
            | translate
        }}</span>
      </button>
    </div>
    <div class="login-option_buttons_button_container">
      <div class="login-option_buttons_button_header">
        <h3>
          {{ 'features.authentication.pages.login.external_user' | translate }}
        </h3>
      </div>
      <button
        nz-button
        nzType="primary"
        nzSize="large"
        (click)="loginWithUsernameAndPassword()"
        nzBlock>
        <span nz-icon nzType="user"></span>
        <span>{{
          'features.authentication.pages.login.buttons.email_and_password'
            | translate
        }}</span>
      </button>
    </div>
  </div>
  <div class="login-option_logo_tcc">
    <img
      src="/assets/logos/tcc-logo.png"
      alt="TCC Logo"
      width="100"
      height="40" />
  </div>
</div>
