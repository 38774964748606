import { Component } from '@angular/core';
import { FormArray, FormGroup, AbstractControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { DemandFormStore } from './demand-form.store';
import { provideComponentStore } from '@ngrx/component-store';
import { Timestamp } from '@angular/fire/firestore';

@Component({
  selector: 'app-demand-form',
  templateUrl: './demand-form.component.html',
  styleUrls: ['./demand-form.component.scss'],
  providers: [provideComponentStore(DemandFormStore)],
})
export class DemandFormComponent extends FieldType {
  vm$ = this.demandFormStore.vm$;

  constructor(private demandFormStore: DemandFormStore) {
    super();
  }

  setSelected(id: string) {
    this.demandFormStore.setSelectedId(id);
  }

  onSubmit(id: string) {
    if (id === '1') {
      this.validateFields(['disclaimer', 'Q0_A', 'Q0_B', 'Q0_C', 'type'], id);
    }

    if (id === '2') {
      const demandType = this.formControl.get('type')?.value;

      if (demandType === 'SaaS' || demandType === undefined) {
        this.validateFields(
          [
            'rfpName',
            'domainOfSolution',
            'description',
            'accessQuestion',
            'integrateQuestion',
            'genaiQuestion',
            'interconnectQuestion',
            'interconnectQuestion2',
            'communicationQuestion',
            'expectedEvaluationDate',
            'expectedCompletionDate',
            'expectedOnlineReleaseDate',
            'nbSolution',
          ],
          id
        );
      } else {
        this.validateFields([
          'editorBrand',
          'description',
          'domainOfSolution',
          'inPlace',
          'apmNumber',
          'link',
          'comment',
          'expectedCompletionDate',
          'expectedOnlineReleaseDate',
        ]);
      }
    }

    if (id === '3') {
      (<FormArray>this.form.get('solutions')).controls.forEach(
        (formCtrl: AbstractControl<any, any>) => {
          Object.keys((<FormGroup>formCtrl).controls).forEach(key => {
            (<FormGroup>formCtrl).controls[key].markAsTouched();
            (<FormGroup>formCtrl).controls[key].updateValueAndValidity();
          });
        }
      );

      if (this.form.valid) {
        if (this.formControl.get('type')?.value === 'SaaS') {
          this.createSaaSSolution(this.form.value);
        }
      }
    }
  }

  validateFields(fieldsToValidate: string[], id?: string) {
    let errors = 0;
    fieldsToValidate.forEach(field => {
      this.formControl.get(field)?.markAsTouched();
      this.formControl.get(field)?.updateValueAndValidity();
      if (this.formControl.get(field)?.invalid) {
        errors++;
      }
    });

    if (errors == 0 && id) {
      const nextId = (parseInt(id!) + 1).toString();
      this.demandFormStore.setUnlockeds([nextId]);
      this.setSelected(nextId);
    }
  }

  getButtonTitle(id: string) {
    if (this.formControl.get('type')?.value === 'SaaS' && id === '2') {
      return 'Save';
    }

    if (this.formControl.get('type')?.value !== 'SaaS' && id === '1') {
      return 'Save';
    }

    return 'Next';
  }

  createSaaSSolution(data: any) {
    const payloads = [];
    for (let i = 0; i <= data.nbSolution - 1; i++) {
      const payload = {
        Q0_A: data.Q0_A,
        Q0_B: data.Q0_B,
        Q0_C: data.Q0_C,
        type: data.type,
        rfpName: data.rfpName,
        domainOfSolution: data.domainOfSolution,
        description: data.description,
        accessQuestion: data.accessQuestion,
        integrateQuestion: data.integrateQuestion,
        genaiQuestion: data.genaiQuestion,
        interconnectQuestion: data.interconnectQuestion,
        interconnectQuestion2: data.interconnectQuestion2,
        communicationQuestion: data.communicationQuestion,
        expectedEvaluationDate: Timestamp.fromDate(
          new Date(data.expectedEvaluationDate)
        ),
        expectedCompletionDate: Timestamp.fromDate(
          new Date(data.expectedCompletionDate)
        ),
        expectedOnlineReleaseDate: Timestamp.fromDate(
          new Date(data.expectedOnlineReleaseDate)
        ),
        nbSolution: data.nbSolution,
        solutionName: data.solutions[i].solutionName,
        editorBrand: data.solutions[i].editorBrand,
        inPlace: data.solutions[i].inPlace,
        apmNumber: data.solutions[i].apmNumber ?? null,
        link: data.solutions[i].link ?? null,
        mainEmailVendor: data.solutions[i].mainEmailVendor,
        mainFirstnameVendor: data.solutions[i].mainFirstnameVendor,
        mainLastnameVendor: data.solutions[i].mainLastnameVendor,
        mainCompanyVendor: data.solutions[i].mainCompanyVendor,
        linkCom: data.solutions[i].linkCom ?? null,
        emailVendorAuthorized: [data.solutions[i].mainEmailVendor],
        emailEvaluatorAuthorized: [],
        requestDate: Timestamp.fromDate(new Date(Date.now())),
        lastUpdate: 'No forms completed yet', //en fonction du dernier form mis à jour
        status: 'Pending',
        designStatus: 'New',
        legalStatus: 'New',
        article28Status: 'New',
        article32Status: 'New',
        jsonFR: 'fr-FR-v1',
        jsonUS: 'en-US-v1',
        fullyEvaluated: false,
      };

      payloads.push(payload);
    }

    this.demandFormStore.setFormData(payloads);
    this.demandFormStore.createSaaSSolution();
  }
}
