import { NgModule } from '@angular/core';
import { SolutionsRoutingModule } from './solutions-routing.module';
import { AssessmentsSummaryPageComponent } from './pages/assessments-summary-page/assessments-summary-page.component';
import { LayoutModule } from 'src/app/shared/features/layout/layout.module';
import { DocumentationModule } from 'src/app/shared/features/documentation/documentation.module';
import { NzListModule } from 'ng-zorro-antd/list';
import { CommonModule } from '@angular/common';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { LegalFormService } from 'src/app/core/services/legal-form.service';
import { DesignFormService } from 'src/app/core/services/design-form.service';
import { Article28FormService } from 'src/app/core/services/article-28-form.service';
import { Article32FormService } from 'src/app/core/services/article-32-form.service';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { ReservationService } from 'src/app/core/services/reservation.service';
import { SummaryReservationOverviewComponent } from './pages/assessments-summary-page/components/summary-reservation-overview/summary-reservation-overview.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReservationOverviewComponent } from './pages/assessments-summary-page/components/reservation-overview/reservation-overview.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { FormSummaryPanelComponent } from './pages/assessments-summary-page/components/form-summary-panel/form-summary-panel.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTableModule } from 'ng-zorro-antd/table';
import { EvaluationItemComponent } from './pages/assessments-summary-page/components/evaluation-item/evaluation-item.component';
import { SectionHeaderComponent } from './pages/assessments-summary-page/components/section-header/section-header.component';
import { CommentBlockComponent } from './pages/assessments-summary-page/components/comment-block/comment-block.component';
import { FileService } from 'src/app/core/services/file.service';
import { ReservationApi } from 'src/app/core/apis/reservation.api';
import { LogsTabIconComponent } from './pages/logs-page/components/logs-tab-icon/logs-tab-icon.component';
import { FormLogsComponent } from './pages/logs-page/components/form-logs/form-logs.component';
import { LogsPageComponent } from './pages/logs-page/logs-page.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { LogService } from 'src/app/core/services/log.service';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { VeoliaChipComponent } from '@veolia.com/vds-angular-components/chips';
import { VeoliaTagComponent } from '@veolia.com/vds-angular-components/tag';
import { VeoliaSegmentedComponent } from '@veolia.com/vds-angular-components/segmented';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';
import { VeoliaLinkComponent } from '@veolia.com/vds-angular-components/link';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import { GoBackComponent } from 'src/app/shared/features/go-back/go-back.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { SolutionShareComponent } from 'src/app/shared/features/solution-share/components/solution-share.component';
import { AccessRightsModule } from 'src/app/shared/features/access-rights/access-rights.module';
import {
  VeoliaTabComponent,
  VeoliaTabsComponent,
} from '@veolia.com/vds-angular-components/tabs';
import { SolutionInfoSummaryModule } from 'src/app/shared/features/solution-info-summary/solution-info-summary.module';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';
import { EvaluationSummaryModule } from 'src/app/shared/features/evaluation-summary/evaluation-summary.module';

@NgModule({
  declarations: [
    AssessmentsSummaryPageComponent,
    CommentBlockComponent,
    EvaluationItemComponent,
    FormSummaryPanelComponent,
    ReservationOverviewComponent,
    SectionHeaderComponent,
    SummaryReservationOverviewComponent,
    LogsPageComponent,
    LogsTabIconComponent,
    FormLogsComponent,
  ],
  imports: [
    CommonModule,
    VeoliaModalModule,
    DocumentationModule,
    VeoliaModalModule,
    FormsModule,
    LayoutModule,
    NzBadgeModule,
    NzButtonModule,
    NzCollapseModule,
    NzCardModule,
    NzDatePickerModule,
    NzDescriptionsModule,
    NzDividerModule,
    NzFormModule,
    NzGridModule,
    NzInputModule,
    NzListModule,
    NzModalModule,
    NzRadioModule,
    NzResultModule,
    NzSkeletonModule,
    NzSpaceModule,
    NzSwitchModule,
    NzTableModule,
    NzTabsModule,
    NzTagModule,
    NzTimelineModule,
    NzTypographyModule,
    NzNotificationModule,
    ReactiveFormsModule,
    SolutionsRoutingModule,
    AccessRightsModule,
    VeoliaChipComponent,
    VeoliaIconComponent,
    VeoliaModalModule,
    VeoliaSegmentedComponent,
    VeoliaTagComponent,
    VeoliaLinkComponent,
    VeoliaButtonComponent,
    GoBackComponent,
    NzDropDownModule,
    SolutionShareComponent,
    VeoliaTabsComponent,
    VeoliaTabComponent,
    SolutionInfoSummaryModule,
    EvaluationSummaryModule,
    AccessRightsModule,
    TranslateModule,
  ],
  providers: [
    Article28FormService,
    Article32FormService,
    DesignFormService,
    EvaluationService,
    FileService,
    LegalFormService,
    LogService,
    ReservationApi,
    ReservationService,
    TranslateService,
  ],
  exports: [],
})
export class SolutionsModule {}
