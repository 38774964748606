import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import HttpMapper from '../mappers/http.mapper';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export abstract class BaseApi {
  constructor(private http: HttpClient) {}

  protected get<T>(url: string, params?: any, options?: any): Observable<any> {
    const paramsObj = params ? params : {};
    return this.http.get<T>(url, {
      ...httpOptions,
      ...options,
      params: HttpMapper.mapToHttpParams(paramsObj),
    });
  }

  protected post<T>(url: string, data: any, options?: any): Observable<any> {
    return this.http.post<T>(url, data, { ...httpOptions, ...options });
  }
}
