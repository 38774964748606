import { NgModule } from "@angular/core";
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { CommonModule } from "@angular/common";

import { NotFoundComponent } from "./components/not-found.component";

@NgModule({
  declarations: [
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    NzResultModule,
    NzButtonModule
  ],
  exports: [
    NotFoundComponent
  ],
})
export class NotFoundModule {}
