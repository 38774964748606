<app-layout-page-header>
  <veolia-page-header [variant]="'light'">
    <div veolia-page-header-title style="display: flex">
      <veolia-icon
        class="backbtn"
        (click)="backtoAdminHome()"
        title="Back to Admin Home"
        [size]="24"
        [name]="'arrow_back'"></veolia-icon>
      Users administration
    </div>

    <div veolia-page-header-bottom>
      <veolia-segmented
        *ngIf="vm$ | async as vm"
        [options]="[
          { label: 'Veolia users', value: 0 },
          { label: 'Vendor users', value: 1 }
        ]"
        (handleIndexChange)="onUserTypeChange($event)"
        [variant]="'primary'"
        [selectedIndex]="vm.userType"
        [size]="'default'" />

      <div class="right-actions">
        <app-form-caption></app-form-caption>
        <app-documentation></app-documentation>
      </div>
    </div>
  </veolia-page-header>
</app-layout-page-header>

<div class="layout-body">
  <div class="layout-body-content">
    <ng-container *ngIf="vm$ | async as vm">
      <nz-tabset>
        <nz-tab [nzTitle]="vm.userType === 0 ? 'Veolia users' : 'Vendor users'">
          <app-admin-all-users [userType]="vm.userType"></app-admin-all-users>
        </nz-tab>
      </nz-tabset>
    </ng-container>
  </div>
</div>
