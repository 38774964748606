import { FormStatus } from '../enums/form-status';

export function getTagColor(
  status: string
):
  | 'default'
  | 'error'
  | 'warning'
  | 'success'
  | 'information'
  | 'purple'
  | 'orange'
  | 'lightblue'
  | undefined {
  switch (status) {
    case FormStatus.NEW:
      return 'default';
    case FormStatus.EMPTY:
      return 'default';
    case FormStatus.NOT_APPLICABLE:
      return 'default';
    case FormStatus.VENDOR_IS_RESPONDING:
      return 'information';
    case FormStatus.COMPLETED:
      return 'purple';
    case FormStatus.ONGOING:
      return 'information';
    case FormStatus.DLA_IS_REVIEWING:
      return 'information';
    case FormStatus.ON_HOLD:
      return 'warning';
    case FormStatus.EMPTY_EVAL:
      return;
    case FormStatus.RESERVATIONS:
      return 'orange';
    case FormStatus.RECOMMENDED_RESERVATIONS:
      return 'orange';
    case FormStatus.PENDING:
      return 'warning';
    case FormStatus.CANCELLED:
      return 'error';
    case FormStatus.NOT_RECOMMENDED:
      return 'error';
    case FormStatus.RECOMMENDED:
      return 'success';
    default:
      return undefined;
  }
}
