<veolia-table
  [datasource]="users"
  size="small"
  [showPagination]="false"
  [hideSelection]="true">
  <veolia-table-column [width]="34">
    <veolia-table-header></veolia-table-header>
    <veolia-table-cell [withoutBackground]="true">
      <ng-template let-row>
        <veolia-link
          size="small"
          icon="info"
          [iconFilled]="true"
          (click)="launchSummaryUserModal(row)">
        </veolia-link>
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>
  <veolia-table-column>
    <veolia-table-header>Uid</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>{{ row.uid }} </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column
    [sortDirections]="['ascend', 'descend', null]"
    [sortPriority]="1">
    <veolia-table-header>Email</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>{{ row.email }} </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column *ngIf="userType === 0">
    <veolia-table-header>Full Name</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>{{ row.displayName }}</ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column *ngIf="userType === 1">
    <veolia-table-header>Firstname</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>{{ row.firstname }}</ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column *ngIf="userType === 1">
    <veolia-table-header>Lastame</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>{{ row.lastname }}</ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column *ngIf="userType === 1">
    <veolia-table-header>Status</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        <p *ngIf="row.disable === false">Enabled</p>
        <p *ngIf="row.disable === true">Disabled</p>
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column *ngIf="userType === 0">
    <veolia-table-header>Role</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        {{ row.role }}
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column *ngIf="userType === 0">
    <veolia-table-header>Business Unit</veolia-table-header>
    <veolia-table-cell>
      <ng-template let-row>
        {{ row.businessUnit }}
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column fixedRight [width]="34" *ngIf="userType === 0">
    <veolia-table-header></veolia-table-header>
    <veolia-table-cell [withoutBackground]="true">
      <ng-template let-row>
        <a nz-dropdown [nzDropdownMenu]="menu">
          <span nz-icon nzType="more"></span>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item>
              <veolia-link
                *ngIf="hasAction('manage')"
                size="small"
                icon="manage_accounts"
                (click)="launchEditRolesUserModal(row)">
                Edit the user rights and BU
              </veolia-link>
            </li>
          </ul>
        </nz-dropdown-menu>
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>

  <veolia-table-column fixedRight [width]="34" *ngIf="userType === 1">
    <veolia-table-header></veolia-table-header>
    <veolia-table-cell [withoutBackground]="true">
      <ng-template let-row>
        <a nz-dropdown [nzDropdownMenu]="menu">
          <span nz-icon nzType="more"></span>
        </a>
        <nz-dropdown-menu #menu="nzDropdownMenu">
          <ul nz-menu nzSelectable>
            <li nz-menu-item>
              <veolia-link
                *ngIf="hasAction('reset_password')"
                size="small"
                icon="mail_lock"
                (click)="launchResetUserPassword(row)">
                Reset user password
              </veolia-link>
            </li>
            <li nz-menu-item>
              <veolia-link
                *ngIf="hasAction('disabled') && row.disable === false"
                size="small"
                icon="person_off"
                (click)="launchDisableUserAccount(row)">
                Disable user account
              </veolia-link>
              <veolia-link
                *ngIf="hasAction('enabled') && row.disable === true"
                size="small"
                icon="person_add"
                (click)="launchEnableUserAccount(row)">
                Enable user account
              </veolia-link>
            </li>
          </ul>
        </nz-dropdown-menu>
      </ng-template>
    </veolia-table-cell>
  </veolia-table-column>
</veolia-table>

<app-user-summary #userSummaryModal />

<app-user-management #userManagementModal />
