import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-evaluation-comment',
  templateUrl: './evaluation-comment.component.html',
  styleUrls: ['./evaluation-comment.component.scss'],
})
export class EvaluationComponent {
  @Input() content?: string;
  @Input() title?: string;
}
