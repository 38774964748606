<veolia-modal #modal [closable]="true">
  <div veolia-modal-title>Legal Notices</div>
  <div veolia-modal-subtitle>Application version v3.0.64-27/11/2024</div>

  <div veolia-modal-content>
    <section style="text-align: justify">
      <p translate>cgu_text1</p>
      <ul>
        <li translate>pro_email</li>
        <li translate>first_and_lastname</li>
        <li translate>google_picture</li>
        <li translate>bu</li>
        <li translate>comments</li>
        <li translate>files</li>
        <li translate>logs</li>
      </ul>
      <p translate>cgu_text2</p>
      <p translate>cgu_text3</p>

      <br />

      <p translate>cgu_text4</p>

      <br />

      <p translate>cgu_text5</p>

      <br />

      <p translate>cgu_text6</p>
    </section>
  </div>

  <div veolia-modal-footer></div>
</veolia-modal>
