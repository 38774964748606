import { NgModule } from '@angular/core';

import { NzSpinModule } from 'ng-zorro-antd/spin';
import { SimpleSpinnerComponent } from './components/simple-spinner/simple-spinner.component';
import { SimpleSpinnerPlaceholderComponent } from './components/simple-spinner-placeholder/simple-spinner-placeholder.component';

@NgModule({
  declarations: [SimpleSpinnerComponent, SimpleSpinnerPlaceholderComponent],
  imports: [NzSpinModule],
  exports: [SimpleSpinnerComponent, SimpleSpinnerPlaceholderComponent],
  providers: [],
})
export class SimpleSpinnerModule {}
