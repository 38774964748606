import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { DemandFormEditStore } from './demand-form-edit.store';
import { provideComponentStore } from '@ngrx/component-store';
import { Timestamp } from '@angular/fire/firestore';
import { FormGroup, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-demand-form-edit',
  templateUrl: './demand-form-edit.component.html',
  styleUrls: ['./demand-form-edit.component.scss'],
  providers: [provideComponentStore(DemandFormEditStore)],
})
export class DemandFormEditComponent extends FieldType implements OnInit {
  vm$ = this.demandFormStore.vm$;

  constructor(
    private demandFormStore: DemandFormEditStore,
    private demandFormEditStore: DemandFormEditStore
  ) {
    super();
  }

  ngOnInit(): void {
    console.log(this.model);
    this.demandFormEditStore.setSolutionName(this.model.solutionName);
  }

  setSelected(id: string) {
    this.demandFormStore.setSelectedId(id);
  }

  onSubmit(id: string) {
    console.log('id', id);
    if (id === '1') {
      this.validateFields(['disclaimer', 'Q0_A', 'Q0_B', 'Q0_C', 'type'], id);
    }

    if (id === '2') {
      const demandType = this.formControl.get('type')?.value;

      if (demandType === 'SaaS' || demandType === undefined) {
        this.validateFields(
          [
            'fullNameInitiatorVeolia',
            'emailInitiatorVeolia',
            'solutionOwner',
            'businessUnitOfSolution',
          ],
          id
        );
      }
    }

    if (id === '3') {
      const demandType = this.formControl.get('type')?.value;

      if (demandType === 'SaaS' || demandType === undefined) {
        this.validateFields(
          [
            'fullNameInitiatorVeolia',
            'emailInitiatorVeolia',
            'solutionOwner',
            'businessUnitOfSolution',
            'requestDate',
            'rfpName',
            'domainOfSolution',
            'description',
            'accessQuestion',
            'integrateQuestion',
            'genaiQuestion',
            'interconnectQuestion',
            'interconnectQuestion2',
            'communicationQuestion',
            'expectedEvaluationDate',
            'expectedCompletionDate',
            'expectedOnlineReleaseDate',
            'nbSolution',
          ],
          id
        );
      } else {
        this.validateFields([
          'solutionName',
          'editorBrand',
          'description',
          'domainOfSolution',
          'inPlace',
          'apmNumber',
          'link',
          'comment',
          'expectedCompletionDate',
          'expectedOnlineReleaseDate',
        ]);
      }
    }

    if (id === '4') {
      this.validateFields(
        [
          'fullNameInitiatorVeolia',
          'emailInitiatorVeolia',
          'solutionOwner',
          'businessUnitOfSolution',
          'requestDate',
          'rfpName',
          'accessQuestion',
          'integrateQuestion',
          'genaiQuestion',
          'interconnectQuestion',
          'interconnectQuestion2',
          'communicationQuestion',
          'expectedEvaluationDate',
          'expectedCompletionDate',
          'expectedOnlineReleaseDate',
          'nbSolution',
          'solutionName',
          'editorBrand',
          'description',
          'domainOfSolution',
          'inPlace',
          'apmNumber',
          'link',
          'mainEmailVendor',
          'mainLastnameVendor',
          'mainFirstnameVendor',
          'mainCompanyVendor',
          'linkCom',
        ],
        id
      );

      if (this.form.dirty) {
        this.getFormValidationErrors(this.form!)
      }
      if (this.form.valid) {
        this.updateSolution(this.form.value);
      }
    }
  }

  validateFields(fieldsToValidate: string[], id?: string) {
    let errors = 0;
    fieldsToValidate.forEach(field => {
      this.formControl.get(field)?.markAsTouched();
      this.formControl.get(field)?.updateValueAndValidity();
      if (this.formControl.get(field)?.invalid) {
        errors++;
      }
    });

    if (errors == 0 && id) {
      const nextId = (parseInt(id) + 1).toString();
      // if (nextId !== '3') {
      this.demandFormStore.setUnlockeds([nextId]);
      this.setSelected(nextId);
      // }
    }
  }

  getButtonTitle(id: string) {
    if (id === '3') {
      return 'Save';
    }

    return 'Next';
  }

  filteredDict = (dict: { [s: string]: unknown } | ArrayLike<unknown>) =>
    Object.fromEntries(
      Object.entries(dict).filter(([_, value]) => value !== undefined)
    );

  updateSolution(data: any) {
    const payload = data;
    if (data.expectedEvaluationDate) {
      payload.expectedEvaluationDate = Timestamp.fromDate(
        new Date(data.expectedEvaluationDate)
      );
    }

    if (data.expectedCompletionDate) {
      payload.expectedCompletionDate = Timestamp.fromDate(
        new Date(data.expectedCompletionDate)
      );
    }

    if (data.expectedOnlineReleaseDate) {
      payload.expectedOnlineReleaseDate = Timestamp.fromDate(
        new Date(data.expectedOnlineReleaseDate)
      );
    }

    if (data.requestDate) {
      payload.requestDate = Timestamp.fromDate(new Date(data.requestDate));
    }

    //console.log("PAYLOAD", payload);

    this.demandFormStore.setSolutionId(this.model.uid);
    this.demandFormStore.setFormData([this.filteredDict(payload)]);
    this.demandFormStore.updateSolution();
  }

  getFormValidationErrors(form: any) {
    Object.keys(form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key)!.errors!;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log({
            control: key,
            error: keyError,
            value: controlErrors[keyError],
          });
        });
      }
    });
  }
}
