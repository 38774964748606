import { BusinessUnit } from "../../models/business-unit";
import { User } from "../../models/user";

export type DemandFormEditStoreState<T> = {
  solutionId: string;
  selectedId?: string;
  unlockeds: string[];
  loading?: boolean;
  currentUser?: User;
  businessUnit?: BusinessUnit;
  solutionName?: string;
  evaluatorList?: string[];
  veoliaList?: string[];
  formData?: any[];
}


export const demandFormEditStoreInitialState = {
  solutionId: '',
  selectedId: '1',
  unlockeds: ['1', '2', '3', '4'],
  loading: false,
};
