import { NgModule } from '@angular/core';
import { DocumentationComponent } from './components/documentation/documentation.component';
import { CommonModule } from '@angular/common';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { DocumentationItemComponent } from './components/documentation-item/documentation-item.component';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';

@NgModule({
  declarations: [DocumentationComponent, DocumentationItemComponent],
  imports: [
    CommonModule,
    NzButtonModule,
    NzModalModule,
    VeoliaButtonComponent,
    VeoliaModalModule,
  ],
  providers: [],
  exports: [DocumentationComponent],
})
export class DocumentationModule {}
