import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { LoginPageStore } from '../../pages/login-page/login-page.store';
import { provideComponentStore } from '@ngrx/component-store';
import { VeoliaModalService } from '@veolia.com/vds-angular-components/modal';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  providers: [provideComponentStore(LoginPageStore)],
})
export class LoginFormComponent {
  @Input() isVisible = false;
  @Output() onClose = new EventEmitter<void>();
  @Output() onSubmit = new EventEmitter<{
    userName: string;
    password: string;
  }>();

  @ViewChild('forgotPassword') content!: TemplateRef<string>;
  #service = inject(VeoliaModalService);

  isOkLoading = false;

  validateForm: FormGroup<{
    userName: FormControl<string>;
    password: FormControl<string>;
  }> = this.fb.group({
    userName: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  constructor(private fb: NonNullableFormBuilder) {}

  handleOk(): void {
    this.isOkLoading = true;
    this.submitForm();
    setTimeout(() => {
      this.onClose.emit();
      this.isOkLoading = false;
    }, 3000);
  }

  handleCancel(): void {
    this.onClose.emit();
  }

  launchResetPassword() {
    this.#service.open({
      title: 'Forgot Password',
      content: this.content,
    });
  }

  submitForm(): void {
    if (this.validateForm.valid) {
      const { userName, password } = this.validateForm.value;
      if (userName && password) {
        this.onSubmit.emit({ userName, password });
      }
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
