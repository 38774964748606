import { Component, ChangeDetectionStrategy, Type } from '@angular/core';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
} from '@ngx-formly/core';
import { FormlyFieldProps } from '@ngx-formly/ng-zorro-antd/form-field';

type InputProps = FormlyFieldProps;

export interface FormlyInputFieldConfig extends FormlyFieldConfig<InputProps> {
  type: 'password-input' | Type<FormlyFieldPasswordInputComponent>;
}

@Component({
  selector: 'app-password-input',
  template: `
    <div class="row">
      <veolia-input
        [maxlength]="props.maxLength!"
        [disabled]="props.disabled!"
        [placeholder]="props.placeholder!"
        [formlyAttributes]="field"
        [ngModel]="formControl.value"
        (ngModelChange)="onChange($event)"
        [disabled]="props.disabled!"
        [type]="this.visibility ? 'text' : 'password'"
        [addonAfter]="after"
        nz-input />
      <ng-template #after>
        <veolia-icon
          [name]="this.visibility ? 'visibility_off' : 'visibility'"
          (click)="visibilityClick()" />
      </ng-template>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldPasswordInputComponent extends FieldType<
  FieldTypeConfig<InputProps>
> {
  visibility = false;

  onChange(event: any) {
    this.formControl.markAsDirty();
    this.formControl.setValue(event);
    this.formControl.markAsTouched();
  }

  visibilityClick() {
    this.visibility = !this.visibility;
  }
}
