import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { EvaluatorSolutionsStore } from './evaluator-solutions.store';
import { AuthService } from '../../../../core/services/auth.service';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SolutionService } from 'src/app/core/services/solution.service';
import { OwnedSolutionsStore } from '../owned-solutions/owned-solutions.store';
import { FollowedSolutionsStore } from '../followed-solutions/followed-solutions.store';

@Component({
  selector: 'app-home-page-evaluator-solutions',
  templateUrl: './evaluator-solutions.component.html',
  styleUrls: ['./evaluator-solutions.component.scss'],
  providers: [
    OwnedSolutionsStore,
    FollowedSolutionsStore,
    EvaluatorSolutionsStore,
  ],
})
export class EvaluatorSolutionsComponent
  extends DataTableComponent
  implements OnChanges, OnInit
{
  @Input() solutionType = 0;

  vm$ = this.evaluatorSolutionsStore.vm$;

  constructor(
    public override authService: AuthService,
    public evaluatorSolutionsStore: EvaluatorSolutionsStore,
    public ownedSolutionsStore: OwnedSolutionsStore,
    public followedSolutionsStore: FollowedSolutionsStore,
    public override translateService: TranslateService,
    public override router: Router,
    public override solutionService: SolutionService
  ) {
    super(authService, router, translateService, solutionService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['solutionType']['firstChange']) {
      this.evaluatorSolutionsStore.changeSolutionType(
        changes['solutionType'].currentValue
      );
    }
  }

  ngOnInit(): void {
    this.authService.userData.subscribe(user => {
      if (user) {
        this.evaluatorSolutionsStore.setEmail(user?.email);
        this.evaluatorSolutionsStore.setSolutionType(this.solutionType);
        this.evaluatorSolutionsStore.fetchData();
      }
    });
  }

  changePage(page: number) {
    this.evaluatorSolutionsStore.changePage(page);
  }

  changeSize(size: Event) {
    const limit = parseInt((size.target as HTMLSelectElement).value);
    this.evaluatorSolutionsStore.changeLimit(limit);
  }

  onSearch(event: string) {
    this.evaluatorSolutionsStore.changeSearch(event);
  }

  onRefreshAfterEmit() {
    this.evaluatorSolutionsStore.getSolutions();
    //this.ownedSolutionsStore.refreshSolutions();
    //this.followedSolutionsStore.refreshSolutions();
  }
}
