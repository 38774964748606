import { AbstractControl } from "@angular/forms";

// Check date if completeDate is after evaluationDate
export function IsCompletionAfterEvaluationDate(control: AbstractControl) {
  const value = control.value;
  const evaluationDate = control.parent?.get('expectedEvaluationDate')?.value;
  if (value && evaluationDate && value > evaluationDate) {
    return { 'IsCompletionAfterEvaluationDate': true };
  }
  return null;
}

export function IsCompletionAfterEvaluationDateMessage(err: any, field: any) {
  return 'Completion date must not be before evaluation date';
}

// check if evaluation date is before completion date
export function IsEvaluationBeforeCompletionDate(control: AbstractControl) {
  const value = control.value;
  const completionDate = control.parent?.get('expectedCompletionDate')?.value;

  if (value && completionDate && value < completionDate) {
    return { 'IsEvaluationBeforeCompletionDate': true };
  }
  return null;
}

export function IsEvaluationBeforeCompletionDateMessage(err: any, field: any) {
  return 'Evaluation date must not be before completion date';
}
