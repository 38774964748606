import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { FirstConnectionPageComponent } from './pages/first-connection-page/first-connection-page.component';
import { BaseLayoutComponent } from '../../shared/features/layout/components/base-layout/base-layout.component';
import { NgModule } from '@angular/core';
import { LoginGuard } from 'src/app/core/guards/login.guard';
import { TermsGuard } from 'src/app/core/guards/terms.guard';
import { EditPasswordPageComponent } from './pages/edit-password-page/edit-password-page.component';
import { PasswordChangedGuard } from 'src/app/core/guards/password-changed.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'first-connection',
    canActivate: [TermsGuard],
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        component: FirstConnectionPageComponent,
      },
    ],
  },
  {
    path: 'edit-first-password',
    canActivate: [PasswordChangedGuard],
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        component: EditPasswordPageComponent,
      },
    ],
  },
  {
    path: 'edit-password',
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        component: EditPasswordPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
