import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SolutionService } from '../services/solution.service';
import { catchError, from, map, Observable, of } from 'rxjs';
import { DocumentSnapshot } from '@angular/fire/firestore';

export const SolutionResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  solutionService: SolutionService = inject(SolutionService),
  router: Router = inject(Router)
): Observable<any> => {
  const solutionId = route.paramMap.get('id');

  if (!solutionId) {
    router.navigate(['/not-found']);
    return of(null);
  }

  return from(solutionService.get(solutionId)).pipe(
    map((docSnapshot: DocumentSnapshot<any>) => {
      if (docSnapshot.exists()) {
        return {
          id: docSnapshot.id,
          ...docSnapshot.data(),
          uid: docSnapshot.id,
        };
      } else {
        throw new Error('Document does not exist');
      }
    }),
    catchError(error => {
      let errorPage = '/error';

      if (error.message === 'Document does not exist') {
        errorPage = '/not-found';
      } else if (error.code === 'permission-denied') {
        errorPage = '/unauthorized';
      }

      router.navigate([errorPage]);
      return of(null);
    })
  );
};
