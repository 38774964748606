import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { NzPaginationComponent } from 'ng-zorro-antd/pagination';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements AfterViewInit, OnDestroy {
  @Input() loading = false;
  @Input() page = 1;
  @Input() size?: number = 15;
  @Input() total = 0;
  @Output() onPageChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() onSizeChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(NzPaginationComponent, { read: ElementRef }) paginationComponent:
    | ElementRef
    | undefined;

  private observer: MutationObserver | undefined;

  constructor() {}

  ngAfterViewInit() {
    this.setupObserver();
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private setupObserver() {
    this.observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList') {
          const paginationInput =
            this.paginationComponent?.nativeElement.querySelector(
              '.ant-pagination-simple-pager input'
            );
          if (paginationInput) {
            this.disablePaginationInput(paginationInput);
            this.observer?.disconnect(); // Stop observing once we've found and disabled the input
          }
        }
      });
    });

    const config = { childList: true, subtree: true };
    this.observer.observe(this.paginationComponent?.nativeElement, config);
  }

  private disablePaginationInput(inputElement: HTMLInputElement) {
    inputElement.disabled = true;
    inputElement.classList.add('disabled-input');
  }

  // Method to re-enable the input if needed
  enablePaginationInput() {
    const paginationInput =
      this.paginationComponent?.nativeElement.querySelector(
        '.ant-pagination-simple-pager input'
      );
    if (paginationInput) {
      paginationInput.disabled = false;
      paginationInput.classList.remove('disabled-input');
    }
  }

  changePage(page: number) {
    this.onPageChange.emit(page);
  }

  changeSize(size: any) {
    this.onSizeChange.emit(size);
  }
}
