import { NgModule } from '@angular/core';
import { AccessRightsComponent } from './components/access-rights/access-rights.component';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { CommonModule } from '@angular/common';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { AccessRightButtonComponent } from './components/access-right-button/access-right-button.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SimpleSpinnerModule } from '../simple-spinner/simple-spinner.module';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { AccessRightUserComponent } from './components/access-right-user/access-right-user.component';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import SolutionApi from 'src/app/core/apis/solution.api';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import { VeoliaSegmentedComponent } from '@veolia.com/vds-angular-components/segmented';
import { TranslateModule } from '@ngx-translate/core';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';
import { BusinessUnitService } from 'src/app/core/services/business-unit.service';

@NgModule({
  declarations: [
    AccessRightsComponent,
    AccessRightButtonComponent,
    AccessRightUserComponent,
  ],
  imports: [
    CommonModule,
    VeoliaModalModule,
    NzAlertModule,
    NzSegmentedModule,
    NzListModule,
    NzRadioModule,
    NzDividerModule,
    NzFormModule,
    NzMessageModule,
    NzSelectModule,
    NzInputModule,
    NzButtonModule,
    NzSpinModule,
    NzTypographyModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleSpinnerModule,
    TranslateModule,
    NgxMaskDirective,
    NgxMaskPipe,
    VeoliaButtonComponent,
    VeoliaSegmentedComponent,
  ],
  exports: [AccessRightsComponent],
  providers: [
    provideNgxMask(),
    SolutionApi,
    AuthService,
    UserService,
    BusinessUnitService,
  ],
})
export class AccessRightsModule {}
