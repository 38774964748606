<veolia-modal #modal [closable]="true">
  <div veolia-modal-title>Contact Form</div>
  <div veolia-modal-subtitle>
    Contact us about a solution/request or about an other subject ?
  </div>

  <div veolia-modal-content>
    <form
      nz-form
      [nzLayout]="'vertical'"
      [formGroup]="form"
      (ngSubmit)="submit()">
      <formly-form
        [model]="model"
        [fields]="fields"
        [options]="options"
        [form]="form"></formly-form>
    </form>
  </div>

  <div veolia-modal-footer>
    <veolia-button (click)="resetForm()" variant="secondary">
      Reset
    </veolia-button>
    <veolia-button htmlType="submit" (click)="submit()">Send</veolia-button>
  </div>
</veolia-modal>
