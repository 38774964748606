import { Component, Type } from '@angular/core';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
  FormlyFieldProps,
} from '@ngx-formly/core';
import { FormlyFieldRadio } from '@ngx-formly/ng-zorro-antd/radio';

type RadioProps = FormlyFieldProps;

export interface FormlyRadioFieldConfig extends FormlyFieldConfig<RadioProps> {
  type: 'disclaimer' | Type<FormlyFieldRadio>;
}

@Component({
  selector: 'app-form-disclaimer',
  templateUrl: './form-disclaimer.component.html',
  styleUrls: ['./form-disclaimer.component.scss'],
})
export class FormDisclaimerComponent extends FieldType<
  FieldTypeConfig<RadioProps>
> {}
