import { Component } from '@angular/core';
import {
  FieldWrapper,
  FormlyFieldConfig,
  FormlyFieldProps as CoreFormlyFieldProps,
} from '@ngx-formly/core';

export interface FormlyFieldProps extends CoreFormlyFieldProps {
  hideRequiredMarker?: boolean;
  hideLabel?: boolean;
}

@Component({
  selector: 'app-form-divider',
  template: `
    <nz-form-item>
      <ng-container *ngIf="props.label && props.hideLabel !== true">
        <nz-form-label
          [nzRequired]="props.required && props.hideRequiredMarker !== true"
          [nzFor]="id">
          {{ props.label }}
        </nz-form-label>
      </ng-container>
      <nz-form-control
        [nzValidateStatus]="errorState"
        [nzErrorTip]="errorTpl"
        [nzExtra]="props.description">
        <ng-container #fieldComponent></ng-container>
        <ng-template #errorTpl let-control>
          <formly-validation-message
            [field]="field"></formly-validation-message>
        </ng-template>
        <nz-divider class="spacing-v16"></nz-divider>
      </nz-form-control>
    </nz-form-item>
  `,
})
export class FormDividerComponent extends FieldWrapper<
  FormlyFieldConfig<FormlyFieldProps>
> {
  get errorState() {
    return this.showError ? 'error' : '';
  }
}
