<div class="layout-body">
  <div class="layout-body-content">
    <div id="first_connection_page">
      <div>
        <h3 nz-typography>Your personal data</h3>
      </div>
      <div>
        <form
          nz-form
          [nzLayout]="'vertical'"
          [formGroup]="
            isVeolia ? firstConnectionVeoliaForm : firstConnectionExternalForm
          "
          (ngSubmit)="isVeolia ? onSubmitVeolia() : onSubmitExternal()">
          <div *ngIf="vm$ | async as vm">
            <div *ngIf="isVeolia">
              <nz-form-item>
                <nz-form-label>Your country*</nz-form-label>
                <nz-form-control>
                  <nz-select
                    required
                    class="light-gray"
                    nzPlaceHolder="Choose your country"
                    formControlName="country">
                    <nz-option
                      *ngFor="let country of vm.countries"
                      [nzValue]="country.value"
                      [nzLabel]="country.label"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label>Your Business Unit*</nz-form-label>
                <nz-form-control>
                  <nz-select
                    required
                    class="light-gray"
                    nzPlaceHolder="Choose you business unit"
                    formControlName="businessUnit">
                    <nz-option
                      *ngFor="let bussinessUnit of vm.businessUnits"
                      [nzValue]="bussinessUnit.value"
                      [nzLabel]="bussinessUnit.label"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label>Your current role</nz-form-label>
                <nz-form-control nzErrorTip="Please input your role!">
                  <input
                    nz-input
                    class="light-gray"
                    formControlName="role"
                    placeholder="Role" />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label>Your firstname and lastname</nz-form-label>
                <nz-form-control nzErrorTip="Please input your display name!">
                  <input
                    nz-input
                    class="light-gray"
                    formControlName="displayName"
                    placeholder="Display Name" />
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label>Your email</nz-form-label>
                <nz-form-control nzErrorTip="Please input your email address!">
                  <input
                    nz-input
                    class="light-gray"
                    formControlName="email"
                    placeholder="E-mail" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div>
            As part of your service contract to ensure the implementation of the
            document management and pdf editing service, we collect and use the
            following personal data that concern you through the form that you
            will be proposed to fill in as well as through the usage of our
            service:
            <br /><br />
            <ul>
              <li>Professional Email address</li>
              <li>First name and last name</li>
              <li>Google profile picture</li>
              <li>Business Unit</li>
              <li>Comments</li>
              <li>Files</li>
              <li>User logs</li>
            </ul>
            as part of an automated processing placed under the responsibility
            of the Group Director of Digital Business & Technology (DB&T).<br />

            Your personal data shall be stored for a five year term.
            <br /><br />

            They may be communicated to third-party service providers to perform
            hosting, storage, communication, database management or IT
            maintenance services. If these service providers are located outside
            the European Union, the data controller undertakes to first take all
            the measures required by the legislation in force to ensure the
            protection of your personal data.<br /><br />

            You have, within the limits of the legislation in force on data
            protection, a right of access, rectification, portability, erasure
            of personal data concerning you and a right to limit processing. You
            also have the right to send special instructions regarding the fate
            of your data after your death.<br /><br />

            To exercise these rights, you can send a request to Group IS&T
            Technology Design Authority groups
            <a
              href="mailto:gbl.ist.technologydesignauthority.all.groups@veolia.com"
              >gbl.ist.technologydesignauthority.all.groups&#64;veolia.com</a
            >
            or secondarily to
            <a href="mailto:dpo.vesa@veolia.com">dpo.vesa&#64;veolia.com</a>. If
            you believe, after this contact, that your rights on this data are
            not respected, you can send a complaint to the your local
            supervisory authority. <br /><br />

            Do you agree with terms ?
          </div>
          <nz-form-item>
            <nz-form-control
              nzValidateStatus="error"
              nzHasFeedback
              [nzErrorTip]="terms_RADIOErrorTpl">
              <nz-radio-group formControlName="terms_RADIO">
                <label nz-radio nzValue="Yes">Yes</label>
                <label nz-radio nzValue="No">No</label>
              </nz-radio-group>
              <ng-template #terms_RADIOErrorTpl let-control>
                <ng-container *ngIf="hasTermsAcceptedError()"
                  >The acceptance of terms is required</ng-container
                >
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control>
              <button nz-button nzType="primary" type="submit">Submit</button>
            </nz-form-control>
          </nz-form-item>
        </form>
      </div>
    </div>
  </div>
</div>
