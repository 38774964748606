import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { DatedLogs } from 'src/app/core/models/log';
import { LogService } from 'src/app/core/services/log.service';
import {
  LogsPageStoreState,
  logsPageStoreInitialState,
} from 'src/app/core/stores/states/logs-page.state';

@Injectable()
export class LogsPageStore extends ComponentStore<
  LogsPageStoreState<DatedLogs>
> {
  readonly logs$ = this.select(state => state.logs);
  readonly isLoading$ = this.select(state => state.isLoading);
  readonly solutionId$ = this.select(state => state.solutionId);

  constructor(private logService: LogService) {
    super(logsPageStoreInitialState);
  }

  setIsLoading = this.updater(state => {
    return {
      ...state,
      isLoading: true,
    };
  });

  setLogs = this.updater((state, logs: DatedLogs[]) => {
    return {
      ...state,
      logs,
    };
  });

  setSolutionId = this.updater((state, solutionId: string) => {
    return {
      ...state,
      solutionId,
    };
  });

  setSolutionType = this.updater((state, solutionType: string) => {
    return {
      ...state,
      solutionType,
    };
  });

  // getLogs = this.effect((trigger$) => {
  //   return trigger$.pipe(
  //     tap(() => {
  //       this.setIsLoading();
  //     }),
  //     withLatestFrom(this.select((state) => state)),
  //     map(([, state]) => state),
  //     switchMap(({ solutionId }) => {
  //       return this.logService.getFormLogs(solutionId!).then((logs) => {
  //         let logsArray: TypedDatedLogs[] = [];
  //         Object.keys(logs as any[]).forEach((log) => {
  //           let items = (logs as any)[log];
  //           console.log(items);
  //           // console.log((logs as any)[log]);
  //           // logsArray.push(logs[log]);
  //         });
  //       }).catch((err) => {
  //         console.log(err);
  //       });

  //     })
  //   );
  // });
}
