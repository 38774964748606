import { Injectable } from '@angular/core';
import {
  Database,
  getDatabase,
  ref,
  onValue,
  query,
  push,
  child,
  set,
  update,
  remove,
} from '@angular/fire/database';
import { Evaluation } from '../models/evalutation';

@Injectable()
export class EvaluationService {
  constructor(public __database: Database = getDatabase()) {}

  getNewEluationKey(path: string) {
    return push(child(ref(this.__database), path)).key;
  }

  getEvaluation(solutionId: string, form: string) {
    return new Promise((resolve, reject) => {
      const evaluationsRef = query(
        ref(this.__database, `evaluations/${form}/${solutionId}`)
      );
      onValue(evaluationsRef, snapshot => {
        if (!snapshot.exists()) {
          reject('No Evaluations found');
        }

        const data: Evaluation[] = [];

        snapshot.forEach(childSnapshot => {
          data.push({
            id: childSnapshot.key,
            ...childSnapshot.val(),
          });
        });

        resolve(data[0]);
      });
    });
  }

  getEvaluations(solutionId: string, form: string) {
    return new Promise((resolve, reject) => {
      const evaluationsRef = query(
        ref(this.__database, `evaluations/${form}/${solutionId}`)
      );
      onValue(evaluationsRef, snapshot => {
        if (!snapshot.exists()) {
          reject('No Evaluations found');
        }

        const data = snapshot.val();
        resolve(data);
      });
    });
  }

  addEvaluation(path: string, data: any) {
    return set(ref(this.__database, `${path}`), data);
  }

  updateEvaluation(path: string, data: any) {
    const updates: any = {};
    updates[path] = data;
    return update(ref(this.__database, path), data);
  }
}
