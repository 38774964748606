<div *ngFor="let subChapter of field.fieldGroup; let i = index">
  <nz-divider class="spacing-b16 spacing-t4"></nz-divider>
  <div>
    <div class="row row-space-between">
      <div>
        <h5 nz-typography>
          {{ subChapter.props?.label! | translate | truncate: 55 }}
        </h5>
      </div>
      <div>
        <app-form-actions
          *ngIf="subChapter.props?.label"
          [assessable]="
            subChapter.props?.['assessable'] !== undefined
              ? subChapter.props?.['assessable']
              : true
          "
          [evaluation]="subChapter.props?.['evaluation']"
          [fieldId]="subChapter.props?.label!"
          [fieldTitle]="subChapter.props?.label! | translate"
          [formName]="subChapter.props?.['formName']"
          [solutionId]="subChapter.props?.['solutionId']"></app-form-actions>
      </div>
    </div>
  </div>
  <formly-field [field]="subChapter"></formly-field>
</div>
