<div class="spacing-b16">
  <h5 [ngClass]="getFormStatusTheme(status!)">{{ title! | translate }}</h5>
  <table class="table">
    <thead>
      <tr>
        <th>Status</th>
        <th>Comment</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="center">
          <veolia-tag
            *ngIf="status"
            [label]="status!"
            [color]="getFormStatusTheme(status! || 'DLA Assessment')" />
        </td>
        <td class="center" nz-typography>
          {{ comment || 'N/A' }}
        </td>
      </tr>
    </tbody>
  </table>
  <nz-divider></nz-divider>
</div>
