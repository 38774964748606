import { NgModule } from '@angular/core';
import { EvaluationSummaryComponent } from './components/evaluation-summary/evaluation-summary.component';
import { EvaluationItemComponent } from './components/evaluation-item/evaluation-item.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { VeoliaTagComponent } from '@veolia.com/vds-angular-components/tag';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { EvaluationSectionHeaderComponent } from './components/evaluation-section-header/evaluation-section-header.component';
import { EvaluationComponent } from './components/evaluation-comment/evaluation-comment.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';
import { FormlyModule } from '@ngx-formly/core';
import { NzCardModule } from 'ng-zorro-antd/card';

@NgModule({
  declarations: [
    EvaluationSummaryComponent,
    EvaluationItemComponent,
    EvaluationSectionHeaderComponent,
    EvaluationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FormlyModule,
    NzCollapseModule,
    NzDividerModule,
    NzFormModule,
    NzCardModule,
    NzListModule,
    NzModalModule,
    NzRadioModule,
    NzTypographyModule,
    ReactiveFormsModule,
    TranslateModule,
    VeoliaButtonComponent,
    VeoliaIconComponent,
    VeoliaModalModule,
    VeoliaTagComponent,
  ],
  exports: [
    EvaluationSummaryComponent,
    EvaluationItemComponent,
    EvaluationSectionHeaderComponent,
    EvaluationComponent,
  ],
})
export class EvaluationSummaryModule {}
