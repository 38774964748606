import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Solution } from 'src/app/core/models/solution';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { ScrollService } from 'src/app/core/services/scroll.service';
import { DesignFormStore } from './design-form.store';
import { DesignFormService } from 'src/app/core/services/design-form.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { FormName } from 'src/app/core/enums/form-name';
import {
  architecturesAndRegions,
  authenticationMethods,
  authenticationProtocols,
  complianceOptions,
  dataFormats,
  implementationOptions,
  languageOptions,
  monoOrMultiTenant,
  plannedMobileOptions,
  reportFormats,
  yesOrNoCentralizedPlannedOptions,
  yesOrNoNAOptions,
  yesOrNoOptions,
  yesOrNoPlannedOptions,
  yesOrNoSoonOptions,
  yesOrNoUnknowOptions,
} from 'src/app/core/forms/checkbox-options';
import { DesignForm } from 'src/app/core/models/design-form';
import { LogService } from 'src/app/core/services/log.service';
import { Auth } from '@angular/fire/auth';
import SolutionApi from 'src/app/core/apis/solution.api';
import { SolutionService } from 'src/app/core/services/solution.service';
import { serverTimestamp } from '@angular/fire/database';
import {
  Timestamp,
  serverTimestamp as fireServerTimestamp,
} from '@angular/fire/firestore';

type Chapter = {
  fields: FormlyFieldConfig[];
} & {
  [additionalProperties: string]: any;
};

@Component({
  selector: 'app-design-form',
  templateUrl: './design-form.component.html',
  styleUrls: ['./design-form.component.scss'],
})
export class DesignFormComponent implements OnInit {
  @Input() solutionId = '';
  @Input() solution?: Solution;
  @Input() businessUnit?: BusinessUnit;

  @Output() updateFormDate: EventEmitter<{
    formName: string;
    lastUpdate: Timestamp;
    lastUpdateBy: string;
    status: string;
  }> = new EventEmitter<{
    formName: string;
    lastUpdate: Timestamp;
    lastUpdateBy: string;
    status: string;
  }>();
  @Output() notifyDirty: EventEmitter<boolean> = new EventEmitter();

  formName: FormName = FormName.Design;
  showEvaluationModal?: boolean;
  isDirty = false;
  isSaving = false;

  vm$ = this.designFormStore.vm$;

  formChapters: string[] = [
    'DA_9',
    'DA_1',
    'DA_2',
    'DA_3',
    'DA_4',
    'DA_5',
    'DA_6',
    'DA_7',
    'DA_8',
  ];

  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      disabled: true,
    },
  };
  formData: any = {};

  selectedChapter? = this.formChapters[0];
  fields: { [key: string]: Chapter } = {};

  constructor(
    public auth: Auth,
    public designFormService: DesignFormService,
    public designFormStore: DesignFormStore,
    public logService: LogService,
    public messageService: NzMessageService,
    public scrollService: ScrollService,
    public solutionApi: SolutionApi,
    public solutionService: SolutionService,
    private translateService: TranslateService,
    private veoliaMessageService: VeoliaMessageService
  ) {}

  ngOnInit(): void {
    this.loadEvaluation();
    this.fillFields();
  }

  loadEvaluation() {
    this.designFormStore.resetData();
    this.designFormStore.setSolutionId(this.solutionId);
    this.designFormStore.setSolution(this.solution!);
    this.designFormStore.setBusinessUnit(this.businessUnit!);
    this.designFormStore.setFormName('Design Form');
    this.designFormStore.getDesignForm();
    this.designFormStore.getEvaluation();
    this.designFormStore.getAllReservations();
    this.designFormStore.getAllComments();
    this.designFormStore.getAllFiles();
    this.designFormStore.getStatuses();

    this.options.formState.disabled = !this.isVendor || this.isVendorReadOnly;

    this.designFormStore.form$.subscribe(data => {
      this.updateFormDate.emit({
        formName: this.formName,
        lastUpdate: data?.lastUpdate!,
        lastUpdateBy: data?.lastUpdateBy!,
        status: data?.designStatus!,
      });
    });
  }

  get evaluation() {
    return this.designFormStore.getCurrentEvaluation();
  }

  get formCompletionStatus() {
    if ((this.designFormStore.getForm() as DesignForm).designStatus) {
      if (
        ['New', 'Vendor is responding'].includes(
          (this.designFormStore.getForm() as DesignForm).designStatus
        )
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  get formStatus() {
    return (
      (this.designFormStore.getForm() as DesignForm)?.designStatus || 'New'
    );
  }

  get isVendor() {
    return this.solution?.emailVendorAuthorized.includes(
      this.auth.currentUser?.email!
    );
  }

  get isVendorReadOnly() {
    return (
      this.solution?.emailVendorReadonlyDesign?.includes(
        this.auth.currentUser?.email!
      ) || false
    );
  }

  set dirty(isDirty: boolean) {
    this.isDirty = isDirty;
    this.notifyDirty.emit(isDirty);
  }

  selectChapter(id: string) {
    this.selectedChapter = id;
    this.scrollService.scrollToElementById(id);
  }

  formEdited(data: any) {
    this.formData = { ...this.formData, ...data };
    this.dirty = true;
  }

  editChapter(data: any) {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;

    const messageId = this.messageService.loading('Saving...', {
      nzDuration: 0,
    }).messageId;
    setTimeout(() => {
      //if the form has been already assessed, the status for the form stay evaluated in 'solutions' and in 'designForm' and in rtdb
      if (
        this.solution?.designStatus === 'Recommended' ||
        this.solution?.designStatus === 'Reservations' ||
        this.solution?.designStatus === 'Not recommended'
      ) {
        this.designFormService
          .update(
            this.solutionId,
            {
              ...data,
            },
            true
          )
          .then(() => {
            return this.solutionService.updateSolution(this.solutionId, {
              lastUpdate: fireServerTimestamp(),
            });
          })
          .then(() => {
            this.dirty = false;
            this.isSaving = false;
            this.messageService.remove(messageId);
            this.veoliaMessageService.create(
              {
                title: 'Saved successfully',
              },
              { duration: 3000 }
            );
          })
          .catch(error => {
            this.isSaving = false;
            console.log(error);
            this.messageService.remove(messageId);
          });
      } else {
        //if the form has not been assessed

        this.designFormService
          .update(
            this.solutionId,
            {
              ...data,
              designStatus: 'Vendor is responding',
            },
            true
          )
          .then(() => {
            return this.solutionService.updateSolution(this.solutionId, {
              lastUpdate: fireServerTimestamp(),
              designStatus: 'Vendor is responding',
              status: 'Ongoing',
            });
          })
          .then(() => {
            this.isSaving = false;
            this.dirty = false;
            this.messageService.remove(messageId);
            this.veoliaMessageService.create(
              {
                title: 'Saved successfully',
              },
              { duration: 3000 }
            );
          })
          .catch(error => {
            console.log(error);
            this.isSaving = false;
            this.messageService.remove(messageId);
          });
      }
    }, 2000);
  }

  saveAll() {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;

    const messageId = this.messageService.loading('Saving All', {
      nzDuration: 0,
    }).messageId;
    setTimeout(() => {
      //if the form has been already assessed, the status for the form stay evaluated in 'solutions' and in 'designForm' and in rtdb
      if (
        this.solution?.designStatus === 'Recommended' ||
        this.solution?.designStatus === 'Reservations' ||
        this.solution?.designStatus === 'Not recommended'
      ) {
        this.designFormService
          .update(
            this.solutionId,
            {
              ...this.formData,
            },
            true
          )
          .then(() => {
            this.dirty = false;
            return this.solutionService.updateSolution(this.solutionId, {
              lastUpdate: fireServerTimestamp(),
            });
          })
          .then(() => {
            this.dirty = false;
            this.isSaving = false;
            this.messageService.remove(messageId);
            this.logService.setLogForAction(
              'SaveForm',
              this.solutionId!,
              this.formName!,
              {
                action: 'Save form',
                userEmail: this.auth.currentUser?.email!,
                dateTime: serverTimestamp(),
                userId: this.auth.currentUser?.uid!,
              }
            );
            this.veoliaMessageService.create(
              {
                title: 'Saved All successfully',
              },
              { duration: 3000 }
            );
          })
          .catch(error => {
            this.isSaving = false;
            this.dirty = true;
            console.log(error);
            this.messageService.remove(messageId);
          });
      } else {
        //if the form has not been assessed

        this.designFormService
          .update(
            this.solutionId,
            {
              ...this.formData,
              designStatus: 'Vendor is responding',
            },
            true
          )
          .then(() => {
            this.dirty = false;
            return this.solutionService.updateSolution(this.solutionId, {
              lastUpdate: fireServerTimestamp(),
              designStatus: 'Vendor is responding',
              status: 'Ongoing',
            });
          })
          .then(() => {
            this.isSaving = false;
            this.messageService.remove(messageId);
            this.logService.setLogForAction(
              'SaveForm',
              this.solutionId!,
              this.formName!,
              {
                action: 'Save form',
                userEmail: this.auth.currentUser?.email!,
                dateTime: serverTimestamp(),
                userId: this.auth.currentUser?.uid!,
              }
            );
            this.veoliaMessageService.create(
              {
                title: 'Saved All successfully',
              },
              { duration: 3000 }
            );
          })
          .catch(error => {
            console.log(error);
            this.isSaving = false;
            this.dirty = true;
            this.messageService.remove(messageId);
          });
      }
    }, 2000);
  }

  markAsComplete(status: boolean) {
    const messageId = this.messageService.loading('Marking as completed', {
      nzDuration: 0,
    }).messageId;

    setTimeout(() => {
      this.designFormService
        .update(
          this.solutionId,
          {
            designStatus: status ? 'Vendor is responding' : 'Completed',
          },
          true
        )
        .then(() => {
          const data: any = {
            designStatus: status ? 'Vendor is responding' : 'Completed',
          };

          if (status === false) {
            data['status'] = 'Ongoing';
          }

          return this.solutionService.update(this.solutionId, data, true);
        })
        .then(() => {
          this.messageService.remove(messageId);

          if (status == false) {
            this.veoliaMessageService.create(
              {
                title: 'Marked as completed successfully',
              },
              { duration: 3000 }
            );

            this.logService.setLogForAction(
              'SaveCompleteForm',
              this.solutionId!,
              this.formName!,
              {
                action: 'Save complete form',
                userEmail: this.auth.currentUser?.email!,
                dateTime: serverTimestamp(),
                userId: this.auth.currentUser?.uid!,
              }
            );

            this.solutionApi
              .notifySolutionFormCompleted(this.solutionId, {
                formName: this.formName,
                formNameRoute: 'designForm',
              })
              .subscribe();
          } else {
            this.veoliaMessageService.create(
              {
                title: 'Marked as uncompleted successfully',
              },
              { duration: 3000 }
            );
          }

          this.refreshForm();
        })
        .catch(error => {
          console.log(error);
          this.messageService.remove(messageId);
        });
    }, 2000);
  }

  refreshForm() {
    this.designFormStore.getDesignForm();
    this.designFormStore.getEvaluation();
    this.designFormStore.getAllReservations();
  }

  fillFields() {
    this.fields = {
      DA_9: {
        evaluation: {
          commentField: 'DA_9_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'DA_9_RADIO',
        },
        label: 'DA_9',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: { label: '' },
                fieldGroup: [
                  {
                    template: this.translateService.instant('acunetix_text_1'),
                  },
                  {
                    template: `<strong>
                      ${this.translateService.instant('acunetix_text_2')}
                      </strong>`,
                  },
                  {
                    template: `<strong>
                      ${this.translateService.instant('acunetix_text_3')}
                      <br /> <br /> </strong>`,
                  },
                  {
                    template: `<strong>
                      ${this.translateService.instant('acunetix_text_4')}
                      <br /> <br /> </strong>`,
                  },
                  {
                    template: `<strong>
                      ${this.translateService.instant('acunetix_text_5')}
                      <br /> <br /> </strong>`,
                  },
                  {
                    template:
                      this.translateService.instant('acunetix_text_6') +
                      `<br /> <br />`,
                  },
                  {
                    template:
                      this.translateService.instant('acunetix_text_7') +
                      `<strong>13.36.153.80</strong><br /> <br />`,
                  },
                  {
                    template: `<a href="https://www.acunetix.com/vulnerability-scanner/black-box-scanner" target="_blank">${this.translateService.instant('acunetix_text_8')}</a> <br /> <br />`,
                  },
                  {
                    template:
                      this.translateService.instant(
                        'Vendor website URL (SaaS solution)'
                      ) +
                      ': <strong>' +
                      this.solution?.link +
                      '</strong><br /><br />',
                  },
                  {
                    key: 'acunetixApprobation',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('acunetix_question'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    template: `<strong>${this.translateService.instant(
                      'securityScanRefused'
                    )}<strong>`,
                    expressions: {
                      hide: "model.acunetixApprobation !== 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'securityScanRefusedAlternative',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant(
                        'securityScanRefusedAlternative'
                      ),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      hide: "model.acunetixApprobation !== 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'securityScanRefusedAlternativeTxt',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant(
                        'securityScanRefusedAlternativeTxt'
                      ),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      hide: "model.acunetixApprobation !== 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    template:
                      this.translateService.instant('acunetix_datetime'),
                    expressions: {
                      hide: "model.acunetixApprobation !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'dateAcunetixScanExpectedFrom',
                    type: 'nz-datepicker',
                    props: {
                      label: this.translateService.instant('Date from'),
                    },
                    expressions: {
                      hide: "model.acunetixApprobation !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'dateAcunetixScanExpectedTo',
                    type: 'nz-datepicker',
                    props: {
                      label: this.translateService.instant('Date to'),
                    },
                    expressions: {
                      hide: "model.acunetixApprobation !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'timeAcunetixScanExpectedFrom',
                    type: 'nz-timepicker',
                    props: {
                      label: this.translateService.instant('Time from'),
                    },
                    expressions: {
                      hide: "model.acunetixApprobation !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'timeAcunetixScanExpectedTo',
                    type: 'nz-timepicker',
                    props: {
                      label: this.translateService.instant('Time to'),
                    },
                    expressions: {
                      hide: "model.acunetixApprobation !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'acunetixInfo',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('Infos supp'),
                    },
                    expressions: {
                      hide: "model.acunetixApprobation !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'securityScanAllowedContact',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant(
                        'securityScanAllowedContact'
                      ),
                    },
                    expressions: {
                      hide: "model.acunetixApprobation !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    template:
                      this.translateService.instant(
                        'securityScanAllowedRemediationRoadmap'
                      ) + `<br /> <br />`,
                    expressions: {
                      hide: "model.acunetixApprobation !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'securityScanAllowedRemediationRoadmap',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant(
                        'securityScanAllowedRemediationRoadmap2'
                      ),
                    },
                    expressions: {
                      hide: "model.acunetixApprobation !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'remediationCritical',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant(
                        'remediation_critical'
                      ),
                    },
                    expressions: {
                      hide: "model.acunetixApprobation !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'remediationHigh',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('remediation_high'),
                    },
                    expressions: {
                      hide: "model.acunetixApprobation !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'remediationMedium',
                    type: 'nz-textarea',
                    props: {
                      label:
                        this.translateService.instant('remediation_medium'),
                    },
                    expressions: {
                      hide: "model.acunetixApprobation !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'remediationLow',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('remediation_low'),
                    },
                    expressions: {
                      hide: "model.acunetixApprobation !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'remediationInfo',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('remediation_info'),
                    },
                    expressions: {
                      hide: "model.acunetixApprobation !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      DA_1: {
        evaluation: {
          commentField: 'DA_1_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'DA_1_RADIO',
        },
        label: 'DA_1',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_10',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_10_RADIO',
                    commentField: 'DA_10_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'funcDescription',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('funcDescription'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'genaiQuestionDesign',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant(
                        'genaiQuestionDesign'
                      ),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
              {
                props: {
                  label: 'DA_11',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_11_RADIO',
                    commentField: 'DA_11_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_11_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_11_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_11_02_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_11_02'),
                      options: yesOrNoSoonOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_11_02_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_11_02_more'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_11_03',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_11_03'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
              {
                props: {
                  label: 'DA_12',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_12_RADIO',
                    commentField: 'DA_12_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_12_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_12_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_12_02_SELECT',
                    type: 'nz-select',
                    props: {
                      label: this.translateService.instant('DA_12_02'),
                      options: [
                        {
                          label: 'Amazon Web Services',
                          value: 'Amazon Web Services',
                        },
                        { label: 'AZURE', value: 'AZURE' },
                        {
                          label: 'Google Cloud Platform',
                          value: 'Google Cloud Platform',
                        },
                        { label: 'Multi cloud', value: 'Multi cloud' },
                        { label: 'Other', value: 'Other' },
                      ],
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_12_03',
                    type: 'nz-textarea',
                    props: { label: this.translateService.instant('DA_12_03') },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
              {
                props: {
                  label: 'DA_13',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_13_RADIO',
                    commentField: 'DA_13_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_13_01_CHECKBOX',
                    type: 'nz-checkbox',
                    props: {
                      label: this.translateService.instant('DA_13_01_CHECKBOX'),
                      options: monoOrMultiTenant,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_13_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_13_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_13_03',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_13_03'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
              {
                props: {
                  label: 'DA_14',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_14_RADIO',
                    commentField: 'DA_14_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_14_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_14_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_14_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_03',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_14_03'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_04',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_14_04'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_04_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_14_04_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_04_02_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_14_04_02'),
                      options: yesOrNoUnknowOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_04_03',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_14_04_03'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_05',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_14_05'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    template:
                      '<a href="https://en.wikipedia.org/wiki/Power_usage_effectiveness" alt="Power usage effectiveness on wikipedia" target="_blank">https://en.wikipedia.org/wiki/Power_usage_effectiveness</a>',
                  },
                ],
              },
              {
                props: {
                  label: 'DA_150',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_150_RADIO',
                    commentField: 'DA_150_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_14_09',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_14_09'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_XX_1_5_2_CHECKBOX',
                    type: 'nz-checkbox',
                    props: {
                      label: this.translateService.instant(
                        'DA_14_XX_1_5_2_CHECKBOX'
                      ),
                      options: architecturesAndRegions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_06',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_14_06'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_07',
                    type: 'number',
                    props: {
                      label: this.translateService.instant('DA_14_07'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_08',
                    type: 'number',
                    props: {
                      label: this.translateService.instant('DA_14_08'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_XX_1_5_6_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_14_XX_1_5_6'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_XX_1_5_6_more',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant(
                        'DA_14_XX_1_5_6_more'
                      ),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_XX_1_5_7_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_14_XX_1_5_7'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_XX_1_5_7_more',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant(
                        'DA_14_XX_1_5_7_more'
                      ),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_XX_1_5_8_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_14_XX_1_5_8'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_14_XX_1_5_8_more',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant(
                        'DA_14_XX_1_5_8_more'
                      ),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
              {
                props: {
                  label: 'DA_15',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_15_RADIO',
                    commentField: 'DA_15_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_15_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_15_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_15_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_15_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_15_03_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_15_03'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_15_03_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: 'model.DA_15_03_RADIO !== "Planned"',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_15_04',
                    type: 'nz-datepicker',
                    props: {
                      label: this.translateService.instant('DA_15_04'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_15_05',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_15_05'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
              {
                props: {
                  label: 'DA_16',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_16_RADIO',
                    commentField: 'DA_16_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_16_01',
                    type: 'select',
                    props: {
                      label: this.translateService.instant('DA_16_01'),
                      multiple: true,
                      options: languageOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_16_01_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_16_01_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_16_02_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_16_02'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_16_02_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      hide: 'model.DA_16_02_RADIO !== "Planned"',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_16_03',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_16_03'),
                    },
                    expressions: {
                      hide: 'model.DA_16_02_RADIO !== "Yes"',
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      DA_2: {
        evaluation: {
          commentField: 'DA_2_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'DA_2_RADIO',
        },
        label: 'DA_2',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_21',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_21_RADIO',
                    commentField: 'DA_21_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_21_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_21_01'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_21_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_21_02'),
                    },
                    expressions: {
                      hide: "model.DA_21_01_RADIO !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_21_02_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_21_02'),
                    },
                    expressions: {
                      hide: "model.DA_21_01_RADIO !== 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_21_02_01_YES',
                    type: 'nz-datepicker',
                    props: {
                      label: this.translateService.instant('DA_21_02_01_YES'),
                    },
                    expressions: {
                      hide: "model.DA_21_02_01_RADIO !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_21_02_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_21_02_01'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      hide: "model.DA_21_01_RADIO !== 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_21_03_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_21_03'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_21_03_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_21_03_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_21_04',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_21_04'),
                    },
                    expressions: {
                      hide: "model.DA_21_03_RADIO !== 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_21_05_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_21_05'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_21_05_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_21_05_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_21_06',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_21_06'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_22',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_22_RADIO',
                    commentField: 'DA_22_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_22_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_22_01'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_01_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_22_01_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_22_02'),
                    },
                    expressions: {
                      hide: "model.DA_22_01_RADIO !== 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_03_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_22_03'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_03_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_22_03_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_04',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_22_04'),
                    },
                    expressions: {
                      hide: "model.DA_22_03_RADIO !== 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_05_00_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_22_05_00'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_05_00_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_22_05_00_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_05_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_22_05_01'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_05_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_22_05_02'),
                    },
                    expressions: {
                      hide: "model.DA_22_05_01_RADIO === 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_05_03_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_22_05_03'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_05_04',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_22_05_02'),
                    },
                    expressions: {
                      hide: "model.DA_22_05_03_RADIO === 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_06',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_22_06'),
                    },
                    expressions: {
                      hide: "model.DA_22_05_03_RADIO === 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_07_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_22_07'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_07_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_22_07_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_08_CHECKBOX',
                    type: 'nz-checkbox',
                    props: {
                      label: this.translateService.instant('DA_22_08_CHECKBOX'),
                      options: reportFormats,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_09',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_22_09'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_10_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_22_10'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_11',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_22_11'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_22_12',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_22_12'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_23',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_23_RADIO',
                    commentField: 'DA_23_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_23_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_23_01'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_23_01_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_23_01_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_23_02_CHECKBOX',
                    type: 'nz-checkbox',
                    props: {
                      label: this.translateService.instant('DA_23_02_CHECKBOX'),
                      options: plannedMobileOptions,
                    },
                    expressions: {
                      hide: "model.DA_23_01_RADIO === 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_23_05',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_23_05'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_23_06_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_23_06'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_23_07',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_23_07'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_23_08_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_23_08'),
                      options: yesOrNoNAOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_23_09',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_23_09'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_24',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_24_RADIO',
                    commentField: 'DA_24_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_24_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_24_01'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_24_01_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_24_01_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_24_02_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_24_02'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_24_02_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_24_02_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_24_03_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_24_03'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_24_03_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_24_03_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_24_04_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_24_04'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_24_04_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_24_04_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_24_05_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_24_05'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_24_05_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_24_05_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_24_06',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_24_06'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_25',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_25_RADIO',
                    commentField: 'DA_25_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_25_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_25_01'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_01_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_25_01_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_25_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_02_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_25_02_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_04_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_25_04'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_04_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_25_04_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_04_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_25_04_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_03_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_25_03'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_03_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_25_03_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_03_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_25_03_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_05',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_25_05'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_06_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_25_06'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_06_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_25_06_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_06_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_25_06_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_07_CHECKBOX',
                    type: 'nz-checkbox',
                    props: {
                      label: this.translateService.instant('DA_25_07_CHECKBOX'),
                      options: dataFormats,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_08_CHECKBOX',
                    type: 'nz-checkbox',
                    props: {
                      label: this.translateService.instant('DA_25_08_CHECKBOX'),
                      options: authenticationMethods,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_09',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_25_09'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_10',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_25_10'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_11',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_25_11'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_12_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_25_12'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_12_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_25_12_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_13',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_25_13'),
                    },
                    expressions: {
                      hide: "model.DA_25_12_RADIO !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_25_14',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_25_14'),
                    },
                    expressions: {
                      hide: "model.DA_25_12_RADIO !== 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      DA_3: {
        evaluation: {
          commentField: 'DA_3_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'DA_3_RADIO',
        },
        label: 'DA_3',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                template: this.translateService.instant('DA_31_0'),
              },
              {
                props: {
                  label: 'DA_31',
                  title: this.translateService.instant('DA_31'),
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_31_RADIO',
                    commentField: 'DA_31_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_31_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_31_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_32',
                  title: this.translateService.instant('DA_32'),
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_32_RADIO',
                    commentField: 'DA_32_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_32_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_32_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      DA_4: {
        evaluation: {
          commentField: 'DA_4_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'DA_4_RADIO',
        },
        label: 'DA_4',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_41',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_41_RADIO',
                    commentField: 'DA_41_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    template: this.translateService.instant('DA_41_01'),
                  },
                  {
                    template: this.translateService.instant('DA_41_01_MORE', {
                      link: 'https://drive.google.com/file/d/1zmURottG44eL793WNeMZV-N4H3alYGCt/view?usp=share_link',
                    }),
                  },
                  {
                    key: 'DA_41_01_RADIO',
                    type: 'radio',
                    props: {
                      label: '',
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_41_01_CHECKBOX',
                    type: 'nz-checkbox',
                    props: {
                      label: this.translateService.instant('DA_41_01_CHECKBOX'),
                      options: authenticationProtocols,
                    },
                    expressions: {
                      hide: "model.DA_41_01_RADIO === 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_41_01_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_41_01_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_41_03_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_41_03'),
                      options: implementationOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_41_06_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_41_06'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_41_06_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_41_06_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_41_07',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_41_07'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_41_08_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_41_08'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_41_08_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_41_08_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_41_09',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_41_09'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_41_10_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_41_10'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_41_10_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_41_10_RADIO !== 'Planned'",
                    },
                  },
                  {
                    key: 'DA_41_10_MORE',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_41_10_MORE'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_41_11_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_41_11'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_41_11_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_41_11_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_410',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_410_RADIO',
                    commentField: 'DA_410_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_410_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_410_01_RADIO'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_410_01_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_410_01_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_410_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_410_02'),
                    },
                    expressions: {
                      hide: "model.DA_410_01_RADIO !== 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_410_03_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_410_03_RADIO'),
                      options: yesOrNoCentralizedPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_410_03_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_410_03_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_410_04',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_410_04'),
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_410_05_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_410_05_RADIO'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_410_05_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_410_05_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_410_06',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_410_06'),
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_42',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_42_RADIO',
                    commentField: 'DA_42_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_42_01_RADIO',
                    type: 'radio',
                    props: {
                      options: yesOrNoPlannedOptions,
                      label: this.translateService.instant('DA_42_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_01_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_42_01_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_02_RADIO',
                    type: 'radio',
                    props: {
                      options: yesOrNoPlannedOptions,
                      label: this.translateService.instant('DA_42_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_02_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_42_02_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    template: this.translateService.instant('DA_42_03_0'),
                  },
                  {
                    key: 'DA_42_03_RADIO',
                    type: 'radio',
                    props: {
                      options: yesOrNoPlannedOptions,
                      label: this.translateService.instant('DA_42_03'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_03_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_42_03_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_04',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_42_04'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_05_RADIO',
                    type: 'radio',
                    props: {
                      options: yesOrNoPlannedOptions,
                      label: this.translateService.instant('DA_42_05'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_05_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_42_05_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_07_RADIO',
                    type: 'radio',
                    props: {
                      options: yesOrNoPlannedOptions,
                      label: this.translateService.instant('DA_42_07'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_07_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_42_07_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_16_RADIO',
                    type: 'radio',
                    props: {
                      options: yesOrNoPlannedOptions,
                      label: this.translateService.instant('DA_42_16'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_16_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_42_16_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_17',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_42_17'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_18_RADIO',
                    type: 'radio',
                    props: {
                      options: yesOrNoPlannedOptions,
                      label: this.translateService.instant('DA_42_18'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_18_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_42_18_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_18_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_42_18_01'),
                    },
                    expressions: {
                      hide: "model.DA_42_18_RADIO !== 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_19_RADIO',
                    type: 'radio',
                    props: {
                      options: yesOrNoPlannedOptions,
                      label: this.translateService.instant('DA_42_19'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_19_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_42_19_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_20_RADIO',
                    type: 'radio',
                    props: {
                      options: yesOrNoPlannedOptions,
                      label: this.translateService.instant('DA_42_20'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_20_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_42_20_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_22_RADIO',
                    type: 'radio',
                    props: {
                      options: yesOrNoPlannedOptions,
                      label: this.translateService.instant('DA_42_22'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_22_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_42_22_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_23_RADIO',
                    type: 'radio',
                    props: {
                      options: yesOrNoPlannedOptions,
                      label: this.translateService.instant('DA_42_23'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_23_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_42_23_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_23_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_42_23_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_25_RADIO',
                    type: 'radio',
                    props: {
                      options: yesOrNoPlannedOptions,
                      label: this.translateService.instant('DA_42_25'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_25_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_42_25_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_42_25_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_42_23_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_43',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_43_RADIO',
                    commentField: 'DA_43_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_43_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_43_01'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_43_01_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_43_01_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_43_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_43_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_43_04',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_43_04'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_44',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_44_RADIO',
                    commentField: 'DA_44_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_44_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_44_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_44_02_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_44_02'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_44_02_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_44_02_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_44_04',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_44_04'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_45',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_45_RADIO',
                    commentField: 'DA_45_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    template: this.translateService.instant('DA_45_0'),
                  },
                  {
                    key: 'DA_45_00_A_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_45_00_A'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_45_00',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_45_00'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_45_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_45_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_45_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_45_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_45_03',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_45_03'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_45_04',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_45_04'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_45_05',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_45_05'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_45_06',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_45_06'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_45_07',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_45_07'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_45_08',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_45_08'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_45_09',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_45_09'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_45_10',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_45_10'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_45_13_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_45_13'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_45_13_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_45_13_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    template: this.translateService.instant('DA_45_15_AB'),
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_46',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_46_RADIO',
                    commentField: 'DA_46_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_46_00_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_46_00'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    template: this.translateService.instant(
                      'Email domain name is not veolia.com:'
                    ),
                  },
                  {
                    key: 'DA_46_00_more',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_46_00_more'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_46_01_CHECKBOX',
                    type: 'nz-checkbox',
                    props: {
                      label: this.translateService.instant('DA_46_01_CHECKBOX'),
                      options: complianceOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    template: this.translateService.instant('DA_46_01'),
                  },
                  {
                    template: this.translateService.instant('DA_46_001'),
                  },
                  {
                    key: 'DA_46_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_46_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    template: this.translateService.instant(
                      'Email domain name is veolia.com:'
                    ),
                  },
                  {
                    key: 'DA_46_03_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_46_03'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_47',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_47_RADIO',
                    commentField: 'DA_47_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_47_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_47_01'),
                      options: yesOrNoPlannedOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_47_01_PLANNED',
                    type: 'nz-datepicker',
                    props: {
                      label:
                        this.translateService.instant('If planned, when ?'),
                    },
                    expressions: {
                      hide: "model.DA_47_01_RADIO !== 'Planned'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_47_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_47_02'),
                    },
                    expressions: {
                      hide: "model.DA_47_01_RADIO !== 'Yes'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      DA_5: {
        evaluation: {
          commentField: 'DA_5_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'DA_5_RADIO',
        },
        label: 'DA_5',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_51',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_51_RADIO',
                    commentField: 'DA_51_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_51_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_51_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_52',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_52_RADIO',
                    commentField: 'DA_52_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_52_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_52_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_52_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_52_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_53',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_53_RADIO',
                    commentField: 'DA_53_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_53_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_53_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_54',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_54_RADIO',
                    commentField: 'DA_54_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    template: this.translateService.instant('DA_54_0'),
                  },
                  {
                    key: 'DA_54_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_54_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_54_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_54_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    template: this.translateService.instant('DA_54_03_0'),
                  },
                  {
                    key: 'DA_54_03',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_54_03'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_54_04',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_54_04'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  { template: this.translateService.instant('DA_54_04_0') },
                  {
                    key: 'DA_54_05_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_54_05'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_54_06',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_54_06'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      DA_6: {
        evaluation: {
          commentField: 'DA_6_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'DA_6_RADIO',
        },
        label: 'DA_6',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_61',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_61_RADIO',
                    commentField: 'DA_61_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_61_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_61_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_61_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_61_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_61_03',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_61_03'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_61_04',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_61_04'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_62',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_62_RADIO',
                    commentField: 'DA_62_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    template: `<strong>${this.translateService.instant('DA_62_0')}</strong>`,
                  },
                  {
                    key: 'DA_62_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_62_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_62_02_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_62_02'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_62_03',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_62_03'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_62_04',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_62_04'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    template: `<strong>${this.translateService.instant('DA_62_05_0')}</strong>`,
                  },
                  {
                    key: 'DA_62_05',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_62_05'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_62_06',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_62_06'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_63',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_63_RADIO',
                    commentField: 'DA_63_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_63_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_63_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_63_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_63_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_63_02_06_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_63_02_06'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_63_02_07',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_63_02_07'),
                    },
                    expressions: {
                      hide: "model.DA_47_01_RADIO !== 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_64',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_64_RADIO',
                    commentField: 'DA_64_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_64_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_64_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_64_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_64_02'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_64_03',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_64_03'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_64_04',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_64_04'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_64_05',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_64_05'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_65',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_65_RADIO',
                    commentField: 'DA_65_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_65_01_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_65_01'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_65_01_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_65_01_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_65_01_02',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_65_01_02'),
                    },
                    expressions: {
                      hide: "model.DA_65_01_RADIO !== 'No'",
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_65_02_RADIO',
                    type: 'radio',
                    props: {
                      label: this.translateService.instant('DA_65_02'),
                      options: yesOrNoOptions,
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                  {
                    key: 'DA_65_02_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_65_02_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      DA_7: {
        evaluation: {
          commentField: 'DA_7_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'DA_7_RADIO',
        },
        label: 'DA_7',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_71',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_71_RADIO',
                    commentField: 'DA_71_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_71_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_71_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_72',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_72_RADIO',
                    commentField: 'DA_72_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_72_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_72_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      DA_8: {
        evaluation: {
          commentField: 'DA_8_RADIO_comment',
          solutionName: this.solution?.solutionName,
          statusField: 'DA_8_RADIO',
        },
        label: 'DA_8',
        fields: [
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_81',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_81_RADIO',
                    commentField: 'DA_81_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_81_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_81_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'nz-sub-chapter',
            fieldGroup: [
              {
                props: {
                  label: 'DA_82',
                  solutionId: this.solutionId,
                  formName: 'Design Form',
                  solutionName: this.solution?.solutionName,
                  evaluation: {
                    statusField: 'DA_82_RADIO',
                    commentField: 'DA_82_RADIO_comment',
                  },
                },
                fieldGroup: [
                  {
                    key: 'DA_82_01',
                    type: 'nz-textarea',
                    props: {
                      label: this.translateService.instant('DA_82_01'),
                    },
                    expressions: {
                      'props.disabled': 'formState.disabled',
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    };
  }
}
