import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { NzFormLayoutType } from 'ng-zorro-antd/form';
import { HttpErrorResponse } from '@angular/common/http';

import { User } from 'src/app/core/models/user';
import { UserService } from 'src/app/core/services/user.service';
import { BusinessUnitService } from 'src/app/core/services/business-unit.service';
import { tapResponse } from '@ngrx/component-store';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { AllUsersStore } from 'src/app/features/admin/components/users/all-users/all-users.component.store';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';

interface IModalData {
  user: User;
}

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  providers: [],
})
export class UserManagementComponent implements AfterContentChecked, OnInit {
  veoliaUserForm: FormGroup<{
    businessUnit: FormControl<string | null>;
    admin: FormControl<boolean | null>;
    evaluator: FormControl<boolean | null>;
    solutionOwner: FormControl<boolean | null>;
    vendor: FormControl<boolean | null>;
  }> = this.fb.group({
    businessUnit: this.fb.control<string | null>(null, Validators.required),
    admin: this.fb.control<boolean | null>(null),
    evaluator: this.fb.control<boolean | null>(null),
    solutionOwner: this.fb.control<boolean | null>(null),
    vendor: this.fb.control<boolean | null>(null),
  });

  formLayout: NzFormLayoutType = 'vertical';

  user?: User;
  isLoading = false;
  //vm$ = this.userManagementStore.vm$;

  buList: any;
  booleanList: boolean[] = [];

  @ViewChild('userManagementModal') modal!: VeoliaModalComponent;

  solutionsAccess?: Array<string> = [];

  @Output() refresh: EventEmitter<void> = new EventEmitter();

  constructor(
    private cDRef: ChangeDetectorRef,
    private userService: UserService,
    private fb: FormBuilder,
    private businessUnitService: BusinessUnitService,
    private veoliaMessageService: VeoliaMessageService,
    private allUsersStore: AllUsersStore
  ) {}

  ngOnInit(): void {
    this.loadBus();
  }

  getBus() {
    this.businessUnitService
      .getBusinessUnitsList()
      .pipe(
        tapResponse(
          data => {
            data.docs.forEach(val => {
              //console.log('nombu', val.data()['nomBu']);
              //console.log('nombu', (val.data() as BusinessUnit).nomBu);
              val.data()['nomBu'];
            });
          },
          (err: HttpErrorResponse) => {
            console.log(err);
          }
        )
      )
      .subscribe(data => {
        this.isLoading = false;
        this.buList = data.docs.map(bu => {
          return {
            ...bu.data(),
            uid: bu.id,
          } as BusinessUnit;
        });
      });
  }

  loadBus(): void {
    this.isLoading = true;
    this.getBus();
  }

  ngAfterContentChecked(): void {
    this.cDRef.detectChanges();
  }

  open(user: User) {
    this.user = user;
    this.modal.open();
  }

  submitForm(): void {
    if (this.veoliaUserForm.valid) {
      this.userService
        .editUserBuAndRights(
          this.user?.uid!,
          this.veoliaUserForm.value.businessUnit!,
          this.veoliaUserForm.value.admin!,
          this.veoliaUserForm.value.evaluator!,
          this.veoliaUserForm.value.solutionOwner!,
          this.veoliaUserForm.value.vendor!
        )
        .then(() => {
          if (this.veoliaUserForm.value.admin! === false) {
            this.userService.editUserMainRole(this.user?.uid!, 'ADMIN');
          }
          if (this.veoliaUserForm.value.admin! === true) {
            this.userService.editUserMainRole(this.user?.uid!, 'ADMIN');
          }
          if (
            this.veoliaUserForm.value.evaluator! === true &&
            this.veoliaUserForm.value.admin! === false
          ) {
            this.userService.editUserMainRole(this.user?.uid!, 'Evaluator');
          }
          if (
            this.veoliaUserForm.value.solutionOwner! === true &&
            this.veoliaUserForm.value.admin! === false &&
            this.veoliaUserForm.value.evaluator! === false
          ) {
            this.userService.editUserMainRole(
              this.user?.uid!,
              'Solution Owner'
            );
          }
          if (
            this.veoliaUserForm.value.solutionOwner! === false &&
            this.veoliaUserForm.value.admin! === false &&
            this.veoliaUserForm.value.evaluator! === false
          ) {
            this.userService.editUserMainRole(this.user?.uid!, 'Viewer');
          }

          this.refresh?.emit();
          this.allUsersStore.refreshUsers();
        });
      this.veoliaMessageService.create(
        {
          title: 'Updated !',
          icon: 'check',
          content: 'User updated',
        },
        {
          duration: 10000,
        }
      );
      this.modal.close();
    } else {
      Object.values(this.veoliaUserForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
