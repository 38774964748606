import { Component } from '@angular/core';
import { AdminHomePageStore } from './admin-home-page.store';
import { SolutionService } from '../../../../core/services/solution.service';
import { UserService } from '../../../../core/services/user.service';
import { BusinessUnitService } from 'src/app/core/services/business-unit.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-home-page',
  templateUrl: './admin-home-page.component.html',
  styleUrls: ['./admin-home-page.component.scss'],
  providers: [SolutionService, AdminHomePageStore],
})
export class AdminHomePageComponent {
  vm$ = this.adminHomePageStore.vm$;

  //SaaS
  allSolutionsCounterSaaS?: number;
  allSolutionsAssessedCounterSaaS?: number;
  allSolutionsPendingCounterSaaS?: number;
  allSolutionsNewCounterSaaS?: number;
  allSolutionsInProgressCounterSaaS?: number;
  allSolutionsCanceledCounterSaaS?: number;

  //Users
  allVeoliaUsersCounter?: number;
  allVendorUsersCounter?: number;

  //BusinessUnits
  allBusinessUnitsCounter?: number;

  constructor(
    private adminHomePageStore: AdminHomePageStore,
    private router: Router,
    private solutionService: SolutionService,
    private userService: UserService,
    private businessUnitService: BusinessUnitService
  ) {}

  ngOnInit() {
    //SaaS//
    this.solutionService
      .getCountOfAllSolutionsByType('SaaS')
      .then(res => {
        this.allSolutionsCounterSaaS = res;
      })
      .catch(error => {
        this.allSolutionsCounterSaaS = undefined;
        console.log(error);
      });
    this.solutionService
      .getCountOfAllSolutionsAssessedByType('SaaS')
      .then(res => {
        this.allSolutionsAssessedCounterSaaS = res;
      })
      .catch(error => {
        this.allSolutionsAssessedCounterSaaS = undefined;
        console.log(error);
      });
    this.solutionService
      .getCountOfAllSolutionsPendingByType('SaaS')
      .then(res => {
        this.allSolutionsPendingCounterSaaS = res;
      })
      .catch(error => {
        this.allSolutionsPendingCounterSaaS = undefined;
        console.log(error);
      });
    this.solutionService
      .getCountOfAllSolutionsNewByType('SaaS')
      .then(res => {
        this.allSolutionsNewCounterSaaS = res;
      })
      .catch(error => {
        this.allSolutionsNewCounterSaaS = undefined;
        console.log(error);
      });
    this.solutionService
      .getCountOfAllSolutionsInProgressByType('SaaS')
      .then(res => {
        this.allSolutionsInProgressCounterSaaS = res;
      })
      .catch(error => {
        this.allSolutionsInProgressCounterSaaS = undefined;
        console.log(error);
      });
    this.solutionService
      .getCountOfAllSolutionsCanceledByType('SaaS')
      .then(res => {
        this.allSolutionsCanceledCounterSaaS = res;
      })
      .catch(error => {
        this.allSolutionsCanceledCounterSaaS = undefined;
        console.log(error);
      });

    //Users
    this.userService
      .getCountOfAllVeoliaUsers()
      .then(res => {
        this.allVeoliaUsersCounter = res;
      })
      .catch(error => {
        this.allVeoliaUsersCounter = undefined;
        console.log(error);
      });
    this.userService
      .getCountOfAllVendorUsers()
      .then(res => {
        this.allVendorUsersCounter = res;
      })
      .catch(error => {
        this.allVendorUsersCounter = undefined;
        console.log(error);
      });

    //Business units
    this.businessUnitService
      .getCountOfAllBusinessUnits()
      .then(res => {
        this.allBusinessUnitsCounter = res;
      })
      .catch(error => {
        this.allBusinessUnitsCounter = undefined;
        console.log(error);
      });
  }

  onSolutionTypeChange(type: number) {
    this.adminHomePageStore.setSolutionType(type);
  }

  redirectToSolutionsAdmin() {
    this.router.navigate(['/admin/solutions']);
  }

  redirectToBusinessUnitsAdmin() {
    this.router.navigate(['/admin/business-units']);
  }

  redirectToUsersAdmin() {
    this.router.navigate(['/admin/users']);
  }
}
