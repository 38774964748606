import { Injectable, inject } from '@angular/core';
import {
  Entity,
  Insertable,
  Ownable,
  Updateable,
  Updates,
} from '../models/bases';
import {
  Firestore,
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  startAt,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { Auth } from '@angular/fire/auth';
import { nameof } from '../utils/nameof';
import { Observable } from 'rxjs';

@Injectable()
export abstract class BaseService<T> {
  abstract get collectionName(): string;

  constructor(
    public _firestore: Firestore,
    public _auth: Auth
  ) {}

  /**
   *  Returns observable of entities owned by current user
   */
  observeOwned(uid: Ownable['uidInitiatorVeolia']) {
    return collectionData(
      query(
        collection(this._firestore, this.collectionName),
        where('uidInitiatorVeolia', '==', uid ?? this._auth.currentUser?.uid)
      ),
      {
        idField: nameof<Entity>('uid'),
      }
    ) as Observable<T[]>;
  }

  /**
   *  Return all entities owned by current user
   */
  async getOwned(uid: Ownable['uidInitiatorVeolia']) {
    return await getDocs(
      query(
        collection(this._firestore, this.collectionName),
        where('uidInitiatorVeolia', '==', uid ?? this._auth.currentUser?.uid)
      )
    );
  }

  /**
   *  Returns observable of all entities
   */
  observeAll() {
    return collectionData(
      query(collection(this._firestore, this.collectionName)),
      {
        idField: nameof<Entity>('uid'),
      }
    ) as Observable<T[]>;
  }

  /**
   *  Return all entities
   */
  async getAll() {
    return getDocs(collection(this._firestore, this.collectionName));
  }

  /**
   *  Count all entities
   */
  async countAll() {
    return (await this.getAll()).size;
  }

  /**
   *  return an entity
   */
  async get(id: Entity['uid']) {
    return await getDoc(doc(this._firestore, this.collectionName, id));
  }

  async update(
    id: Entity['uid'],
    updates: Updates<T> | Updateable<T>,
    formEdition: boolean
  ) {
    if (formEdition) {
      return await updateDoc(doc(this._firestore, this.collectionName, id), {
        ...updates,
        lastUpdate: serverTimestamp(),
        lastUpdateBy: this._auth.currentUser?.email,
      });
    }
    if (!formEdition) {
      return await updateDoc(doc(this._firestore, this.collectionName, id), {
        ...updates,
      });
    }
  }

  // async replace(id: Entity['uid'], entity: Updateable<T>) {
  //   return await setDoc(doc(this._firestore, this.collectionName, id), {
  //     ...entity,
  //     updatedAt: serverTimestamp(),
  //   });
  // }

  async create(
    entity: Insertable<T> | Updates<T> | Updateable<T>,
    id?: Entity['uid']
  ) {
    if (id) {
      return await setDoc(doc(this._firestore, this.collectionName, id), {
        ...entity,
        createdAt: serverTimestamp(),
        firstUpdate: serverTimestamp(),
        firstUpdateBy: this._auth.currentUser?.email,
      });
    } else {
      return await addDoc(collection(this._firestore, this.collectionName), {
        ...entity,
        createdAt: serverTimestamp(),
        firstUpdate: serverTimestamp(),
        firstUpdateBy: this._auth.currentUser?.email,
      });
    }
  }

  async remove(id: Entity['uid']) {
    return await deleteDoc(doc(this._firestore, this.collectionName, id));
  }
}
