<div id="pagination">
  <div class="pagination__info">
    <span>Show</span>
    <select [ngModel]="size" (change)="changeSize($event)">
      <option value="10" [selected]="size === 10">10</option>
      <option value="25" [selected]="size === 25">25</option>
      <option value="35" [selected]="size === 35">35</option>
      <option value="50" [selected]="size === 50">50</option>
    </select>
    <span>entries par page</span>
    <span> - </span>
    <span>{{ total }} entries in total</span>
  </div>

  <nz-pagination
    [nzDisabled]="loading"
    nzSimple
    [nzPageIndex]="page"
    (nzPageIndexChange)="onPageChange.emit($event)"
    [nzPageSize]="size"
    [nzTotal]="total"></nz-pagination>
</div>
