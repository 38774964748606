import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  scrollToElementById(id: string) {
    const element = this.__getElementById(id);
    if (element) {
      this.scrollToElement(element);
    }
  }

  private __getElementById(id: string): HTMLElement | null {
    const element = document.getElementById(id);
    return element;
  }

  scrollToElement(element: HTMLElement) {
    element.scrollIntoView({
      behavior: 'auto',
      block: 'start',
      inline: 'center',
    });
  }
}
