import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tap, switchMap } from 'rxjs';
import UserMapper from 'src/app/core/mappers/user.mapper';
import { Solution } from 'src/app/core/models/solution';
import { UserService } from 'src/app/core/services/user.service';
import { EmailedUser } from 'src/app/core/types/emailed-user';
import { vendorDTO } from './solution-share.type';

interface SolutionShareStoreState {
  error?: string;
  isLoading: boolean;
  isLoadingUsers: boolean;
  isNew?: boolean;
  solution?: Solution;
  success?: string;
  users: EmailedUser[];
  vendorData?: vendorDTO;
  veoliaUser?: string;
}

@Injectable()
export class SolutionShareStore extends ComponentStore<SolutionShareStoreState> {
  readonly error$ = this.select(state => state.error);
  readonly isLoading$ = this.select(state => state.isLoading);
  readonly isLoadingUsers$ = this.select(state => state.isLoadingUsers);
  readonly isNew$ = this.select(state => state.isNew);
  readonly solution$ = this.select(state => state.solution);
  readonly success$ = this.select(state => state.success);
  readonly users$ = this.select(state => state.users);
  readonly vendorData$ = this.select(state => state.vendorData);
  readonly veoliaUser$ = this.select(state => state.veoliaUser);

  vm$ = this.select(
    this.error$,
    this.isLoading$,
    this.isLoadingUsers$,
    this.isNew$,
    this.solution$,
    this.success$,
    this.users$,
    this.vendorData$,
    this.veoliaUser$,
    (
      error,
      isLoading,
      isNew,
      isLoadingUsers,
      solution,
      success,
      users,
      vendorData,
      veoliaUser
    ) => ({
      error,
      isLoading,
      isLoadingUsers,
      isNew,
      solution,
      success,
      users,
      vendorData,
      veoliaUser,
    })
  );

  constructor(private userService: UserService) {
    super({
      error: undefined,
      isLoading: false,
      isLoadingUsers: false,
      users: [],
      vendorData: undefined,
    });
  }

  readonly reset = this.updater(state => ({
    ...state,
    error: undefined,
    success: undefined,
  }));

  readonly setError = this.updater((state, error: string) => ({
    ...state,
    isLoading: false,
    error,
    success: undefined,
  }));

  readonly setIsLoading = this.updater((state, isLoading: boolean) => ({
    ...state,
    error: undefined,
    isLoading,
  }));

  readonly setIsLoadingUsers = this.updater(
    (state, isLoadingUsers: boolean) => ({
      ...state,
      error: undefined,
      isLoadingUsers,
    })
  );

  readonly setIsNew = this.updater((state, isNew: boolean) => ({
    ...state,
    isNew,
  }));

  readonly setSolution = this.updater((state, solution: Solution) => ({
    ...state,
    solution,
  }));

  readonly setSuccess = this.updater((state, success: string) => ({
    ...state,
    isLoading: false,
    error: undefined,
    success,
  }));

  readonly setUsers = this.updater((state, users: EmailedUser[]) => ({
    ...state,
    users,
  }));

  readonly setVendorData = this.updater((state, vendorData: vendorDTO) => ({
    ...state,
    vendorData,
  }));

  readonly setVeoliaUser = this.updater((state, veoliaUser: string) => ({
    ...state,
    veoliaUser,
  }));

  getUsers = this.effect(trigger$ => {
    return trigger$.pipe(
      tap(() => {
        this.setIsLoadingUsers(true);
      }),
      switchMap(() => {
        return this.userService.observeVeoliaUsers().pipe(
          tap(data => {
            this.setUsers(data.map(user => UserMapper.toEmailedUser(user)));
            this.setIsLoadingUsers(false);
          })
        );
      })
    );
  });
}
