import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'solution-form',
  template: `
    <div *ngFor="let f of field.fieldGroup; let i = index">
      <h3>Solution {{ i + 1 }}</h3>
      <formly-field class="col" [field]="f"></formly-field>
      <button type="button" nz-button nzDanger (click)="remove(i)">
        Remove (solution {{ i + 1 }} )
      </button>
      <nz-divider *ngIf="field.parent?.model.nbSolution !== i + 1"></nz-divider>
    </div>
    <button
      *ngIf="field.fieldGroup?.length! < field.parent?.model.nbSolution"
      type="button"
      nz-button
      nzType="primary"
      (click)="add()">
      Add Solution
    </button>
  `,
})
export class SolutionFormComponent extends FieldArrayType {}
