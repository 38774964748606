import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminBusinessUnitsPageStore } from './admin-business-units-page.store';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BusinessUnit } from 'src/app/core/models/business-unit';
import { BusinessUnitService } from 'src/app/core/services/business-unit.service';
import { Router } from '@angular/router';
import { VeoliaMessageService } from '@veolia.com/vds-angular-components/message';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';

@Component({
  selector: 'app-admin-business-units-page',
  templateUrl: './admin-business-units-page.component.html',
  styleUrls: ['./admin-business-units-page.component.scss'],
  providers: [AdminBusinessUnitsPageStore],
})
export class AdminBusinessUnitsPageComponent implements OnInit {
  @ViewChild('modal') modal!: VeoliaModalComponent;

  isVisible = false;
  isPreviewVisible = false;
  selectedBusinessUnit?: BusinessUnit;
  businessUnitUid: string | undefined;

  form = this.formBuilder.group({
    delegatedZone: new FormControl<string | null>(''),
    emailDLABU: new FormControl<string | null>(''),
    evaluators: new FormControl<string[] | null>(null),
    nomBu: new FormControl<string | null>(null, [Validators.required]),
    nomZone: new FormControl<string | null>(''),
    country: new FormControl<string | null>(''),
  });

  vm$ = this.adminBusinessUnitsPageStore.vm$;

  constructor(
    private adminBusinessUnitsPageStore: AdminBusinessUnitsPageStore,
    private businessUnitService: BusinessUnitService,
    private formBuilder: FormBuilder,
    private veoliaMessageService: VeoliaMessageService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.adminBusinessUnitsPageStore.getCount();
    this.adminBusinessUnitsPageStore.getBusinessUnits();
    this.adminBusinessUnitsPageStore.getUsers();

    if (this.selectedBusinessUnit) {
      this.form.get('nomBu')?.disable();
    }
  }

  showModal(): void {
    this.isVisible = true;
    this.modal.open();
  }

  close(): void {
    this.isVisible = false;
    this.reset();
  }

  showPreviewModal(): void {
    this.isPreviewVisible = true;
  }

  closePreviewModal(): void {
    this.isPreviewVisible = false;
    this.selectedBusinessUnit = undefined;
  }

  cancel(): void {
    this.isVisible = false;
    this.reset();
  }

  reset(): void {
    this.selectedBusinessUnit = undefined;
    this.form.reset();
  }

  changePage(page: number) {
    this.adminBusinessUnitsPageStore.changePage(page);
  }

  changeSize(size: Event) {
    const limit = parseInt((size.target as HTMLSelectElement).value);
    this.adminBusinessUnitsPageStore.changeLimit(limit);
  }

  onSearch(event: string) {
    this.adminBusinessUnitsPageStore.changeSearch(event);
  }

  onEdit(id: string) {
    this.businessUnitUid = id;

    this.selectedBusinessUnit =
      this.adminBusinessUnitsPageStore.getBusinessUnit(id);
    this.form.setValue({
      delegatedZone: this.selectedBusinessUnit?.delegatedZone || '',
      emailDLABU: this.selectedBusinessUnit?.emailDLABU || '',
      evaluators: this.selectedBusinessUnit?.evaluators || [],
      nomBu: this.selectedBusinessUnit?.nomBu || '',
      nomZone: this.selectedBusinessUnit?.nomZone || '',
      country: this.selectedBusinessUnit?.country || '',
    });

    this.showModal();
  }

  onSubmit() {
    if (this.form.valid) {
      console.log(this.form.value);
      if (this.selectedBusinessUnit) {
        this.businessUnitService
          .update(
            this.selectedBusinessUnit.uid,
            {
              delegatedZone: this.form.value.delegatedZone!,
              emailDLABU: this.form.value.emailDLABU!,
              evaluators: this.form.value.evaluators!,
              nomZone: this.form.value.nomZone!,
              country: this.form.value.country!,
            },
            false
          )
          .then(resp => {
            //this.close();
            this.veoliaMessageService.create(
              {
                title: 'Updated !',
                icon: 'check',
                content: 'Business unit updated successfully',
              },
              {
                duration: 10000,
              }
            );
            this.adminBusinessUnitsPageStore.getBusinessUnits();
          })
          .catch(err => {
            this.veoliaMessageService.create(
              {
                title: 'Error',
                icon: 'error',
                content: 'Error updating business unit',
              },
              {
                duration: 10000,
              }
            );
          });
      } else {
        this.businessUnitService
          .create({
            delegatedZone: this.form.value.delegatedZone!,
            emailDLABU: this.form.value.emailDLABU!,
            evaluators: this.form.value.evaluators!,
            nomBu: this.form.value.nomBu!,
            nomZone: this.form.value.nomZone!,
            country: this.form.value.country!,
          })
          .then(resp => {
            //this.close();
            this.veoliaMessageService.create(
              {
                title: 'Created !',
                icon: 'check',
                content: 'Business unit created successfully',
              },
              {
                duration: 10000,
              }
            );
            this.adminBusinessUnitsPageStore.getBusinessUnits();
          })
          .catch(err => {
            this.veoliaMessageService.create(
              {
                title: 'Error',
                icon: 'error',
                content: 'Error creating business unit',
              },
              {
                duration: 10000,
              }
            );
          });
      }
    } else {
      Object.values(this.form?.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  backtoAdminHome() {
    this.router.navigate(['/admin']);
  }
}
