<app-layout-page-header>
  <veolia-page-header variant="light">
    <div veolia-page-header-title>Home</div>
    <div veolia-page-header-actions *ngIf="isVeolia">
      <div class="row">
        <veolia-tag
          label="Release notes"
          style="cursor: pointer"
          (click)="redirectToReleaseNotes()"
          color="information"
          variant="light" />
      </div>
    </div>

    <div veolia-page-header-bottom *ngIf="isVeolia">
      <veolia-segmented
        *ngIf="vm$ | async as vm"
        [options]="tabOptions"
        (handleIndexChange)="onSolutionTypeChange($event)"
        [variant]="'primary'"
        [selectedIndex]="vm.solutionType"
        [size]="'default'" />

      <div class="right-actions">
        <app-form-caption></app-form-caption>
        <app-documentation></app-documentation>
        <veolia-button
          [size]="'default'"
          [variant]="'primary'"
          [icon]="'add'"
          [iconFilled]="true"
          [iconPosition]="'left'"
          (click)="goToNewDemand()">
          New DLA Request
        </veolia-button>
      </div>
    </div>

    <div veolia-page-header-bottom *ngIf="!isVeolia" class="right-actions">
      <app-form-caption></app-form-caption>
      <app-documentation></app-documentation>
    </div>
  </veolia-page-header>
</app-layout-page-header>
<div class="layout-body">
  <div class="layout-body-content table_container">
    <ng-container *ngIf="vm$ | async as vm">
      <veolia-tabs *ngIf="isVeolia">
        <veolia-tab label="My BU's requests">
          <app-home-page-business-unit-solutions
            [solutionType]="
              vm.solutionType
            "></app-home-page-business-unit-solutions>
        </veolia-tab>
        <veolia-tab label="My requests">
          <app-home-page-owned-solutions
            [solutionType]="vm.solutionType"></app-home-page-owned-solutions>
          <app-home-page-followed-solutions
            [solutionType]="vm.solutionType"></app-home-page-followed-solutions>
          <app-home-page-evaluator-solutions
            [solutionType]="
              vm.solutionType
            "></app-home-page-evaluator-solutions>
        </veolia-tab>
        <veolia-tab label="Other BU's requests">
          <app-home-page-other-business-unit-solutions
            [solutionType]="
              vm.solutionType
            "></app-home-page-other-business-unit-solutions>
        </veolia-tab>
      </veolia-tabs>
      <app-home-page-vendor-solutions
        *ngIf="!isVeolia"
        [solutionType]="vm.solutionType"></app-home-page-vendor-solutions>
    </ng-container>
  </div>
</div>
