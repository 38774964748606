import { BaseStoreState, baseStoreInitialState } from './base-store.state';
import { BusinessUnit } from '../../models/business-unit';
import { EmailedUser } from '../../types/emailed-user';

export type BusinessUnitStoreState<T> = {
  businessUnits: BusinessUnit[];
  isLoadingUsers: boolean;
  users: EmailedUser[];
} & BaseStoreState<T>;

export const businessUnitStoreInitialState = {
  ...baseStoreInitialState,
  businessUnits: [],
  isLoadingUsers: false,
  users: [],
};
