import { BusinessUnit } from "../../models/business-unit";

export type DemandFormStoreState<T> = {
  selectedId?: string;
  unlockeds: string[];
  loading?: boolean;
  currentUser?: any;
  businessUnit?: BusinessUnit
  evaluatorList?: string[];
  formData?: any[]
}


export const demandFormStoreInitialState = {
  selectedId: '1',
  unlockeds: ['1',],
  loading: false,
  currentUser: null,
};
