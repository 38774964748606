import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { NotFoundModule } from './shared/features/not-found/not-found.module';
import { NotFoundComponent } from './shared/features/not-found/components/not-found.component';
import { DemandFormsPageComponent } from './features/demands/pages/demand-forms-page/demand-forms-page.component';
import { SolutionResolver } from './core/resolvers/solution-resolver';
import { BaseLayoutComponent } from './shared/features/layout/components/base-layout/base-layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AssessmentsSummaryPageComponent } from './features/solutions/pages/assessments-summary-page/assessments-summary-page.component';
import { BusinessUnitResolver } from './core/resolvers/business-unit-resolver';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./features/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./features/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'evaluator',
    loadChildren: () =>
      import('./features/evaluator/business-unit-evaluator.module').then(
        m => m.BusinessUnitEvaluatorModule
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/authentication/authentication.module').then(
        m => m.AuthenticationModule
      ),
  },
  {
    path: 'demands',
    loadChildren: () =>
      import('./features/demands/demands.module').then(m => m.DemandsModule),
  },
  {
    path: 'solutions',
    loadChildren: () =>
      import('./features/solutions/solutions.module').then(
        m => m.SolutionsModule
      ),
  },
  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        matcher: url => {
          if (
            url.length === 2 &&
            url[0].path.match(
              /^(?:article28Form|article32Form|designForm|legalForm)$/
            )
          ) {
            return {
              consumed: url,
              posParams: {
                formName: new UrlSegment(url[0].path, {}),
                id: new UrlSegment(url[1].path, {}),
              },
            };
          }
          return null;
        },
        component: DemandFormsPageComponent,
        resolve: {
          solution: SolutionResolver,
          businessUnit: BusinessUnitResolver,
        },
      },
    ],
  },
  { path: '**', component: NotFoundComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [NotFoundModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
