<div class="rounded-white spacing-b16">
  <nz-collapse [nzBordered]="false">
    <nz-collapse-panel
      [nzHeader]="header"
      [nzActive]="id === selectedId"
      (nzActiveChange)="onClick($event)"
      [ngStyle]="{
        background: '#ffffff',
        'border-radius': '4px',
        border: '0px'
      }"
      [nzDisabled]="!unlocked">
      <nz-divider class="spacing-v8"></nz-divider>
      <div>
        <ng-content></ng-content>
      </div>
      <nz-divider></nz-divider>
      <div class="row row-right">
        <div>
          <veolia-button
            (click)="submit()"
            size="default"
            [shape]="'default'"
            [variant]="'primary'"
            [disabled]="loading!"
            [htmlType]="'button'">
            {{ actionTitle }}
          </veolia-button>
        </div>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
</div>
<ng-template #header>
  <h3 [ngClass]="unlocked ? 'active' : 'inactive'">{{ title }}</h3>
</ng-template>
