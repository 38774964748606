import { Injectable } from '@angular/core';
import {
  Database,
  getDatabase,
  onValue,
  push,
  query,
  ref,
  set,
  update,
} from '@angular/fire/database';

@Injectable()
export class ChapterCompletionStatusService {
  constructor(public __database: Database = getDatabase()) {}

  getStatuses(solutionId: string, form: string) {
    return new Promise((resolve, reject) => {
      const statusesRef = query(
        ref(this.__database, `completeChapSubChap/${form}/${solutionId}`)
      );

      onValue(statusesRef, snapshot => {
        if (!snapshot.exists()) {
          reject('No Statuses found');
        }

        const data = snapshot.val();
        resolve(data);
      });
    });
  }

  setCompletionStatus(path: string, data: any) {
    const chapterCompletionRef = ref(this.__database, path);
    const newChapterCompletionRef = push(chapterCompletionRef);
    return set(newChapterCompletionRef, data);
  }

  updateCompletionStatus(path: string, data: any) {
    return update(ref(this.__database, path), data);
  }
}
