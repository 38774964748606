import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewChild,
} from '@angular/core';
import { VeoliaModalComponent } from '@veolia.com/vds-angular-components/modal';
import { BusinessUnit } from 'src/app/core/models/business-unit';

import { User } from 'src/app/core/models/user';
import { BusinessUnitService } from 'src/app/core/services/business-unit.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-user-summary',
  templateUrl: './user-summary.component.html',
  styleUrls: ['./user-summary.component.scss'],
})
export class UserSummaryComponent implements AfterContentChecked {
  user?: User;

  @ViewChild('userSummaryModal') modal!: VeoliaModalComponent;

  solutionsAccess?: Array<string> = [];
  solutionsAccessBuEvaluator?: Array<string> = [];

  businessUnit?: BusinessUnit;

  constructor(
    private cDRef: ChangeDetectorRef,
    private userService: UserService,
    private businessUnitService: BusinessUnitService
  ) {}

  ngAfterContentChecked(): void {
    this.cDRef.detectChanges();
  }

  // ngAfterViewInit(): void {
  //   if (this.user?.uid) {
  //     this.userService.get(this.user?.uid).then(data => {
  //       this.user = { uid: data.id, ...data.data() } as User;
  //     });
  //   }
  // }

  open(user: User) {
    this.user = user;

    this.solutionsAccess = [];
    this.solutionsAccessBuEvaluator = [];

    if (this.user.type === 'Veolia') {
      this.userService
        .getSolutionsAccessForVeoliaUser(this.user?.email)
        .forEach(res => {
          res.forEach(element => {
            this.solutionsAccess?.push(
              element.data()['solutionName'] + ' (' + element.id + ')'
            );
          });
        })
        .catch(error => {
          this.solutionsAccess = undefined;
          console.log(error);
        });

      if (this.user?.businessUnit) {
        this.businessUnitService
          .getByName(this.user?.businessUnit)
          .then(res => {
            this.businessUnit = res.docs[0].data() as BusinessUnit;
            if (this.isBusinessUnitEvaluator) {
              this.userService
                .getSolutionsAccessForBuEvaluator(this.user!.businessUnit)
                .forEach(res => {
                  res.forEach(element => {
                    this.solutionsAccessBuEvaluator?.push(
                      element.data()['solutionName'] + ' (' + element.id + ')'
                    );
                  });
                })
                .catch(error => {
                  this.solutionsAccessBuEvaluator = undefined;
                  console.log(error);
                });
            }
          });
      }
    }

    if (this.user.type === 'Vendor') {
      this.userService
        .getSolutionsAccessForVendorUser(this.user?.email)
        .forEach(res => {
          res.forEach(element => {
            this.solutionsAccess?.push(
              element.data()['solutionName'] + ' (' + element.id + ')'
            );
          });
        })
        .catch(error => {
          this.solutionsAccess = undefined;
          console.log(error);
        });
    }

    this.modal.open();
  }

  get isBusinessUnitEvaluator() {
    if (!this.businessUnit?.evaluators?.includes(this.user!.email!)) {
      return false;
    } else {
      return true;
    }
  }
}
