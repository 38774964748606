<div class="spacing-v8">
  <strong nz-typography class="title">
    Fill in your email, you will receive a link to reset your password
  </strong>
</div>
<form
  nz-form
  [formGroup]="forgotPasswordForm"
  class="forgot-password-form"
  (ngSubmit)="submitForm()">
  <nz-form-item>
    <nz-form-control nzErrorTip="Please input your username!">
      <nz-input-group nzPrefixIcon="user" class="light-gray">
        <input
          class="light-gray"
          type="text"
          nz-input
          formControlName="email"
          placeholder="Email address" />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>
  <div class="row row-right">
    <div>
      <veolia-button
        [size]="'default'"
        [variant]="'secondary'"
        [htmlType]="'reset'">
        Cancel
      </veolia-button>
    </div>
    <div>
      <veolia-button
        [size]="'default'"
        [variant]="'primary'"
        [htmlType]="'submit'">
        Send
      </veolia-button>
    </div>
  </div>
</form>
