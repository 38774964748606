import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-form-nz-ragio-group',
  template: `<div *ngFor="let f of field.fieldGroup; let i = index">
    {{ f.props?.label }}
  </div>`,
  styles: [
    `
      .ant-radio-wrapper-disabled {
        color: var(
          --components-table-body-table-cell-foreground-default
        ) !important;
      }
    `,
  ],
})
export class FormNZRadioGroupComponent extends FieldType {}
