import { Injectable } from '@angular/core';
import {
  Database,
  getDatabase,
  ref,
  set,
  onValue,
  query,
  get,
} from '@angular/fire/database';
import { serverTimestamp } from '@angular/fire/database';
import { Observable, timer, Subscription } from 'rxjs';

export interface UserPresence {
  status: 'online' | 'away';
  timestamp: number;
  userId: string;
  displayName?: string;
}

export interface FormStatus {
  users: UserPresence[];
}

@Injectable({
  providedIn: 'root',
})
export class DocumentPresenceService {
  private heartbeatInterval = 60000; // 1 minute
  private inactiveThreshold = 180000; // 3 minute
  private cleanupInterval = 300000; // 5 minutes
  private db: Database;
  private formName?: string;
  private documentId?: string;

  constructor() {
    this.db = getDatabase();
    this.startCleanupTask();
  }

  initializeDocumentPresence(formName: string, documentId: string) {
    this.formName = formName;
    this.documentId = documentId;
  }

  updatePresence(
    documentId: string,
    formName: string,
    userId: string,
    displayName: string,
    status: 'online' | 'away'
  ) {
    const formRef = ref(this.db, `documentStatus/${documentId}/${formName}`);
    const userPresence: UserPresence = {
      status,
      timestamp: Date.now(),
      userId,
      displayName,
    };

    return get(formRef).then(snapshot => {
      if (snapshot.exists()) {
        const formStatus = snapshot.val() as FormStatus;
        const updatedUsers = formStatus.users
          ? formStatus.users.filter(u => u.userId !== userId)
          : [];
        updatedUsers.push(userPresence);
        return set(formRef, {
          users: updatedUsers,
        });
      } else {
        return set(formRef, {
          users: [userPresence],
        });
      }
    });
  }

  getFormStatus(documentId: string, formName: string): Observable<FormStatus> {
    const formRef = ref(this.db, `documentStatus/${documentId}/${formName}`);
    return new Observable<FormStatus>(observer => {
      const unsubscribe = onValue(formRef, snapshot => {
        if (snapshot.exists()) {
          observer.next(snapshot.val() as FormStatus);
        } else {
          observer.next({
            users: [],
          });
        }
      });
      return () => unsubscribe();
    });
  }

  removePresence(documentId: string, formName: string, userId: string) {
    const formRef = ref(this.db, `documentStatus/${documentId}/${formName}`);
    return get(formRef).then(snapshot => {
      if (snapshot.exists()) {
        const formStatus = snapshot.val() as FormStatus;
        const updatedUsers = formStatus.users.filter(u => u.userId !== userId);
        return set(formRef, {
          users: updatedUsers,
        });
      } else {
        return Promise.resolve();
      }
    });
  }

  startHeartbeat(
    documentId: string,
    formName: string,
    userId: string,
    displayName: string
  ): Subscription {
    return timer(0, this.heartbeatInterval).subscribe(() => {
      this.updatePresence(documentId, formName, userId, displayName, 'online');
    });
  }

  private startCleanupTask() {
    timer(0, this.cleanupInterval).subscribe(() => {
      this.cleanOldPresenceData();
    });
  }

  private async cleanOldPresenceData() {
    const currentTime = Date.now();
    const documentStatusRef = ref(
      this.db,
      `documentStatus/${this.documentId}/${this.formName}`
    );
    const snapshot = await get(query(documentStatusRef));
    const formStatusData = snapshot.val() as FormStatus;

    if (formStatusData) {
      const updatedUsers = formStatusData.users.filter(
        user => currentTime - user.timestamp <= this.inactiveThreshold
      );

      if (updatedUsers.length !== formStatusData.users.length) {
        set(
          ref(this.db, `documentStatus/${this.documentId}/${this.formName}`),
          {
            timestamp: serverTimestamp(),
            users: updatedUsers,
          }
        );
      }
    }
  }
}
