import { Injectable } from '@angular/core';
import { BaseApi } from './api';
import { Observable } from 'rxjs';
import { FileAddedDTO } from '../dtos/file-added.dto';

@Injectable()
export class FileApi extends BaseApi {
  notifyFileAdded(solution_id: string, data: FileAddedDTO): Observable<any> {
    return this.post(`/api/files/notify/file_add/${solution_id}`, data);
  }

  exportSolutionInfoPDF(
    solution_id: string,
    params: Record<string, string> = {}
  ) {
    return this.get(
      `/api/pdf/solution/${solution_id}/info`,
      { format: 'pdf', ...params },
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  exportEvaluationFormPDF(
    solution_id: string,
    params: Record<string, string> = {}
  ) {
    return this.get(
      `/api/pdf/evaluation/${solution_id}/form`,
      { format: 'pdf', ...params },
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  exportFormFilesPDF(solution_id: string, params: Record<string, string> = {}) {
    return this.get(
      `/api/pdf/files/${solution_id}/form`,
      { format: 'pdf', ...params },
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  exportFormCommentsPDF(solution_id: string, params: Record<string, string> = {}) {
    return this.get(
      `/api/pdf/comments/${solution_id}/form`,
      { format: 'pdf', ...params },
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  exportFormPDF(solution_id: string, params: Record<string, string> = {}) {
    return this.get(
      `/api/pdf/form/${solution_id}`,
      { format: 'pdf', ...params },
      {
        responseType: 'blob',
        observe: 'response',
      }
    );
  }
}
