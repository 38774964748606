import { Solution } from '../models/solution';
import { formatDate } from './date';

export function ConvertToCSV(
  objArray: Solution[],
  headerList: string[] = [
    'uid',
    'rfpName',
    'solutionName',
    'type',
    'apmNumber',
    'uidInitiatorVeolia',
    'solutionOwner',
    'article28Status',
    'article32Status',
    'businessUnitOfSolution',
    'communicationQuestion',
    'designStatus',
    'description',
    'domainOfSolution',
    'editorBrand',
    'emailEvaluatorAuthorized',
    'emailInitiatorVeolia',
    'emailVendorAuthorized',
    'emailVeoliaAuthorized',
    'expectedCompletionDate',
    'expectedEvaluationDate',
    'expectedOnlineReleaseDate',
    'fullNameInitiatorVeolia',
    'fullyEvaluated',
    'inPlace',
    'jsonFR',
    'jsonUS',
    'lastUpdate',
    'legalStatus',
    'link',
    'linkCom',
    'mainCompanyVendor',
    'mainEmailVendor',
    'mainFirstnameVendor',
    'mainLastnameVendor',
    'nbSolution',
    'personalDataQuestion',
    'requestDate',
    'status',
  ]
) {
  const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  let row = '';
  for (const index in headerList) {
    row += convertCamelCaseToTitleCase(headerList[index]) + ',';
  }
  row = row.slice(0, -1);
  str += row + '\r\n';
  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (const index in headerList) {
      const head = headerList[index];
      if (
        [
          'accessQuestion',
          'communicationQuestion',
          'interconnectQuestion',
          'emailEvaluatorAuthorized',
          'emailVendorAuthorized',
          'emailVeoliaAuthorized',
        ].includes(head)
      ) {
        if (array[i][head]) {
          if (Array.isArray(array[i][head])) {
            line += '"' + array[i][head].join(';') + '",';
          } else {
            line += '"' + array[i][head] + '",';
          }
        } else {
          line += '""' + ',';
        }
      } else if ('domainOfSolution' === head) {
        line += array[i][head].replaceAll(',', '') + ',';
      } else if ('description' === head) {
        line += '"' + array[i][head] + '",';
      } else if (
        [
          'expectedCompletionDate',
          'expectedEvaluationDate',
          'expectedOnlineReleaseDate',
          'lastUpdate',
          'requestDate',
        ].includes(head)
      ) {
        line += formatDate(array[i][head]) + ',';
      } else {
        if (array[i][head]) {
          line += array[i][head].toString().replaceAll(',', '') + ',';
        } else {
          line += '' + ',';
        }
      }
    }
    str += line + '\r\n';
  }
  return str;
}

function convertCamelCaseToTitleCase(input: string): string {
  // Add a space before any uppercase letter that follows a lowercase letter
  const spaced = input.replaceAll(/([a-z])([A-Z])/g, '$1 $2');

  // Capitalize the first letter of each word
  return spaced
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
