import { NgModule } from '@angular/core';
import { CaptionComponent } from './components/caption/caption.component';
import { CommonModule } from '@angular/common';
import { CaptionItemComponent } from './components/caption-item/caption-item.component';
import { VeoliaButtonComponent } from '@veolia.com/vds-angular-components/button';
import { VeoliaTagComponent } from '@veolia.com/vds-angular-components/tag';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';
import {
  VeoliaTabComponent,
  VeoliaTabsComponent,
} from '@veolia.com/vds-angular-components/tabs';
import { VeoliaIconComponent } from '@veolia.com/vds-angular-components/icons';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

@NgModule({
  declarations: [CaptionComponent, CaptionItemComponent],
  imports: [
    CommonModule,
    VeoliaButtonComponent,
    VeoliaIconComponent,
    VeoliaTabComponent,
    VeoliaTabsComponent,
    VeoliaTagComponent,
    NzTagModule,
    NzSwitchModule,
    VeoliaModalModule,
  ],
  exports: [CaptionComponent],
})
export class CaptionModule {}
