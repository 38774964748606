import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { getTagColor } from 'src/app/core/utils/colors';
import { formatDate } from 'src/app/core/utils/date';
import { DataTableComponent } from 'src/app/shared/features/data-table/components/data-table/data-table.component';
import { User } from 'src/app/core/models/user';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent extends DataTableComponent {
  @Input() users: User[] = [];
  @Input() userType = 0;
  @Input() showId = true;
  @Input() actions: string[] = [];

  @Output() refresh: EventEmitter<void> = new EventEmitter();

  getTagColor(status: string) {
    return getTagColor(status);
  }

  getFormattedDate(date: string | Timestamp) {
    return formatDate(date);
  }

  hasAction(action: string) {
    return this.actions.includes(action);
  }

  //data-table functions overrided
  override launchDisableUserAccount(user: User) {
    this.authService.disableVendorUser(user?.uid, user?.email);
    this.refresh?.emit();
    window.alert('User ' + user.email + ' has been disabled');
  }

  override launchEnableUserAccount(user: User) {
    this.authService.enableVendorUser(user?.uid, user?.email);
    this.refresh?.emit();
    window.alert('User ' + user.email + ' has been enabled');
  }
}
