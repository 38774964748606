<veolia-button
  [size]="'default'"
  [variant]="'secondary'"
  [icon]="'download'"
  (click)="modal.open()">
  Documentation
</veolia-button>
<veolia-modal #modal>
  <div veolia-modal-title>Documentation</div>
  <div veolia-modal-content>
    <div class="content">
      <div class="column column-space-between">
        <div>
          <div class="row spacing-v16">
            <app-documentation-item
              *ngIf="user?.roles?.admin! === true"
              title="Admin documentation (EN)"
              link="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-EN.pdf?alt=media&token=49beb7c5-587c-456e-a2dc-aa05212d08de"></app-documentation-item>
            <app-documentation-item
              *ngIf="user?.roles?.admin! === true"
              title="Admin documentation (FR)"
              link="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/admin_doc%2FADMIN_VEOLIA_GUIDE-FR.pdf?alt=media&token=7676548c-af4c-4c65-8cf1-03988a626abc"></app-documentation-item>
            <app-documentation-item
              *ngIf="user?.roles?.viewer! === true"
              title="Veolia documentation (EN)"
              link="https://docs.google.com/presentation/d/1kqMltFibPvQjwLI2bcVVdQAQ2w7Stf-MIw78wsZUkOI/edit#slide=id.g85055ddc33_0_1700"></app-documentation-item>
          </div>
          <div class="row spacing-v16">
            <app-documentation-item
              *ngIf="user?.roles?.vendor! === true"
              title="Vendor documentation (EN)"
              link="https://firebasestorage.googleapis.com/v0/b/gbl-ist-ve-dlaaas.appspot.com/o/vendor_doc%2FDLA%20DLAaaS%20Vendor%20Guide.pdf?alt=media&token=f3e33a65-afc7-48d4-8b30-1d2e5d692d92"></app-documentation-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</veolia-modal>
