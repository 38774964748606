import { NgModule } from '@angular/core';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzCardModule } from 'ng-zorro-antd/card';
import { TranslateModule } from '@ngx-translate/core';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { CommonModule } from '@angular/common';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { UserSummaryComponent } from './components/user-summary.component';
import { VeoliaTagComponent } from '@veolia.com/vds-angular-components/tag';
import { VeoliaModalModule } from '@veolia.com/vds-angular-components/modal';

@NgModule({
  declarations: [UserSummaryComponent],
  imports: [
    CommonModule,
    VeoliaModalModule,
    NzButtonModule,
    NzCardModule,
    NzDividerModule,
    NzModalModule,
    NzSpinModule,
    NzTagModule,
    TranslateModule,
    VeoliaTagComponent,
  ],
  exports: [UserSummaryComponent],
})
export class UserSummaryModule {}
