<div class="row row-space-between row-vertical-center">
  <div class="row row-vertical-center">
    <div>
      <veolia-icon
        [size]="24"
        [filled]="false"
        name="file_present"></veolia-icon>
    </div>
    <div>
      <div class="file-item__file-name">
        <strong>{{ file!.name! }}</strong>
      </div>
      <div class="file-item__file-info">
        <nz-progress [nzPercent]="progress"></nz-progress>
      </div>
    </div>
  </div>
  <!-- <div>
    <div>
      <div class="row">
        <veolia-button
          [size]="'small'"
          [shape]="'default'"
          [variant]="'secondary'"
          [htmlType]="'button'"
          [icon]="'close'">
        </veolia-button>
      </div>
    </div>
  </div> -->
</div>
